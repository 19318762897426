/*-------------------------------------------------------------------------------------------------------*/
/*This is main CSS file that contains custom style rules used in this template*/
/*-------------------------------------------------------------------------------------------------------*/
/* Template Name: Melbourne.*/
/* Version: 1.0 Initial Release*/
/* Build Date: 10-02-2017*/
/* Author: */
/* Website: */
/* Copyright: (C) 2016 */
/*------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/
/*----------------------STYLES INIT----------------------
init
fonts
body
swiper
LEFT NAVIGATION 
SCROLL
IMAGE BANNER
SIMPLE BANNER SLIDER
CREATIVE BANNER SLIDER
HEADLINES
SERVICES
STEP SERVICES
TEAM
SKILLS
GALLERY
PORTFOLIO
CLIENTS
COUNTERS
CONTACTS - FORM
PORTFOLIO DETAIL
SINGLE POST
BLOG LIST
SIDEBAR

-------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Raleway:100,200,300,400,500,600,700,800,900");
* {
  line-height: 1.2;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 19px;
  text-transform: uppercase;
}

h5 {
  font-size: 19px;
}

h6 {
  font-size: 19px;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  display: block;
}

blockquote {
  border: 0 solid #1a1a1a;
  border-left-width: 4px;
  color: #686868;
  font-size: 19px;
  font-style: italic;
  line-height: 1.4;
  margin: 0 0 25px;
  padding: 0 0 0 24px;
}

blockquote:before, blockquote:after {
  content: '';
  display: table;
}

blockquote:after {
  clear: both;
}

blockquote p {
  margin-bottom: 15px;
}

blockquote > :last-child {
  margin-bottom: 0;
}

q:before, q:after {
  content: '';
}

blockquote, q {
  quotes: none;
}

blockquote > ul {
  margin-left: 15px;
}

dfn, cite, em, i {
  font-style: italic;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 25px;
  table-layout: fixed;
  width: 100%;
}

table, th, td {
  border: 1px solid #d1d1d1;
}

th, td {
  padding: 7px;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

caption, th, td {
  font-weight: normal;
  text-align: left;
}

td {
  border-width: 0 1px 1px 0;
}

a, a:hover {
  text-decoration: none;
}

dl {
  margin: 0 0 25px;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 0 20px;
}

ul {
  list-style: disc;
}

ul, ol {
  margin: 10px 0;
  padding: 0;
}

li > ul, li > ol {
  margin-left: 15px;
  margin-bottom: 0;
}

ol {
  list-style: decimal;
}

address {
  font-style: italic;
  margin: 0 0 25px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

abbr, acronym {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help;
}

b, strong {
  font-weight: 700;
}

big {
  font-size: 125%;
}

code {
  background-color: #d1d1d1;
  padding: 2px 4px;
}

code, kbd, tt, var, samp, pre {
  font-family: Inconsolata,monospace;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: inherit;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

del {
  opacity: 0.8;
}

mark, ins {
  background: #007acc;
  color: #fff;
  padding: 2px 4px;
  text-decoration: none;
}

pre {
  border: 1px solid #d1d1d1;
  font-size: 16px;
  line-height: 1.3;
  margin: 0 0 25px;
  max-width: 100%;
  overflow: auto;
  padding: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub {
  bottom: -3px;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -6px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 25px;
}

.alignleft {
  float: left;
  margin: 6px 25px 25px 0;
}

.alignright {
  float: right;
  margin: 6px 0 25px 25px;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

img {
  border: 0;
}

.wp-caption {
  margin-bottom: 25px;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}

.wp-caption .wp-caption-text {
  color: #686868;
  font-size: 13px;
  font-style: italic;
  line-height: 1.6;
  padding-top: 7px;
}

.page-links {
  clear: both;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  margin: 0 0 25px;
}

.page-links a, .page-links > span {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  height: 22px;
  line-height: 22px;
  margin-right: 4px;
  text-align: center;
  width: 24px;
}

.page-links > .page-links-title {
  border: 0;
  color: #1a1a1a;
  height: auto;
  margin: 0;
  padding-right: 8px;
  width: auto;
}

.page-links a {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #fff;
}

.says, .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea {
  background: #f7f7f7;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #686868;
  padding: 10px 7px;
  width: 100%;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
}

button,
button[disabled]:hover,
button[disabled]:focus,
input[type="button"],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:focus,
input[type="reset"],
input[type="reset"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="submit"],
input[type="submit"][disabled]:hover,
input[type="submit"][disabled]:focus {
  background: #1a1a1a;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  font-weight: 700;
  line-height: 1;
  padding: 13.5px 14px 12.5px;
  text-transform: uppercase;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.post-password-form {
  margin-bottom: 25px;
}

.post-password-form label {
  color: #686868;
  display: block;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 21px;
  margin-bottom: 22px;
  text-transform: uppercase;
}

.post-password-form button, input, select, textarea {
  max-width: 100%;
  margin: 0;
}

.post-password-form input[type="password"] {
  background: #f7f7f7;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #686868;
  padding: 10px 7px;
  width: 100%;
  margin-top: 7px;
}

.post-password-form input[type="submit"] {
  background: #1a1a1a;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  font-weight: 700;
  letter-spacing: .75px;
  line-height: 1;
  padding: 13.5px 14px 12.5px;
  text-transform: uppercase;
}

.comment-list {
  list-style: none;
  margin: 0;
}

.comment-content h1:first-child, .comment-content h2:first-child, .comment-content h3:first-child, .comment-content h4:first-child, .comment-content h5:first-child, .comment-content h6:first-child {
  margin-top: 0;
}

blockquote em, blockquote i, blockquote cite {
  font-style: normal;
}

blockquote cite, blockquote small {
  color: #1a1a1a;
  display: block;
  font-size: 16px;
  line-height: 1.75;
}

blockquote cite:before, blockquote small:before {
  content: "\2014\00a0";
}

.comment-list .children > li {
  padding-left: 25px;
}

.gallery-item {
  display: inline-block;
  max-width: 33.33%;
  padding: 0 7px 14px;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

figure {
  margin: 0;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  color: #686868;
  display: block;
  font-size: 13px;
  font-style: italic;
  line-height: 21px;
  padding-top: 7px;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.wp-caption .wp-caption-text {
  color: #686868;
  font-size: 13px;
  font-style: italic;
  line-height: 21px;
  padding-top: 7px;
}

embed, iframe, object, video {
  margin-bottom: 25px;
  max-width: 100%;
  vertical-align: middle;
}

.entry-content blockquote:not(.alignleft):not(.alignright),
.entry-summary blockquote, .comment-content blockquote {
  margin-left: -25px;
}

.entry-content > :last-child, .entry-summary > :last-child,
body:not(.search-results) .entry-summary > :last-child,
.page-content > :last-child, .comment-content > :last-child,
.textwidget > :last-child {
  margin-bottom: 0;
}

.widget li > ul, .widget li > ol {
  margin-bottom: 0;
  margin-left: 20px;
}

.tagcloud a {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-family: Montserrat,"Helvetica Neue",sans-serif;
  line-height: 1;
  margin: 0 3px 7px 0;
  padding: 7px 6px 6px;
}

.margin-lg-0t {
  margin-top: 0px;
}

.margin-lg-5t {
  margin-top: 5px;
}

.margin-lg-10t {
  margin-top: 10px;
}

.margin-lg-15t {
  margin-top: 15px;
}

.margin-lg-20t {
  margin-top: 20px;
}

.margin-lg-25t {
  margin-top: 25px;
}

.margin-lg-30t {
  margin-top: 30px;
}

.margin-lg-35t {
  margin-top: 35px;
}

.margin-lg-40t {
  margin-top: 40px;
}

.margin-lg-45t {
  margin-top: 45px;
}

.margin-lg-50t {
  margin-top: 50px;
}

.margin-lg-55t {
  margin-top: 55px;
}

.margin-lg-60t {
  margin-top: 60px;
}

.margin-lg-65t {
  margin-top: 65px;
}

.margin-lg-70t {
  margin-top: 70px;
}

.margin-lg-75t {
  margin-top: 75px;
}

.margin-lg-80t {
  margin-top: 80px;
}

.margin-lg-85t {
  margin-top: 85px;
}

.margin-lg-90t {
  margin-top: 90px;
}

.margin-lg-95t {
  margin-top: 95px;
}

.margin-lg-100t {
  margin-top: 100px;
}

.margin-lg-105t {
  margin-top: 105px;
}

.margin-lg-110t {
  margin-top: 110px;
}

.margin-lg-115t {
  margin-top: 115px;
}

.margin-lg-120t {
  margin-top: 120px;
}

.margin-lg-125t {
  margin-top: 125px;
}

.margin-lg-130t {
  margin-top: 130px;
}

.margin-lg-135t {
  margin-top: 135px;
}

.margin-lg-140t {
  margin-top: 140px;
}

.margin-lg-145t {
  margin-top: 145px;
}

.margin-lg-150t {
  margin-top: 150px;
}

.margin-lg-155t {
  margin-top: 155px;
}

.margin-lg-160t {
  margin-top: 160px;
}

.margin-lg-165t {
  margin-top: 165px;
}

.margin-lg-170t {
  margin-top: 170px;
}

.margin-lg-175t {
  margin-top: 175px;
}

.margin-lg-180t {
  margin-top: 180px;
}

.margin-lg-185t {
  margin-top: 185px;
}

.margin-lg-190t {
  margin-top: 190px;
}

.margin-lg-195t {
  margin-top: 195px;
}

.margin-lg-200t {
  margin-top: 200px;
}

.margin-lg-0b {
  margin-bottom: 0px;
}

.margin-lg-5b {
  margin-bottom: 5px;
}

.margin-lg-10b {
  margin-bottom: 10px;
}

.margin-lg-15b {
  margin-bottom: 15px;
}

.margin-lg-20b {
  margin-bottom: 20px;
}

.margin-lg-25b {
  margin-bottom: 25px;
}

.margin-lg-30b {
  margin-bottom: 30px;
}

.margin-lg-35b {
  margin-bottom: 35px;
}

.margin-lg-40b {
  margin-bottom: 40px;
}

.margin-lg-45b {
  margin-bottom: 45px;
}

.margin-lg-50b {
  margin-bottom: 50px;
}

.margin-lg-55b {
  margin-bottom: 55px;
}

.margin-lg-60b {
  margin-bottom: 60px;
}

.margin-lg-65b {
  margin-bottom: 65px;
}

.margin-lg-70b {
  margin-bottom: 70px;
}

.margin-lg-75b {
  margin-bottom: 75px;
}

.margin-lg-80b {
  margin-bottom: 80px;
}

.margin-lg-85b {
  margin-bottom: 85px;
}

.margin-lg-90b {
  margin-bottom: 90px;
}

.margin-lg-95b {
  margin-bottom: 95px;
}

.margin-lg-100b {
  margin-bottom: 100px;
}

.margin-lg-105b {
  margin-bottom: 105px;
}

.margin-lg-110b {
  margin-bottom: 110px;
}

.margin-lg-115b {
  margin-bottom: 115px;
}

.margin-lg-120b {
  margin-bottom: 120px;
}

.margin-lg-125b {
  margin-bottom: 125px;
}

.margin-lg-130b {
  margin-bottom: 130px;
}

.margin-lg-135b {
  margin-bottom: 135px;
}

.margin-lg-140b {
  margin-bottom: 140px;
}

.margin-lg-145b {
  margin-bottom: 145px;
}

.margin-lg-150b {
  margin-bottom: 150px;
}

.margin-lg-155b {
  margin-bottom: 155px;
}

.margin-lg-160b {
  margin-bottom: 160px;
}

.margin-lg-165b {
  margin-bottom: 165px;
}

.margin-lg-170b {
  margin-bottom: 170px;
}

.margin-lg-175b {
  margin-bottom: 175px;
}

.margin-lg-180b {
  margin-bottom: 180px;
}

.margin-lg-185b {
  margin-bottom: 185px;
}

.margin-lg-190b {
  margin-bottom: 190px;
}

.margin-lg-195b {
  margin-bottom: 195px;
}

.margin-lg-200b {
  margin-bottom: 200px;
}

.padding-lg-0t {
  padding-top: 0px;
}

.padding-lg-5t {
  padding-top: 5px;
}

.padding-lg-10t {
  padding-top: 10px;
}

.padding-lg-15t {
  padding-top: 15px;
}

.padding-lg-20t {
  padding-top: 20px;
}

.padding-lg-25t {
  padding-top: 25px;
}

.padding-lg-30t {
  padding-top: 30px;
}

.padding-lg-35t {
  padding-top: 35px;
}

.padding-lg-40t {
  padding-top: 40px;
}

.padding-lg-45t {
  padding-top: 45px;
}

.padding-lg-50t {
  padding-top: 50px;
}

.padding-lg-55t {
  padding-top: 55px;
}

.padding-lg-60t {
  padding-top: 60px;
}

.padding-lg-65t {
  padding-top: 65px;
}

.padding-lg-70t {
  padding-top: 70px;
}

.padding-lg-75t {
  padding-top: 75px;
}

.padding-lg-80t {
  padding-top: 80px;
}

.padding-lg-85t {
  padding-top: 85px;
}

.padding-lg-90t {
  padding-top: 90px;
}

.padding-lg-95t {
  padding-top: 95px;
}

.padding-lg-100t {
  padding-top: 100px;
}

.padding-lg-105t {
  padding-top: 105px;
}

.padding-lg-110t {
  padding-top: 110px;
}

.padding-lg-115t {
  padding-top: 115px;
}

.padding-lg-120t {
  padding-top: 120px;
}

.padding-lg-125t {
  padding-top: 125px;
}

.padding-lg-130t {
  padding-top: 130px;
}

.padding-lg-135t {
  padding-top: 135px;
}

.padding-lg-140t {
  padding-top: 140px;
}

.padding-lg-145t {
  padding-top: 145px;
}

.padding-lg-150t {
  padding-top: 150px;
}

.padding-lg-155t {
  padding-top: 155px;
}

.padding-lg-160t {
  padding-top: 160px;
}

.padding-lg-165t {
  padding-top: 165px;
}

.padding-lg-170t {
  padding-top: 170px;
}

.padding-lg-175t {
  padding-top: 175px;
}

.padding-lg-180t {
  padding-top: 180px;
}

.padding-lg-185t {
  padding-top: 185px;
}

.padding-lg-190t {
  padding-top: 190px;
}

.padding-lg-195t {
  padding-top: 195px;
}

.padding-lg-200t {
  padding-top: 200px;
}

.padding-lg-0b {
  padding-bottom: 0px;
}

.padding-lg-5b {
  padding-bottom: 5px;
}

.padding-lg-10b {
  padding-bottom: 10px;
}

.padding-lg-15b {
  padding-bottom: 15px;
}

.padding-lg-20b {
  padding-bottom: 20px;
}

.padding-lg-25b {
  padding-bottom: 25px;
}

.padding-lg-30b {
  padding-bottom: 30px;
}

.padding-lg-35b {
  padding-bottom: 35px;
}

.padding-lg-40b {
  padding-bottom: 40px;
}

.padding-lg-45b {
  padding-bottom: 45px;
}

.padding-lg-50b {
  padding-bottom: 50px;
}

.padding-lg-55b {
  padding-bottom: 55px;
}

.padding-lg-60b {
  padding-bottom: 60px;
}

.padding-lg-65b {
  padding-bottom: 65px;
}

.padding-lg-70b {
  padding-bottom: 70px;
}

.padding-lg-75b {
  padding-bottom: 75px;
}

.padding-lg-80b {
  padding-bottom: 80px;
}

.padding-lg-85b {
  padding-bottom: 85px;
}

.padding-lg-90b {
  padding-bottom: 90px;
}

.padding-lg-95b {
  padding-bottom: 95px;
}

.padding-lg-100b {
  padding-bottom: 100px;
}

.padding-lg-105b {
  padding-bottom: 105px;
}

.padding-lg-110b {
  padding-bottom: 110px;
}

.padding-lg-115b {
  padding-bottom: 115px;
}

.padding-lg-120b {
  padding-bottom: 120px;
}

.padding-lg-125b {
  padding-bottom: 125px;
}

.padding-lg-130b {
  padding-bottom: 130px;
}

.padding-lg-135b {
  padding-bottom: 135px;
}

.padding-lg-140b {
  padding-bottom: 140px;
}

.padding-lg-145b {
  padding-bottom: 145px;
}

.padding-lg-150b {
  padding-bottom: 150px;
}

.padding-lg-155b {
  padding-bottom: 155px;
}

.padding-lg-160b {
  padding-bottom: 160px;
}

.padding-lg-165b {
  padding-bottom: 165px;
}

.padding-lg-170b {
  padding-bottom: 170px;
}

.padding-lg-175b {
  padding-bottom: 175px;
}

.padding-lg-180b {
  padding-bottom: 180px;
}

.padding-lg-185b {
  padding-bottom: 185px;
}

.padding-lg-190b {
  padding-bottom: 190px;
}

.padding-lg-195b {
  padding-bottom: 195px;
}

.padding-lg-200b {
  padding-bottom: 200px;
}

@media screen and (max-width: 1199px) {
  .margin-md-0t {
    margin-top: 0px;
  }
  .margin-md-5t {
    margin-top: 5px;
  }
  .margin-md-10t {
    margin-top: 10px;
  }
  .margin-md-15t {
    margin-top: 15px;
  }
  .margin-md-20t {
    margin-top: 20px;
  }
  .margin-md-25t {
    margin-top: 25px;
  }
  .margin-md-30t {
    margin-top: 30px;
  }
  .margin-md-35t {
    margin-top: 35px;
  }
  .margin-md-40t {
    margin-top: 40px;
  }
  .margin-md-45t {
    margin-top: 45px;
  }
  .margin-md-50t {
    margin-top: 50px;
  }
  .margin-md-55t {
    margin-top: 55px;
  }
  .margin-md-60t {
    margin-top: 60px;
  }
  .margin-md-65t {
    margin-top: 65px;
  }
  .margin-md-70t {
    margin-top: 70px;
  }
  .margin-md-75t {
    margin-top: 75px;
  }
  .margin-md-80t {
    margin-top: 80px;
  }
  .margin-md-85t {
    margin-top: 85px;
  }
  .margin-md-90t {
    margin-top: 90px;
  }
  .margin-md-95t {
    margin-top: 95px;
  }
  .margin-md-100t {
    margin-top: 100px;
  }
  .margin-md-105t {
    margin-top: 105px;
  }
  .margin-md-110t {
    margin-top: 110px;
  }
  .margin-md-115t {
    margin-top: 115px;
  }
  .margin-md-120t {
    margin-top: 120px;
  }
  .margin-md-125t {
    margin-top: 125px;
  }
  .margin-md-130t {
    margin-top: 130px;
  }
  .margin-md-135t {
    margin-top: 135px;
  }
  .margin-md-140t {
    margin-top: 140px;
  }
  .margin-md-145t {
    margin-top: 145px;
  }
  .margin-md-150t {
    margin-top: 150px;
  }
  .margin-md-155t {
    margin-top: 155px;
  }
  .margin-md-160t {
    margin-top: 160px;
  }
  .margin-md-165t {
    margin-top: 165px;
  }
  .margin-md-170t {
    margin-top: 170px;
  }
  .margin-md-175t {
    margin-top: 175px;
  }
  .margin-md-180t {
    margin-top: 180px;
  }
  .margin-md-185t {
    margin-top: 185px;
  }
  .margin-md-190t {
    margin-top: 190px;
  }
  .margin-md-195t {
    margin-top: 195px;
  }
  .margin-md-200t {
    margin-top: 200px;
  }
  .margin-md-0b {
    margin-bottom: 0px;
  }
  .margin-md-5b {
    margin-bottom: 5px;
  }
  .margin-md-10b {
    margin-bottom: 10px;
  }
  .margin-md-15b {
    margin-bottom: 15px;
  }
  .margin-md-20b {
    margin-bottom: 20px;
  }
  .margin-md-25b {
    margin-bottom: 25px;
  }
  .margin-md-30b {
    margin-bottom: 30px;
  }
  .margin-md-35b {
    margin-bottom: 35px;
  }
  .margin-md-40b {
    margin-bottom: 40px;
  }
  .margin-md-45b {
    margin-bottom: 45px;
  }
  .margin-md-50b {
    margin-bottom: 50px;
  }
  .margin-md-55b {
    margin-bottom: 55px;
  }
  .margin-md-60b {
    margin-bottom: 60px;
  }
  .margin-md-65b {
    margin-bottom: 65px;
  }
  .margin-md-70b {
    margin-bottom: 70px;
  }
  .margin-md-75b {
    margin-bottom: 75px;
  }
  .margin-md-80b {
    margin-bottom: 80px;
  }
  .margin-md-85b {
    margin-bottom: 85px;
  }
  .margin-md-90b {
    margin-bottom: 90px;
  }
  .margin-md-95b {
    margin-bottom: 95px;
  }
  .margin-md-100b {
    margin-bottom: 100px;
  }
  .margin-md-105b {
    margin-bottom: 105px;
  }
  .margin-md-110b {
    margin-bottom: 110px;
  }
  .margin-md-115b {
    margin-bottom: 115px;
  }
  .margin-md-120b {
    margin-bottom: 120px;
  }
  .margin-md-125b {
    margin-bottom: 125px;
  }
  .margin-md-130b {
    margin-bottom: 130px;
  }
  .margin-md-135b {
    margin-bottom: 135px;
  }
  .margin-md-140b {
    margin-bottom: 140px;
  }
  .margin-md-145b {
    margin-bottom: 145px;
  }
  .margin-md-150b {
    margin-bottom: 150px;
  }
  .margin-md-155b {
    margin-bottom: 155px;
  }
  .margin-md-160b {
    margin-bottom: 160px;
  }
  .margin-md-165b {
    margin-bottom: 165px;
  }
  .margin-md-170b {
    margin-bottom: 170px;
  }
  .margin-md-175b {
    margin-bottom: 175px;
  }
  .margin-md-180b {
    margin-bottom: 180px;
  }
  .margin-md-185b {
    margin-bottom: 185px;
  }
  .margin-md-190b {
    margin-bottom: 190px;
  }
  .margin-md-195b {
    margin-bottom: 195px;
  }
  .margin-md-200b {
    margin-bottom: 200px;
  }
  .padding-md-0t {
    padding-top: 0px;
  }
  .padding-md-5t {
    padding-top: 5px;
  }
  .padding-md-10t {
    padding-top: 10px;
  }
  .padding-md-15t {
    padding-top: 15px;
  }
  .padding-md-20t {
    padding-top: 20px;
  }
  .padding-md-25t {
    padding-top: 25px;
  }
  .padding-md-30t {
    padding-top: 30px;
  }
  .padding-md-35t {
    padding-top: 35px;
  }
  .padding-md-40t {
    padding-top: 40px;
  }
  .padding-md-45t {
    padding-top: 45px;
  }
  .padding-md-50t {
    padding-top: 50px;
  }
  .padding-md-55t {
    padding-top: 55px;
  }
  .padding-md-60t {
    padding-top: 60px;
  }
  .padding-md-65t {
    padding-top: 65px;
  }
  .padding-md-70t {
    padding-top: 70px;
  }
  .padding-md-75t {
    padding-top: 75px;
  }
  .padding-md-80t {
    padding-top: 80px;
  }
  .padding-md-85t {
    padding-top: 85px;
  }
  .padding-md-90t {
    padding-top: 90px;
  }
  .padding-md-95t {
    padding-top: 95px;
  }
  .padding-md-100t {
    padding-top: 100px;
  }
  .padding-md-105t {
    padding-top: 105px;
  }
  .padding-md-110t {
    padding-top: 110px;
  }
  .padding-md-115t {
    padding-top: 115px;
  }
  .padding-md-120t {
    padding-top: 120px;
  }
  .padding-md-125t {
    padding-top: 125px;
  }
  .padding-md-130t {
    padding-top: 130px;
  }
  .padding-md-135t {
    padding-top: 135px;
  }
  .padding-md-140t {
    padding-top: 140px;
  }
  .padding-md-145t {
    padding-top: 145px;
  }
  .padding-md-150t {
    padding-top: 150px;
  }
  .padding-md-155t {
    padding-top: 155px;
  }
  .padding-md-160t {
    padding-top: 160px;
  }
  .padding-md-165t {
    padding-top: 165px;
  }
  .padding-md-170t {
    padding-top: 170px;
  }
  .padding-md-175t {
    padding-top: 175px;
  }
  .padding-md-180t {
    padding-top: 180px;
  }
  .padding-md-185t {
    padding-top: 185px;
  }
  .padding-md-190t {
    padding-top: 190px;
  }
  .padding-md-195t {
    padding-top: 195px;
  }
  .padding-md-200t {
    padding-top: 200px;
  }
  .padding-md-0b {
    padding-bottom: 0px;
  }
  .padding-md-5b {
    padding-bottom: 5px;
  }
  .padding-md-10b {
    padding-bottom: 10px;
  }
  .padding-md-15b {
    padding-bottom: 15px;
  }
  .padding-md-20b {
    padding-bottom: 20px;
  }
  .padding-md-25b {
    padding-bottom: 25px;
  }
  .padding-md-30b {
    padding-bottom: 30px;
  }
  .padding-md-35b {
    padding-bottom: 35px;
  }
  .padding-md-40b {
    padding-bottom: 40px;
  }
  .padding-md-45b {
    padding-bottom: 45px;
  }
  .padding-md-50b {
    padding-bottom: 50px;
  }
  .padding-md-55b {
    padding-bottom: 55px;
  }
  .padding-md-60b {
    padding-bottom: 60px;
  }
  .padding-md-65b {
    padding-bottom: 65px;
  }
  .padding-md-70b {
    padding-bottom: 70px;
  }
  .padding-md-75b {
    padding-bottom: 75px;
  }
  .padding-md-80b {
    padding-bottom: 80px;
  }
  .padding-md-85b {
    padding-bottom: 85px;
  }
  .padding-md-90b {
    padding-bottom: 90px;
  }
  .padding-md-95b {
    padding-bottom: 95px;
  }
  .padding-md-100b {
    padding-bottom: 100px;
  }
  .padding-md-105b {
    padding-bottom: 105px;
  }
  .padding-md-110b {
    padding-bottom: 110px;
  }
  .padding-md-115b {
    padding-bottom: 115px;
  }
  .padding-md-120b {
    padding-bottom: 120px;
  }
  .padding-md-125b {
    padding-bottom: 125px;
  }
  .padding-md-130b {
    padding-bottom: 130px;
  }
  .padding-md-135b {
    padding-bottom: 135px;
  }
  .padding-md-140b {
    padding-bottom: 140px;
  }
  .padding-md-145b {
    padding-bottom: 145px;
  }
  .padding-md-150b {
    padding-bottom: 150px;
  }
  .padding-md-155b {
    padding-bottom: 155px;
  }
  .padding-md-160b {
    padding-bottom: 160px;
  }
  .padding-md-165b {
    padding-bottom: 165px;
  }
  .padding-md-170b {
    padding-bottom: 170px;
  }
  .padding-md-175b {
    padding-bottom: 175px;
  }
  .padding-md-180b {
    padding-bottom: 180px;
  }
  .padding-md-185b {
    padding-bottom: 185px;
  }
  .padding-md-190b {
    padding-bottom: 190px;
  }
  .padding-md-195b {
    padding-bottom: 195px;
  }
  .padding-md-200b {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 991px) {
  .margin-sm-0t {
    margin-top: 0px;
  }
  .margin-sm-5t {
    margin-top: 5px;
  }
  .margin-sm-10t {
    margin-top: 10px;
  }
  .margin-sm-15t {
    margin-top: 15px;
  }
  .margin-sm-20t {
    margin-top: 20px;
  }
  .margin-sm-25t {
    margin-top: 25px;
  }
  .margin-sm-30t {
    margin-top: 30px;
  }
  .margin-sm-35t {
    margin-top: 35px;
  }
  .margin-sm-40t {
    margin-top: 40px;
  }
  .margin-sm-45t {
    margin-top: 45px;
  }
  .margin-sm-50t {
    margin-top: 50px;
  }
  .margin-sm-55t {
    margin-top: 55px;
  }
  .margin-sm-60t {
    margin-top: 60px;
  }
  .margin-sm-65t {
    margin-top: 65px;
  }
  .margin-sm-70t {
    margin-top: 70px;
  }
  .margin-sm-75t {
    margin-top: 75px;
  }
  .margin-sm-80t {
    margin-top: 80px;
  }
  .margin-sm-85t {
    margin-top: 85px;
  }
  .margin-sm-90t {
    margin-top: 90px;
  }
  .margin-sm-95t {
    margin-top: 95px;
  }
  .margin-sm-100t {
    margin-top: 100px;
  }
  .margin-sm-105t {
    margin-top: 105px;
  }
  .margin-sm-110t {
    margin-top: 110px;
  }
  .margin-sm-115t {
    margin-top: 115px;
  }
  .margin-sm-120t {
    margin-top: 120px;
  }
  .margin-sm-125t {
    margin-top: 125px;
  }
  .margin-sm-130t {
    margin-top: 130px;
  }
  .margin-sm-135t {
    margin-top: 135px;
  }
  .margin-sm-140t {
    margin-top: 140px;
  }
  .margin-sm-145t {
    margin-top: 145px;
  }
  .margin-sm-150t {
    margin-top: 150px;
  }
  .margin-sm-155t {
    margin-top: 155px;
  }
  .margin-sm-160t {
    margin-top: 160px;
  }
  .margin-sm-165t {
    margin-top: 165px;
  }
  .margin-sm-170t {
    margin-top: 170px;
  }
  .margin-sm-175t {
    margin-top: 175px;
  }
  .margin-sm-180t {
    margin-top: 180px;
  }
  .margin-sm-185t {
    margin-top: 185px;
  }
  .margin-sm-190t {
    margin-top: 190px;
  }
  .margin-sm-195t {
    margin-top: 195px;
  }
  .margin-sm-200t {
    margin-top: 200px;
  }
  .margin-sm-0b {
    margin-bottom: 0px;
  }
  .margin-sm-5b {
    margin-bottom: 5px;
  }
  .margin-sm-10b {
    margin-bottom: 10px;
  }
  .margin-sm-15b {
    margin-bottom: 15px;
  }
  .margin-sm-20b {
    margin-bottom: 20px;
  }
  .margin-sm-25b {
    margin-bottom: 25px;
  }
  .margin-sm-30b {
    margin-bottom: 30px;
  }
  .margin-sm-35b {
    margin-bottom: 35px;
  }
  .margin-sm-40b {
    margin-bottom: 40px;
  }
  .margin-sm-45b {
    margin-bottom: 45px;
  }
  .margin-sm-50b {
    margin-bottom: 50px;
  }
  .margin-sm-55b {
    margin-bottom: 55px;
  }
  .margin-sm-60b {
    margin-bottom: 60px;
  }
  .margin-sm-65b {
    margin-bottom: 65px;
  }
  .margin-sm-70b {
    margin-bottom: 70px;
  }
  .margin-sm-75b {
    margin-bottom: 75px;
  }
  .margin-sm-80b {
    margin-bottom: 80px;
  }
  .margin-sm-85b {
    margin-bottom: 85px;
  }
  .margin-sm-90b {
    margin-bottom: 90px;
  }
  .margin-sm-95b {
    margin-bottom: 95px;
  }
  .margin-sm-100b {
    margin-bottom: 100px;
  }
  .margin-sm-105b {
    margin-bottom: 105px;
  }
  .margin-sm-110b {
    margin-bottom: 110px;
  }
  .margin-sm-115b {
    margin-bottom: 115px;
  }
  .margin-sm-120b {
    margin-bottom: 120px;
  }
  .margin-sm-125b {
    margin-bottom: 125px;
  }
  .margin-sm-130b {
    margin-bottom: 130px;
  }
  .margin-sm-135b {
    margin-bottom: 135px;
  }
  .margin-sm-140b {
    margin-bottom: 140px;
  }
  .margin-sm-145b {
    margin-bottom: 145px;
  }
  .margin-sm-150b {
    margin-bottom: 150px;
  }
  .margin-sm-155b {
    margin-bottom: 155px;
  }
  .margin-sm-160b {
    margin-bottom: 160px;
  }
  .margin-sm-165b {
    margin-bottom: 165px;
  }
  .margin-sm-170b {
    margin-bottom: 170px;
  }
  .margin-sm-175b {
    margin-bottom: 175px;
  }
  .margin-sm-180b {
    margin-bottom: 180px;
  }
  .margin-sm-185b {
    margin-bottom: 185px;
  }
  .margin-sm-190b {
    margin-bottom: 190px;
  }
  .margin-sm-195b {
    margin-bottom: 195px;
  }
  .margin-sm-200b {
    margin-bottom: 200px;
  }
  .padding-sm-0t {
    padding-top: 0px;
  }
  .padding-sm-5t {
    padding-top: 5px;
  }
  .padding-sm-10t {
    padding-top: 10px;
  }
  .padding-sm-15t {
    padding-top: 15px;
  }
  .padding-sm-20t {
    padding-top: 20px;
  }
  .padding-sm-25t {
    padding-top: 25px;
  }
  .padding-sm-30t {
    padding-top: 30px;
  }
  .padding-sm-35t {
    padding-top: 35px;
  }
  .padding-sm-40t {
    padding-top: 40px;
  }
  .padding-sm-45t {
    padding-top: 45px;
  }
  .padding-sm-50t {
    padding-top: 50px;
  }
  .padding-sm-55t {
    padding-top: 55px;
  }
  .padding-sm-60t {
    padding-top: 60px;
  }
  .padding-sm-65t {
    padding-top: 65px;
  }
  .padding-sm-70t {
    padding-top: 70px;
  }
  .padding-sm-75t {
    padding-top: 75px;
  }
  .padding-sm-80t {
    padding-top: 80px;
  }
  .padding-sm-85t {
    padding-top: 85px;
  }
  .padding-sm-90t {
    padding-top: 90px;
  }
  .padding-sm-95t {
    padding-top: 95px;
  }
  .padding-sm-100t {
    padding-top: 100px;
  }
  .padding-sm-105t {
    padding-top: 105px;
  }
  .padding-sm-110t {
    padding-top: 110px;
  }
  .padding-sm-115t {
    padding-top: 115px;
  }
  .padding-sm-120t {
    padding-top: 120px;
  }
  .padding-sm-125t {
    padding-top: 125px;
  }
  .padding-sm-130t {
    padding-top: 130px;
  }
  .padding-sm-135t {
    padding-top: 135px;
  }
  .padding-sm-140t {
    padding-top: 140px;
  }
  .padding-sm-145t {
    padding-top: 145px;
  }
  .padding-sm-150t {
    padding-top: 150px;
  }
  .padding-sm-155t {
    padding-top: 155px;
  }
  .padding-sm-160t {
    padding-top: 160px;
  }
  .padding-sm-165t {
    padding-top: 165px;
  }
  .padding-sm-170t {
    padding-top: 170px;
  }
  .padding-sm-175t {
    padding-top: 175px;
  }
  .padding-sm-180t {
    padding-top: 180px;
  }
  .padding-sm-185t {
    padding-top: 185px;
  }
  .padding-sm-190t {
    padding-top: 190px;
  }
  .padding-sm-195t {
    padding-top: 195px;
  }
  .padding-sm-200t {
    padding-top: 200px;
  }
  .padding-sm-0b {
    padding-bottom: 0px;
  }
  .padding-sm-5b {
    padding-bottom: 5px;
  }
  .padding-sm-10b {
    padding-bottom: 10px;
  }
  .padding-sm-15b {
    padding-bottom: 15px;
  }
  .padding-sm-20b {
    padding-bottom: 20px;
  }
  .padding-sm-25b {
    padding-bottom: 25px;
  }
  .padding-sm-30b {
    padding-bottom: 30px;
  }
  .padding-sm-35b {
    padding-bottom: 35px;
  }
  .padding-sm-40b {
    padding-bottom: 40px;
  }
  .padding-sm-45b {
    padding-bottom: 45px;
  }
  .padding-sm-50b {
    padding-bottom: 50px;
  }
  .padding-sm-55b {
    padding-bottom: 55px;
  }
  .padding-sm-60b {
    padding-bottom: 60px;
  }
  .padding-sm-65b {
    padding-bottom: 65px;
  }
  .padding-sm-70b {
    padding-bottom: 70px;
  }
  .padding-sm-75b {
    padding-bottom: 75px;
  }
  .padding-sm-80b {
    padding-bottom: 80px;
  }
  .padding-sm-85b {
    padding-bottom: 85px;
  }
  .padding-sm-90b {
    padding-bottom: 90px;
  }
  .padding-sm-95b {
    padding-bottom: 95px;
  }
  .padding-sm-100b {
    padding-bottom: 100px;
  }
  .padding-sm-105b {
    padding-bottom: 105px;
  }
  .padding-sm-110b {
    padding-bottom: 110px;
  }
  .padding-sm-115b {
    padding-bottom: 115px;
  }
  .padding-sm-120b {
    padding-bottom: 120px;
  }
  .padding-sm-125b {
    padding-bottom: 125px;
  }
  .padding-sm-130b {
    padding-bottom: 130px;
  }
  .padding-sm-135b {
    padding-bottom: 135px;
  }
  .padding-sm-140b {
    padding-bottom: 140px;
  }
  .padding-sm-145b {
    padding-bottom: 145px;
  }
  .padding-sm-150b {
    padding-bottom: 150px;
  }
  .padding-sm-155b {
    padding-bottom: 155px;
  }
  .padding-sm-160b {
    padding-bottom: 160px;
  }
  .padding-sm-165b {
    padding-bottom: 165px;
  }
  .padding-sm-170b {
    padding-bottom: 170px;
  }
  .padding-sm-175b {
    padding-bottom: 175px;
  }
  .padding-sm-180b {
    padding-bottom: 180px;
  }
  .padding-sm-185b {
    padding-bottom: 185px;
  }
  .padding-sm-190b {
    padding-bottom: 190px;
  }
  .padding-sm-195b {
    padding-bottom: 195px;
  }
  .padding-sm-200b {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) {
  .margin-xs-0t {
    margin-top: 0px;
  }
  .margin-xs-5t {
    margin-top: 5px;
  }
  .margin-xs-10t {
    margin-top: 10px;
  }
  .margin-xs-15t {
    margin-top: 15px;
  }
  .margin-xs-20t {
    margin-top: 20px;
  }
  .margin-xs-25t {
    margin-top: 25px;
  }
  .margin-xs-30t {
    margin-top: 30px;
  }
  .margin-xs-35t {
    margin-top: 35px;
  }
  .margin-xs-40t {
    margin-top: 40px;
  }
  .margin-xs-45t {
    margin-top: 45px;
  }
  .margin-xs-50t {
    margin-top: 50px;
  }
  .margin-xs-55t {
    margin-top: 55px;
  }
  .margin-xs-60t {
    margin-top: 60px;
  }
  .margin-xs-65t {
    margin-top: 65px;
  }
  .margin-xs-70t {
    margin-top: 70px;
  }
  .margin-xs-75t {
    margin-top: 75px;
  }
  .margin-xs-80t {
    margin-top: 80px;
  }
  .margin-xs-85t {
    margin-top: 85px;
  }
  .margin-xs-90t {
    margin-top: 90px;
  }
  .margin-xs-95t {
    margin-top: 95px;
  }
  .margin-xs-100t {
    margin-top: 100px;
  }
  .margin-xs-105t {
    margin-top: 105px;
  }
  .margin-xs-110t {
    margin-top: 110px;
  }
  .margin-xs-115t {
    margin-top: 115px;
  }
  .margin-xs-120t {
    margin-top: 120px;
  }
  .margin-xs-125t {
    margin-top: 125px;
  }
  .margin-xs-130t {
    margin-top: 130px;
  }
  .margin-xs-135t {
    margin-top: 135px;
  }
  .margin-xs-140t {
    margin-top: 140px;
  }
  .margin-xs-145t {
    margin-top: 145px;
  }
  .margin-xs-150t {
    margin-top: 150px;
  }
  .margin-xs-155t {
    margin-top: 155px;
  }
  .margin-xs-160t {
    margin-top: 160px;
  }
  .margin-xs-165t {
    margin-top: 165px;
  }
  .margin-xs-170t {
    margin-top: 170px;
  }
  .margin-xs-175t {
    margin-top: 175px;
  }
  .margin-xs-180t {
    margin-top: 180px;
  }
  .margin-xs-185t {
    margin-top: 185px;
  }
  .margin-xs-190t {
    margin-top: 190px;
  }
  .margin-xs-195t {
    margin-top: 195px;
  }
  .margin-xs-200t {
    margin-top: 200px;
  }
  .margin-xs-0b {
    margin-bottom: 0px;
  }
  .margin-xs-5b {
    margin-bottom: 5px;
  }
  .margin-xs-10b {
    margin-bottom: 10px;
  }
  .margin-xs-15b {
    margin-bottom: 15px;
  }
  .margin-xs-20b {
    margin-bottom: 20px;
  }
  .margin-xs-25b {
    margin-bottom: 25px;
  }
  .margin-xs-30b {
    margin-bottom: 30px;
  }
  .margin-xs-35b {
    margin-bottom: 35px;
  }
  .margin-xs-40b {
    margin-bottom: 40px;
  }
  .margin-xs-45b {
    margin-bottom: 45px;
  }
  .margin-xs-50b {
    margin-bottom: 50px;
  }
  .margin-xs-55b {
    margin-bottom: 55px;
  }
  .margin-xs-60b {
    margin-bottom: 60px;
  }
  .margin-xs-65b {
    margin-bottom: 65px;
  }
  .margin-xs-70b {
    margin-bottom: 70px;
  }
  .margin-xs-75b {
    margin-bottom: 75px;
  }
  .margin-xs-80b {
    margin-bottom: 80px;
  }
  .margin-xs-85b {
    margin-bottom: 85px;
  }
  .margin-xs-90b {
    margin-bottom: 90px;
  }
  .margin-xs-95b {
    margin-bottom: 95px;
  }
  .margin-xs-100b {
    margin-bottom: 100px;
  }
  .margin-xs-105b {
    margin-bottom: 105px;
  }
  .margin-xs-110b {
    margin-bottom: 110px;
  }
  .margin-xs-115b {
    margin-bottom: 115px;
  }
  .margin-xs-120b {
    margin-bottom: 120px;
  }
  .margin-xs-125b {
    margin-bottom: 125px;
  }
  .margin-xs-130b {
    margin-bottom: 130px;
  }
  .margin-xs-135b {
    margin-bottom: 135px;
  }
  .margin-xs-140b {
    margin-bottom: 140px;
  }
  .margin-xs-145b {
    margin-bottom: 145px;
  }
  .margin-xs-150b {
    margin-bottom: 150px;
  }
  .margin-xs-155b {
    margin-bottom: 155px;
  }
  .margin-xs-160b {
    margin-bottom: 160px;
  }
  .margin-xs-165b {
    margin-bottom: 165px;
  }
  .margin-xs-170b {
    margin-bottom: 170px;
  }
  .margin-xs-175b {
    margin-bottom: 175px;
  }
  .margin-xs-180b {
    margin-bottom: 180px;
  }
  .margin-xs-185b {
    margin-bottom: 185px;
  }
  .margin-xs-190b {
    margin-bottom: 190px;
  }
  .margin-xs-195b {
    margin-bottom: 195px;
  }
  .margin-xs-200b {
    margin-bottom: 200px;
  }
  .padding-xs-0t {
    padding-top: 0px;
  }
  .padding-xs-5t {
    padding-top: 5px;
  }
  .padding-xs-10t {
    padding-top: 10px;
  }
  .padding-xs-15t {
    padding-top: 15px;
  }
  .padding-xs-20t {
    padding-top: 20px;
  }
  .padding-xs-25t {
    padding-top: 25px;
  }
  .padding-xs-30t {
    padding-top: 30px;
  }
  .padding-xs-35t {
    padding-top: 35px;
  }
  .padding-xs-40t {
    padding-top: 40px;
  }
  .padding-xs-45t {
    padding-top: 45px;
  }
  .padding-xs-50t {
    padding-top: 50px;
  }
  .padding-xs-55t {
    padding-top: 55px;
  }
  .padding-xs-60t {
    padding-top: 60px;
  }
  .padding-xs-65t {
    padding-top: 65px;
  }
  .padding-xs-70t {
    padding-top: 70px;
  }
  .padding-xs-75t {
    padding-top: 75px;
  }
  .padding-xs-80t {
    padding-top: 80px;
  }
  .padding-xs-85t {
    padding-top: 85px;
  }
  .padding-xs-90t {
    padding-top: 90px;
  }
  .padding-xs-95t {
    padding-top: 95px;
  }
  .padding-xs-100t {
    padding-top: 100px;
  }
  .padding-xs-105t {
    padding-top: 105px;
  }
  .padding-xs-110t {
    padding-top: 110px;
  }
  .padding-xs-115t {
    padding-top: 115px;
  }
  .padding-xs-120t {
    padding-top: 120px;
  }
  .padding-xs-125t {
    padding-top: 125px;
  }
  .padding-xs-130t {
    padding-top: 130px;
  }
  .padding-xs-135t {
    padding-top: 135px;
  }
  .padding-xs-140t {
    padding-top: 140px;
  }
  .padding-xs-145t {
    padding-top: 145px;
  }
  .padding-xs-150t {
    padding-top: 150px;
  }
  .padding-xs-155t {
    padding-top: 155px;
  }
  .padding-xs-160t {
    padding-top: 160px;
  }
  .padding-xs-165t {
    padding-top: 165px;
  }
  .padding-xs-170t {
    padding-top: 170px;
  }
  .padding-xs-175t {
    padding-top: 175px;
  }
  .padding-xs-180t {
    padding-top: 180px;
  }
  .padding-xs-185t {
    padding-top: 185px;
  }
  .padding-xs-190t {
    padding-top: 190px;
  }
  .padding-xs-195t {
    padding-top: 195px;
  }
  .padding-xs-200t {
    padding-top: 200px;
  }
  .padding-xs-0b {
    padding-bottom: 0px;
  }
  .padding-xs-5b {
    padding-bottom: 5px;
  }
  .padding-xs-10b {
    padding-bottom: 10px;
  }
  .padding-xs-15b {
    padding-bottom: 15px;
  }
  .padding-xs-20b {
    padding-bottom: 20px;
  }
  .padding-xs-25b {
    padding-bottom: 25px;
  }
  .padding-xs-30b {
    padding-bottom: 30px;
  }
  .padding-xs-35b {
    padding-bottom: 35px;
  }
  .padding-xs-40b {
    padding-bottom: 40px;
  }
  .padding-xs-45b {
    padding-bottom: 45px;
  }
  .padding-xs-50b {
    padding-bottom: 50px;
  }
  .padding-xs-55b {
    padding-bottom: 55px;
  }
  .padding-xs-60b {
    padding-bottom: 60px;
  }
  .padding-xs-65b {
    padding-bottom: 65px;
  }
  .padding-xs-70b {
    padding-bottom: 70px;
  }
  .padding-xs-75b {
    padding-bottom: 75px;
  }
  .padding-xs-80b {
    padding-bottom: 80px;
  }
  .padding-xs-85b {
    padding-bottom: 85px;
  }
  .padding-xs-90b {
    padding-bottom: 90px;
  }
  .padding-xs-95b {
    padding-bottom: 95px;
  }
  .padding-xs-100b {
    padding-bottom: 100px;
  }
  .padding-xs-105b {
    padding-bottom: 105px;
  }
  .padding-xs-110b {
    padding-bottom: 110px;
  }
  .padding-xs-115b {
    padding-bottom: 115px;
  }
  .padding-xs-120b {
    padding-bottom: 120px;
  }
  .padding-xs-125b {
    padding-bottom: 125px;
  }
  .padding-xs-130b {
    padding-bottom: 130px;
  }
  .padding-xs-135b {
    padding-bottom: 135px;
  }
  .padding-xs-140b {
    padding-bottom: 140px;
  }
  .padding-xs-145b {
    padding-bottom: 145px;
  }
  .padding-xs-150b {
    padding-bottom: 150px;
  }
  .padding-xs-155b {
    padding-bottom: 155px;
  }
  .padding-xs-160b {
    padding-bottom: 160px;
  }
  .padding-xs-165b {
    padding-bottom: 165px;
  }
  .padding-xs-170b {
    padding-bottom: 170px;
  }
  .padding-xs-175b {
    padding-bottom: 175px;
  }
  .padding-xs-180b {
    padding-bottom: 180px;
  }
  .padding-xs-185b {
    padding-bottom: 185px;
  }
  .padding-xs-190b {
    padding-bottom: 190px;
  }
  .padding-xs-195b {
    padding-bottom: 195px;
  }
  .padding-xs-200b {
    padding-bottom: 200px;
  }
}

/*--------------------------------------------------------*/
/* Fonts: */
/*--------------------------------------------------------*/
* {
  box-sizing: border-box;
  outline: none;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 19px;
  text-transform: uppercase;
}

h5 {
  font-size: 19px;
}

h6 {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
}

/*--------------------------------------------------------*/
/* Body */
/*--------------------------------------------------------*/
.wrapper {
  padding-left: 1em;
  padding-right: 1em;
  margin: auto;
  display: block;
  width: 195px;
}

.cssload-loader {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  margin: 3em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background: #343434;
}

.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
  animation: 1.15s infinite ease-in-out;
  -o-animation: 1.15s infinite ease-in-out;
  -ms-animation: 1.15s infinite ease-in-out;
  -webkit-animation: 1.15s infinite ease-in-out;
  -moz-animation: 1.15s infinite ease-in-out;
}

.cssload-loader:before,
.cssload-loader:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.cssload-loader {
  animation-name: cssload-loader;
  -o-animation-name: cssload-loader;
  -ms-animation-name: cssload-loader;
  -webkit-animation-name: cssload-loader;
  -moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@-o-keyframes cssload-loader {
  from {
    -o-transform: scale(0);
    opacity: 1;
  }
  to {
    -o-transform: scale(1);
    opacity: 0;
  }
}

@-ms-keyframes cssload-loader {
  from {
    -ms-transform: scale(0);
    opacity: 1;
  }
  to {
    -ms-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loader {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@-moz-keyframes cssload-loader {
  from {
    -moz-transform: scale(0);
    opacity: 1;
  }
  to {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

p {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin-top: 10px;
  margin-bottom: 15px;
}

body {
  font-size: 14px;
  line-height: normal;
  background-color: #F0F0F0;
  color: #343434;
  margin: 50px 50px 10px;
  overflow-x: hidden;
}

html {
  font-size: 14px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.overflow {
  overflow: hidden;
}

.body-height {
  min-height: 200px;
}

.move-wrapper > div.clearfix {
  height: 0;
}

/*--------------------------------------------------------*/
/* SWIPER */
/*--------------------------------------------------------*/
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  direction: ltr;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  height: inherit;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  -webkit-transition-property: -webkit-transform, left, top;
  -webkit-transition-duration: 0s;
  -webkit-transform: translate3d(0px, 0, 0);
  -webkit-transition-timing-function: ease;
  -moz-transition-property: -moz-transform, left, top;
  -moz-transition-duration: 0s;
  -moz-transform: translate3d(0px, 0, 0);
  -moz-transition-timing-function: ease;
  -o-transition-property: -o-transform, left, top;
  -o-transition-duration: 0s;
  -o-transform: translate3d(0px, 0, 0);
  -o-transition-timing-function: ease;
  -o-transform: translate(0px, 0px);
  -ms-transition-property: -ms-transform, left, top;
  -ms-transition-duration: 0s;
  -ms-transform: translate3d(0px, 0, 0);
  -ms-transition-timing-function: ease;
  transition-property: transform, left, top;
  transition-duration: 0s;
  transform: translate3d(0px, 0, 0);
  transition-timing-function: ease;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: ew-resize;
  margin: 0 auto;
  height: inherit;
  float: left;
}

.swiper-slide {
  float: left;
  height: inherit;
  position: relative;
}

.logo {
  position: relative;
  padding: 55px 15px 53px;
  left: 0;
  display: block;
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.logo img {
  max-height: 70px;
}

.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #e4e6eb;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.body-height {
  height: calc(100vh - 100px);
}

/*--------------------------------------------------------*/
/*  LEFT NAVIGATION  */
/*--------------------------------------------------------*/
.left-navigation {
  display: block;
  width: 280px;
  float: left;
  z-index: 500;
  min-height: calc(100vmin - 160px);
  text-align: center;
  background-color: white;
  position: relative;
}

.left-navigation::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  height: 200px;
  z-index: -1;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-wrapper {
  max-height: 100%;
  height: inherit;
  padding: 0px 15px 15px;
  background-color: #FFFFFF;
}

.navigation ul {
  padding-left: 0;
}

.navigation {
  font-family: 'Raleway', serif;
  position: relative;
  width: 100%;
  text-align: center;
  overflow: auto;
  height: 100%;
}

.menu > .li-nav {
  position: relative;
  display: block;
}

.menu > .li-nav:hover > a {
  color: #fff;
  background-color: #AA855D;
}

.menu > .li-nav:hover > a::before {
  box-shadow: 0px 7px 30px 0px #aa855d;
  opacity: .5;
}

.menu > .li-nav.current-menu-ancestor > a,
.menu > .li-nav.current_page_item > a,
.menu > .li-nav.current-menu-parent > a {
  color: #AA855D;
}

.menu > .li-nav > .sub-menu > .current-menu-parent > a {
  color: #343434;
}

.menu > .li-nav > .sub-menu > .current-menu-parent > a::before {
  width: 100%;
}

.menu > .li-nav > .sub-menu > .current_page_item > a {
  opacity: 1;
  color: #AA855D;
}

.menu > .li-nav > .sub-menu > .current_page_item > a::before {
  background-color: #AA855D;
}

.menu > .li-nav > .sub-menu > .current_page_item > a::after {
  background-color: #AA855D;
}

.menu > .li-nav > .sub-menu > .current_page_item > a:hover::after {
  background-color: transparent;
}

.menu > .li-nav > .sub-menu .sub-menu a::after {
  display: none;
}

.menu > .li-nav > .sub-menu .sub-menu > .current_page_item > a {
  color: #AA855D;
  opacity: 1;
}

.menu > .li-nav.current-menu-parent > a:hover {
  color: #fff;
}

.menu-item > a {
  text-transform: uppercase;
}

.nav-link {
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 15px 56px;
  display: inline-block;
  line-height: 1.2;
  position: relative;
  z-index: 10;
  text-decoration: none;
  margin-bottom: 14px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.nav-link:focus, .nav-link:visited, .nav-link:hover {
  color: #343434;
}

.nav-link::before {
  content: '';
  position: absolute;
  left: 50%;
  display: block;
  z-index: -1;
  width: 90%;
  bottom: 0;
  height: 30%;
  transform: translateX(-50%);
  -webkit-transition: box-shadow 400ms ease;
  transition: box-shadow 400ms ease;
}

.fixed-sidebar-footer {
  position: absolute;
  padding-top: 15px;
  background-color: #fff;
  width: calc(100% - 30px);
  left: 15px;
  padding-bottom: 15px;
  bottom: 0px;
  z-index: 9999;
}

.menu-item .sub-menu {
  display: none;
  position: relative;
  top: -4px;
  margin: 0;
  padding: 0px;
}

.menu-item .sub-menu li {
  font-size: 11px;
  padding: 0px;
  line-height: 1.8em;
  margin: 0;
}

.menu-item .sub-menu li:hover > a {
  color: #343434;
  opacity: 1;
}

.menu-item .sub-menu li .sub-menu {
  margin-top: 8px;
}

.menu-item .sub-menu li .sub-menu li > a {
  opacity: .7;
}

.menu-item .sub-menu li .sub-menu li > a::before {
  width: 0%;
}

.menu-item .sub-menu li .sub-menu li > a:hover {
  opacity: 1;
}

.menu-item .sub-menu li .sub-menu li > a:hover::before {
  width: 0%;
}

.menu-item .sub-menu .nav-link {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  position: relative;
  font-weight: 400;
  letter-spacing: 1.7px;
  padding: 0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  text-decoration: none;
  background: none;
  text-transform: none;
  margin-bottom: 8px;
}

.menu-item .sub-menu .nav-link::before {
  content: '';
  height: 1px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  transform: translateY(-50%);
  bottom: auto;
  background: transparent;
  transition: width 0s ease;
}

.menu-item .sub-menu .nav-link::after {
  content: '';
  display: block;
  height: 1px;
  width: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #343434;
  transition: width .3s ease-out;
}

.menu-item .sub-menu .nav-link:hover::before {
  width: 100%;
  background: #343434;
  transition: width 0.3s ease;
}

.menu-item .sub-menu .nav-link:hover::after {
  width: 100%;
  background: transparent;
  transition: all 0s ease-out;
}

.nav-open-submenu {
  position: relative;
  margin-bottom: -3px;
  display: none;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.current-portfolio-ancestor > a {
  color: #fff;
  background-color: #AA855D;
}

.current-portfolio-ancestor > a::before {
  box-shadow: 0px 7px 30px 0px #aa855d;
  opacity: .5;
}

.social-ico {
  margin-bottom: 50px;
}

.social {
  font-size: 12px;
  display: inline-block;
  height: 24px;
  width: 24px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #343434;
  position: relative;
  margin: 0 3.6px;
  border: 1px solid transparent;
}

.social i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: color 400ms ease;
  transition: color 400ms ease;
}

.social:hover {
  border-color: #AA855D;
}

.social:hover i {
  color: #AA855D;
}

.copyright {
  padding: 20px;
  background-color: #F0F0F0;
}

.copyright-text {
  font-family: 'Raleway', serif;
  font-size: 12px;
  font-weight: 400;
  color: #343434;
  opacity: .7;
  line-height: 1.5;
  letter-spacing: .8px;
}

.copyright-text a {
  color: #fff;
}

.left-navigation:hover .jspTrack {
  background-color: #F0F0F0;
}

.jspTrack {
  background-color: transparent;
}

.jspVerticalBar {
  width: 5px;
  background-color: transparent;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.left-navigation:hover .jspDrag {
  background-color: #343434;
}

.jspDrag {
  background-color: transparent;
  opacity: .4;
  transition: all 300ms ease;
}

.s-back-switch {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.no-padding {
  padding: 0;
}

/*--------------------------------------------------------*/
/*  IMAGE BANNER  */
/*--------------------------------------------------------*/
.simple-banner-wrap {
  position: relative;
}

.simple-banner-wrap .content.active {
  opacity: 1;
}

.simple-banner-wrap .content.active .title,
.simple-banner-wrap .content.active .a-btn {
  opacity: 1;
}

.simple-banner-wrap .content.active .subtitle {
  opacity: 1;
}

.simple-banner-wrap .content {
  position: absolute;
  bottom: 70px;
  background-color: #fff;
  padding: 25px 40px 30px;
  text-align: center;
  max-width: 630px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 400ms ease-out 400ms;
}

.simple-banner-wrap .content .title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #a7a2a2;
  font-weight: bold;
  line-height: 1.2;
  opacity: 0;
  display: block;
  margin-bottom: 5px;
  transition: opacity 400ms ease-out 600ms;
}

.simple-banner-wrap .content .subtitle {
  margin-top: 0;
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
  opacity: 0;
  transition: opacity 400ms ease-out 800ms;
}

.simple-banner-wrap .a-btn {
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 400ms ease-out 1000ms;
}

.simple-banner-wrap {
  overflow: hidden;
}

.simple-banner-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

.a-btn {
  background-color: #9E7B58;
  color: white;
  text-align: left;
  padding-left: 20px;
  display: inline-block;
  padding-right: 20px;
  width: 65px;
  position: relative;
  height: 27px;
  cursor: pointer;
}

.a-btn:hover i {
  width: 27px;
  transform: translateX(4px);
}

.a-btn:hover i::before {
  width: 24px;
}

.a-btn::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.a-btn i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: relative;
  transition: all 350ms ease;
}

.a-btn i::after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.a-btn i::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  left: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 350ms ease;
}

.a-btn-left {
  background-color: #9E7B58;
  color: white;
  text-align: right;
  display: inline-block;
  padding-right: 20px;
  width: 65px;
  position: relative;
  height: 27px;
  cursor: pointer;
}

.a-btn-left:hover i {
  width: 27px;
  transform: translateX(-4px);
}

.a-btn-left:hover i::after {
  width: 24px;
}

.a-btn-left::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.a-btn-left i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: relative;
  transition: all 350ms ease;
}

.a-btn-left i::before {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.a-btn-left i::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  right: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 350ms ease;
}

.border {
  border: 30px solid white;
  position: relative;
}

.border::before {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
}

.border-1 {
  border-top: 30px solid white;
  border-bottom: 30px solid white;
  position: relative;
}

.border-1::before {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
}

.border-2 {
  position: relative;
}

.border-2::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: calc(100% - 40px);
  display: block;
  z-index: 10;
  width: 30px;
  background-color: #fff;
}

.border-3 {
  position: relative;
}

.border-3::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: calc(100% - 40px);
  display: block;
  z-index: 10;
  width: 30px;
  background-color: #fff;
}

.box-shadow-1 {
  position: relative;
}

.box-shadow-1::before {
  content: '';
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
}

.bypostauthor {
  font-size: inherit;
}

.box-shadow {
  position: relative;
}

.box-shadow::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
}

.box-shadow-row {
  position: relative;
}

.box-shadow-row::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 70px);
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
}

.textlogo {
  font-size: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  font-weight: bold;
  line-height: 1.3;
}

/*--------------------------------------------------------*/
/* SIMPLE BANNER SLIDER */
/*--------------------------------------------------------*/
.banner-slider-wrap {
  position: relative;
}

.banner-slider-wrap .swiper-slide.swiper-slide-active .title {
  opacity: 1;
}

.banner-slider-wrap .swiper-slide.swiper-slide-active .subtitle {
  opacity: 1;
}

.banner-slider-wrap .swiper-slide.swiper-slide-active .slider-content {
  opacity: 1;
}

.banner-slider-wrap .body-height {
  height: calc(100vh - 160px);
}

.banner-slider-wrap .pagination {
  display: none;
}

.banner-slider-wrap .swiper-outer-left,
.banner-slider-wrap .swiper-outer-right {
  position: absolute;
  top: calc(50% - 40px);
  transform: translateY(-50%);
  z-index: 50;
}

.banner-slider-wrap .swiper-outer-left {
  left: 0px;
}

.banner-slider-wrap .swiper-outer-right {
  right: 0px;
}

.banner-slider-wrap .wrap {
  position: relative;
}

.banner-slider-wrap .slider-content {
  position: absolute;
  bottom: -45px;
  background-color: #fff;
  padding: 25px 40px 45px;
  text-align: center;
  max-width: 630px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.banner-slider-wrap .slider-content .title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;
  opacity: 0;
  transition: opacity 400ms ease-out 600ms;
}

.banner-slider-wrap .slider-content .subtitle {
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
  letter-spacing: .8px;
  opacity: 0;
  transition: opacity 400ms ease-out 800ms;
}

.banner-slider-wrap .button {
  margin-top: 15px;
}

.single-post-wrap {
  padding-left: 55px;
  padding-right: 55px;
}

/*--------------------------------------------------------*/
/*  CREATIVE BANNER SLIDER */
/*--------------------------------------------------------*/
.creative-slider-wrap {
  position: relative;
}

.creative-slider-wrap .swiper-slide {
  background-color: #fff;
}

.creative-slider-wrap .swiper-slide.swiper-slide-active .title,
.creative-slider-wrap .swiper-slide.swiper-slide-active .button {
  opacity: 1;
}

.creative-slider-wrap .swiper-slide.swiper-slide-active .subtitle {
  opacity: .8;
}

.creative-slider-wrap .pagination {
  display: block;
  position: absolute;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
}

.creative-slider-wrap .pagination span {
  display: none;
  font-size: 160px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  opacity: .1;
  font-weight: bold;
}

.creative-slider-wrap .pagination .swiper-active-switch {
  display: block;
}

.creative-slider-wrap .wrap {
  background-color: #fff;
}

.creative-slider-wrap .img-slider-wrap {
  width: 100%;
}

.creative-slider-wrap .img-slider-wrap.body-height {
  height: calc(100vh - 450px);
  min-height: 100px;
}

.creative-slider-wrap .slider-content {
  padding: 0 30px;
}

.creative-slider-wrap .wrap-arrows {
  position: absolute;
  padding: 12px 25px;
  top: calc(100vh - 440px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
}

.creative-slider-wrap .wrap-arrows::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.creative-slider-wrap .wrap-arrows > div {
  display: inline-block;
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 2px;
  transition: opacity 300ms ease;
}

.creative-slider-wrap .wrap-arrows > div:hover {
  opacity: 1;
}

.creative-slider-wrap .wrap-arrows > div:first-child {
  margin-right: 1px;
  position: relative;
}

.creative-slider-wrap .wrap-arrows > div:nth-child(2):hover {
  opacity: .7;
}

.creative-slider-wrap .wrap-arrows > div:last-child {
  margin-left: 1px;
}

.creative-slider-wrap .creative-content {
  height: 290px;
  text-align: center;
  padding-top: 55px;
  padding-right: 30px;
  padding-left: 30px;
}

.creative-slider-wrap .creative-content .title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  font-weight: bold;
  line-height: 1.3;
  opacity: 0;
  transition: opacity 400ms ease-out 400ms;
}

.creative-slider-wrap .creative-content .subtitle {
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 23px;
  letter-spacing: .85px;
  max-width: 610px;
  margin: auto;
  opacity: 0;
  transition: opacity 400ms ease-out 600ms;
}

.creative-slider-wrap .creative-content .button {
  margin-top: 43px;
  opacity: 0;
  transition: opacity 400ms ease-out 800ms;
}

.button {
  font-size: 11px;
  font-family: "Raleway", sans-serif;
  color: #fff;
  padding: 15px 50px 15px 37px;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: #9E7B58;
  position: relative;
  text-transform: uppercase;
}

.button::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: block;
  z-index: 0;
  box-shadow: 0px 7px 30px 0px #9e7b58;
  opacity: .5;
}

.button::after {
  content: "\f105";
  font-family: "FontAwesome";
  color: inherit;
  position: absolute;
  right: 35px;
  top: 48%;
  transform: translateY(-50%);
  transition: right 350ms ease;
}

.button:focus, .button:active {
  color: #fff;
}

.button:hover {
  color: white;
}

.button:hover::after {
  right: 30px;
}

/*--------------------------------------------------------*/
/* HEADLINES */
/*--------------------------------------------------------*/
.headlines-wrap {
  background-color: #fff;
  padding: 70px 0px 20px;
}

.headlines-wrap.style1 .headlines {
  margin-bottom: 28px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.headlines-wrap.style1 .headlines::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.headlines-wrap.style1 .headlines .image-paralax {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.headlines-wrap.style1 .headlines .content {
  position: absolute;
  bottom: 34px;
  width: 100%;
  text-align: center;
  z-index: 99;
}

.headlines-wrap.style1 .headlines .content .headlines-title {
  padding: 0 15px;
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  line-height: 1.2;
}

.headlines-wrap.style2 {
  padding-top: 43px;
}

.headlines-wrap.style2 .headlines {
  margin-bottom: 44px;
}

.headlines-wrap.style2 .headlines .content {
  text-align: center;
}

.headlines-wrap.style2 .headlines .content .headlines-title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 17px;
}

.headlines-wrap.style2 .headlines .content .headlines-subtitle {
  font-size: 12.7px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  font-weight: 600;
  letter-spacing: .27px;
  opacity: .7;
  line-height: 1.5;
  text-align: center;
}

/*--------------------------------------------------------*/
/* SERVICES */
/*--------------------------------------------------------*/
.services-wrap {
  text-align: center;
  margin: 25px auto 20px;
}

.services-wrap .icon i {
  font-size: 36px;
  color: #AA855D;
}

.services-wrap .title {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  margin-top: 23px;
  letter-spacing: 1.7px;
  font-weight: 600;
}

.services-wrap .text {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  line-height: 1.5;
  letter-spacing: 1.1px;
  margin-top: 14px;
}

.services-wrap .a-btn {
  margin-top: 20px;
}

/*--------------------------------------------------------*/
/*  STEP SERVICES */
/*--------------------------------------------------------*/
.step-services-wrap {
  position: relative;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.step-services-wrap .char {
  position: absolute;
  top: 230px;
  width: 100%;
  text-align: center;
  font-size: 100px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  opacity: .1;
  font-weight: bold;
}

.step-services-wrap .title {
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  line-height: 1.2;
  margin-bottom: 5px;
}

.step-services-wrap .text {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  letter-spacing: 1.2px;
  line-height: 1.5;
}

.step-services-wrap .content-wrap {
  margin-top: 65px;
  text-align: center;
}

.step-services-wrap .content-wrap.char-style {
  min-height: 120px;
}

.step-services-wrap .img-wrap {
  height: 200px;
  width: 100%;
  position: relative;
}

.step-services-wrap .img-wrap::before {
  content: '';
  position: absolute;
  height: 21px;
  bottom: -20px;
  width: 72%;
  background-color: #AA855D;
  left: 50%;
  transform: translateX(-50%);
  transition: all 350ms ease;
}

.step-services-wrap .img-wrap::after {
  content: '';
  position: absolute;
  height: 30px;
  bottom: -10px;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 50px 0px #AA855D;
  opacity: .5;
  transition: all 350ms ease;
}

.step-services-wrap .img-wrap:hover::before {
  height: 26px;
  bottom: -25px;
}

.step-services-wrap .img-wrap:hover::after {
  height: 35px;
  bottom: -15px;
}

/*  7. call to action */
.call-to-action {
  margin-top: 72px;
  position: relative;
  padding-top: 41px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  background-position: top center;
}

.call-to-action::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.call-to-action .content-wrap {
  color: white;
  text-align: center;
  position: relative;
}

.call-to-action .content-wrap .title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  line-height: 1.5;
  padding-right: 15px;
  padding-left: 15px;
}

.call-to-action .content-wrap .subtitle {
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  line-height: 1.5;
  font-weight: bold;
  margin-top: 60px;
  letter-spacing: 2px;
  margin-bottom: 50px;
}

.call-to-action.order .content-wrap .subtitle {
  margin-top: 38px;
  margin-bottom: 46px;
}

.call-to-action.order .button {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
}

.call-to-action .form-subscribe {
  max-width: 430px;
  margin: auto;
  margin-top: 67px;
  margin-bottom: -39px;
}

.call-to-action .form-subscribe form {
  position: relative;
}

.call-to-action .form-subscribe form .mc4wp-response {
  position: absolute;
  top: -45px;
  width: 100%;
  text-align: center;
}

.call-to-action .form-subscribe form .mc4wp-response p {
  color: white;
}

.call-to-action .form-subscribe p {
  opacity: 1;
}

.call-to-action .form-subscribe input:not([type="submit"]) {
  background-color: transparent;
  border: none;
  text-align: center;
  color: white;
  border-bottom: 1px solid white;
  font-size: 14px;
  line-height: normal;
  font-family: "Libre Baskerville", sans-serif;
}

.call-to-action .form-subscribe input:not([type="submit"])::-webkit-input-placeholder {
  opacity: .7;
}

.call-to-action .form-subscribe input:not([type="submit"])::-moz-placeholder {
  opacity: .7;
}

.call-to-action .form-subscribe input:not([type="submit"]):-moz-placeholder {
  opacity: .7;
}

.call-to-action .form-subscribe input:not([type="submit"]):-ms-input-placeholder {
  opacity: .7;
}

.call-to-action .form-subscribe .submit-wrap {
  display: inline-block;
  background: #9E7B58;
  position: relative;
  margin-top: 42px;
}

.call-to-action .form-subscribe .submit-wrap::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.call-to-action .form-subscribe .submit-wrap:hover i {
  width: 27px;
  transform: translateX(calc(-50% + 4px));
}

.call-to-action .form-subscribe .submit-wrap:hover i::before {
  width: 24px;
}

.call-to-action .form-subscribe .submit-wrap i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition: all 350ms ease;
}

.call-to-action .form-subscribe .submit-wrap i::after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.call-to-action .form-subscribe .submit-wrap i::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  left: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 350ms ease;
}

.call-to-action .form-subscribe input[type="submit"] {
  background-color: transparent;
  color: white;
  text-align: left;
  padding-left: 20px;
  display: inline-block;
  border: none;
  outline: none;
  padding-right: 20px;
  width: 65px;
  position: relative;
  height: 27px;
  z-index: 10;
  cursor: pointer;
}

.call-to-action.subscribe {
  margin-top: 68px;
  padding-top: 22px;
  padding-bottom: 10px;
  margin-bottom: 64px;
}

* {
  outline: none;
}

div.wpcf7-validation-errors {
  border: none;
  text-align: center;
  color: #9E7B58;
  font-family: "Raleway", sans-serif;
  letter-spacing: .5px;
  font-size: 15px;
}

span.wpcf7-not-valid-tip {
  margin-top: -13px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
}

/*--------------------------------------------------------*/
/* TEAM */
/*--------------------------------------------------------*/
.team-wrap {
  position: relative;
  max-width: 450px;
  margin: auto;
}

.team-wrap .img-wrapper {
  width: 100%;
  height: 350px;
  position: relative;
  overflow: hidden;
}

.team-wrap .img-wrap {
  width: 100%;
  height: 350px;
  overflow: hidden;
  transition: all 400ms ease;
}

.team-wrap .content-wrapper {
  display: block;
  position: relative;
}

.team-wrap .content-wrapper::before {
  content: '';
  position: absolute;
  height: 100%;
  width: calc(100% - 90px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: .5;
  box-shadow: 0px 10px 50px 0px #AA855D;
}

.team-wrap .content-wrap {
  width: calc(100% - 42px);
  margin: auto auto 30px;
  background-color: #AA855D;
  text-align: center;
  position: relative;
  min-height: 40px;
  padding: 3px 15px;
}

.team-wrap .content-wrap .content {
  position: absolute;
  overflow: hidden;
  padding: 0 15px;
  top: 0px;
  margin-top: 5px;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
}

.team-wrap .content-wrap .title {
  position: relative;
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  line-height: 1.3;
  letter-spacing: 2px;
  padding-top: 6px;
  padding-bottom: 5px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
  transition: all 400ms ease;
}

.team-wrap .content-wrap .subtitle {
  position: absolute;
  top: 110%;
  width: 100%;
  left: 0;
  text-align: center;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #fff;
  line-height: 1.3;
  letter-spacing: 1.2px;
  transition: all 400ms ease;
}

.team-wrap .team-social-wrap {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  width: 100%;
  text-align: center;
  transition: all 400ms ease;
}

.team-wrap .team-social {
  opacity: 0;
  position: relative;
  padding: 9px;
  text-align: center;
  background-color: #fff;
  display: inline-block;
  transition: opacity 400ms ease-out;
}

.team-wrap .team-social a {
  font-size: 12px;
  padding-right: 8.5px;
  padding-left: 8.5px;
  color: #343434;
  line-height: 1.5;
  transition: all 300ms ease;
}

.team-wrap .team-social a:hover {
  color: #AA855D;
}

.team-wrap.hover .team-social {
  opacity: 1;
}

.team-wrap.hover .title {
  margin-top: -40%;
}

.team-wrap.hover .subtitle {
  top: 50%;
  transform: translateY(-50%);
}

.team-wrap.hover .team-social-wrap {
  bottom: 30px;
  transform: translate(0);
}

.team-wrap.hover .img-wrap {
  transform: scale(1.1);
}

/*--------------------------------------------------------*/
/* SKILLS */
/*--------------------------------------------------------*/
.skill-wrapper .main-title {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 2px;
}

.skill-wrapper .skills {
  margin-top: 14px;
  margin-bottom: 25px;
}

.skill-wrapper .skill .label-skill {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  letter-spacing: 1.4px;
  display: inline-block;
}

.skill-wrapper .skill .value {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  letter-spacing: 1.4px;
  display: inline-block;
  float: right;
}

.skill-wrapper .skill .line {
  background-color: #EEEEEE;
  position: relative;
  width: 100%;
  height: 20px;
  margin-bottom: 25px;
  margin-top: 12px;
}

.skill-wrapper .skill .active-line {
  background-color: #AA855D;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  transition: all 1500ms ease;
}

.skill-wrapper .skill .active-line::before {
  content: '';
  position: absolute;
  left: 10px;
  bottom: 0;
  width: calc(100% - 20px);
  height: 10px;
  display: block;
  z-index: 1;
  box-shadow: 0px 5px 24px 0px #AA855D;
  opacity: .5;
  transition: all 1500ms ease;
}

.section-wrapper {
  height: 100%;
  margin-left: 330px;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.gallery-wrap {
  margin: 30px auto 20px;
}

.gallery-wrap .item-gallery {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.gallery-wrap .item-gallery.big {
  width: 50%;
}

.gallery-wrap .item-gallery.small {
  width: 25%;
}

.gallery-wrap .item-gallery.long {
  width: 25%;
}

.gallery-wrap .item-gallery.longer {
  width: 50%;
}

.gallery-wrap.style-3 .item-gallery.big {
  width: 66.66%;
}

.gallery-wrap.style-3 .item-gallery.small {
  width: 33.33%;
}

.gallery-wrap.style-3 .item-gallery.long {
  width: 33.33%;
}

.gallery-wrap.style-3 .item-gallery.longer {
  width: 66.66%;
}

.gallery-wrap.style-2 .item-gallery.big {
  width: 100%;
}

.gallery-wrap.style-2 .item-gallery.small {
  width: 50%;
}

.gallery-wrap.style-2 .item-gallery.long {
  width: 50%;
}

.gallery-wrap.style-2 .item-gallery.longer {
  width: 100%;
}

.gallery-wrap .item-gallery .img-wrap {
  display: block;
  height: inherit;
  width: calc(100% - 10px);
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transition: filter 500ms ease;
}

.gallery-wrap .item-gallery .img-wrap::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: .9;
  transition: background-color 700ms ease;
}

.gallery-wrap span {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  opacity: 0;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 24px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  line-height: 1.2;
  transition: opacity 800ms ease;
}

.gallery-wrap .item-gallery:hover .img-wrap {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gallery-wrap .item-gallery:hover .img-wrap::before {
  background-color: #fff;
}

.gallery-wrap .item-gallery:hover span {
  opacity: 1;
}

.mfp-container {
  cursor: pointer;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-counter {
  display: none;
}

.mfp-arrow {
  background-color: #9E7B58;
  width: 65px;
  height: 27px;
  margin-top: 0;
  transform: translateY(-50%);
  transition: all 300ms ease;
}

.mfp-arrow.mfp-arrow-left {
  left: 20px;
}

.mfp-arrow.mfp-arrow-right {
  right: 20px;
}

.mfp-arrow:focus {
  opacity: .65;
}

.mfp-arrow:active {
  margin-top: 0;
  opacity: .65;
}

.mfp-arrow:hover {
  opacity: 1;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border: none;
  height: 1px;
  margin-top: 13px;
  margin-left: 21px;
  width: 20px;
  background-color: #fff;
  transition: margin 300ms ease;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border: none;
  top: 0;
  margin-top: 10px;
  margin-left: 41px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  transition: margin 300ms ease;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  border: none;
  height: 1px;
  margin-top: 13px;
  margin-left: 24px;
  width: 20px;
  background-color: #fff;
  transition: margin 300ms ease;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border: none;
  top: 0;
  margin-top: 10px;
  margin-left: 20px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid white;
  transition: margin 300ms ease;
}

.mfp-arrow-left:hover::before,
.mfp-arrow-left:hover .mfp-b {
  margin-left: 20px;
}

.mfp-arrow-left:hover::after,
.mfp-arrow-left:hover .mfp-a {
  margin-left: 16px;
}

.mfp-arrow-right:hover::before,
.mfp-arrow-right:hover .mfp-b {
  margin-left: 25px;
}

.mfp-arrow-right:hover::after,
.mfp-arrow-right:hover .mfp-a {
  margin-left: 45px;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

.hinge {
  animation-duration: 1s;
  animation-name: hinge;
}

.mfp-with-fade .mfp-content, .mfp-with-fade.mfp-bg {
  opacity: 0;
  transition: opacity .5s ease-out;
}

.mfp-with-fade.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0;
}

/*--------------------------------------------------------*/
.portfolio .item-gallery .title {
  position: static;
  transform: none;
}

.portfolio .item-gallery .category {
  position: static;
  transform: none;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #9E7B58;
  line-height: 1.2;
  margin-top: 30px;
  letter-spacing: 1.2px;
}

.portfolio-popup-hidden .filter {
  margin-top: 18px;
  margin-bottom: -10px;
}

.portfolio-popup-hidden .filter ul li {
  display: inline-block;
  list-style: none;
  padding: 12px 19px;
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 1.2;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: all 350ms ease;
}

.portfolio-popup-hidden .filter ul li:hover {
  color: #9E7B58;
}

.portfolio-popup-hidden .filter ul {
  margin: auto;
  text-align: center;
}

.close-popup {
  position: absolute;
  right: -15px;
  top: 19px;
  width: 27px;
  height: 27px;
  background: #9E7B58;
  cursor: pointer;
}

.close-popup i {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.close-popup:before,
.close-popup:after {
  display: block;
  content: '';
  height: 14px;
  width: 1px;
  background: #fff;
  top: 10px;
  left: 13px;
  position: relative;
}

.close-popup:before {
  transform: rotate(45deg);
  top: 7px;
  transition: all 300ms ease;
}

.close-popup:after {
  transform: rotate(-45deg);
  top: -7px;
  transition: all 300ms ease;
}

.close-popup:hover::before {
  transform: rotate(135deg);
}

.close-popup:hover:after {
  transform: rotate(45deg);
}

.popup-wrapper {
  position: relative;
  width: 100%;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s linear;
  -moz-transition: all .5s linear;
  -ms-transition: all .5s linear;
  -o-transition: all .5s linear;
  transition: all .5s linear;
}

.popup-wrapper.active {
  opacity: 1;
  visibility: visible;
}

.popup-wrapper.active .popup-box {
  padding: 70px;
}

.portfolio-top-nav {
  position: absolute;
  top: 15px;
  left: -15px;
}

.portfolio-top-nav .a-btn-prev {
  background-color: #9E7B58;
  color: white;
  text-align: left;
  padding-left: 20px;
  display: inline-block;
  padding-right: 20px;
  width: 65px;
  position: relative;
  height: 27px;
  cursor: pointer;
  margin-right: 10px;
}

.portfolio-top-nav .a-btn-prev::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: 0;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.portfolio-top-nav .a-btn-prev i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: relative;
  transition: all 350ms ease;
  transform: translateX(0);
}

.portfolio-top-nav .a-btn-prev i::after {
  content: '';
  height: 1px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: none;
  background-color: #fff;
  margin-left: 0;
}

.portfolio-top-nav .a-btn-prev i::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid white;
  left: 0;
  margin-left: 1px;
}

.portfolio-top-nav .a-btn-prev:hover i {
  width: 27px;
  transform: translateX(-4px);
}

.portfolio-top-nav .a-btn-prev:hover i::before {
  width: auto;
}

.portfolio-top-nav .a-btn-prev:hover i::after {
  width: 24px;
}

.portfolio-top-nav .a-btn-prev.disable i {
  opacity: .2;
}

.portfolio-top-nav .a-btn-prev.disable:hover i {
  width: 25px;
  transform: translateX(0);
}

.portfolio-top-nav .a-btn-prev.disable:hover i::after {
  width: 20px;
}

.portfolio-top-nav .a-btn-next {
  background-color: #9E7B58;
  color: white;
  text-align: left;
  padding-left: 20px;
  display: inline-block;
  padding-right: 20px;
  width: 65px;
  position: relative;
  height: 27px;
  cursor: pointer;
}

.portfolio-top-nav .a-btn-next::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;
  z-index: 0;
  box-shadow: 0px 13px 24px -10px #9e7b58;
  opacity: .5;
}

.portfolio-top-nav .a-btn-next i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: relative;
  transition: all 350ms ease;
  transform: translateX(0);
}

.portfolio-top-nav .a-btn-next i::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  left: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 350ms ease;
}

.portfolio-top-nav .a-btn-next i::after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.portfolio-top-nav .a-btn-next:hover i {
  width: 27px;
  transform: translateX(4px);
}

.portfolio-top-nav .a-btn-next:hover i::before {
  width: 24px;
}

.portfolio-top-nav .a-btn-next.disable i {
  opacity: .2;
}

.portfolio-top-nav .a-btn-next.disable:hover i {
  width: 25px;
  transform: translateX(0);
}

.portfolio-top-nav .a-btn-next.disable:hover i::before {
  width: 20px;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.clients-wrapper {
  padding: 20px 0;
}

.clients-wrapper .clients-wrap {
  margin: auto;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.clients-wrapper .clients-wrap .client-logo {
  list-style: none;
  display: inline-block;
  padding: 20px 15px;
  opacity: .4;
  flex: none;
  transition: all 300ms ease;
}

.clients-wrapper .clients-wrap .client-logo .img {
  max-height: 50px;
  width: auto;
}

.clients-wrapper .clients-wrap .client-logo:hover {
  opacity: 1;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.counters-wrapper {
  position: relative;
  padding: 25px 15px 27px;
  margin: 20px auto 50px;
}

.counters-wrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.counters-wrapper .counters-wrap {
  position: relative;
  z-index: 99;
  margin: auto;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.counters-wrapper .counter {
  list-style: none;
  display: inline-block;
  padding: 40px 25px;
  flex: none;
}

.counters-wrapper .counter span {
  display: block;
  text-align: center;
}

.counters-wrapper .counter .number {
  font-size: 36px;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

.counters-wrapper .counter .icon {
  font-size: 26px;
  color: #fff;
  margin-top: 20px;
}

.counters-wrapper .counter .title {
  margin-top: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  line-height: 1.3;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.wpcf7[role="form"] .paddings-form {
  padding-right: 55px;
  padding-left: 55px;
}

.wpcf7[role="form"] .title {
  font-size: 24px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 1.2px;
  line-height: 1.75;
  margin-bottom: 43px;
}

.wpcf7[role="form"] input:not([type="submit"]),
.wpcf7[role="form"] textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  color: #343434;
  font-size: 14px;
  padding: 10px 0;
  letter-spacing: .7px;
  margin-bottom: 25px;
  font-family: "Libre Baskerville", sans-serif;
  line-height: 1.2;
  transition: all 350ms ease;
}

.wpcf7[role="form"] input:not([type="submit"]):focus,
.wpcf7[role="form"] textarea:focus {
  border-bottom: 1px solid #9E7B58;
}

.wpcf7[role="form"] input:not([type="submit"])::-webkit-input-placeholder,
.wpcf7[role="form"] textarea::-webkit-input-placeholder {
  opacity: .5;
}

.wpcf7[role="form"] input:not([type="submit"])::-moz-placeholder,
.wpcf7[role="form"] textarea::-moz-placeholder {
  opacity: .5;
}

.wpcf7[role="form"] input:not([type="submit"]):-ms-input-placeholder,
.wpcf7[role="form"] textarea:-ms-input-placeholder {
  opacity: .5;
}

.wpcf7[role="form"] input:not([type="submit"]):-moz-placeholder,
.wpcf7[role="form"] textarea:-moz-placeholder {
  opacity: .5;
}

.wpcf7[role="form"] textarea {
  height: 40px;
}

.wpcf7[role="form"] input[type="submit"] {
  margin-top: 5px;
  font-size: 11px;
  font-family: "Raleway", sans-serif;
  color: #fff;
  position: relative;
  padding: 16px 43px;
  letter-spacing: 1px;
  z-index: 10;
  font-weight: bold;
  background-color: #9E7B58;
}

.wpcf7[role="form"] .submit-wrap {
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
}

.wpcf7[role="form"] .submit-wrap img {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translate(100%, -50%);
}

.wpcf7[role="form"] .submit-wrap::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  height: 50%;
  display: block;
  z-index: 0;
  box-shadow: 0px 7px 30px 0px #9E7B58;
  opacity: .5;
}

.wpcf7[role="form"] .submit-wrap input {
  transition: all 180ms ease;
  border-radius: 0px;
  transition-delay: 150ms;
}

.wpcf7[role="form"] .submit-wrap:hover input {
  color: transparent;
  transition-delay: 0ms;
}

.wpcf7[role="form"] .submit-wrap {
  position: relative;
}

.wpcf7[role="form"] .submit-wrap i {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: absolute;
  top: 13px;
  left: 20%;
  opacity: 0;
  transform: translateX(-50%);
  z-index: 100;
  transition: all 150ms ease;
}

.wpcf7[role="form"] .submit-wrap i::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  left: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 150ms ease;
}

.wpcf7[role="form"] .submit-wrap i::after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.wpcf7[role="form"] .submit-wrap:hover i {
  left: 50%;
  opacity: 1;
  transition-delay: 180ms;
}

.single-post-wrap .form-submit input[type="submit"] {
  transition: all 180ms ease;
  border-radius: 0px;
  transition-delay: 150ms;
}

.single-post-wrap .form-submit:hover input[type="submit"] {
  color: transparent;
  transition-delay: 0ms;
}

.comment-respond .form-submit {
  position: relative;
}

.comment-respond .form-submit .isubmit {
  display: inline-block;
  width: 25px;
  height: 27px;
  position: absolute;
  top: 13px;
  left: 20%;
  opacity: 0;
  transform: translateX(-50%);
  z-index: 100;
  transition: all 150ms ease;
}

.comment-respond .form-submit .isubmit::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  left: 0;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 350ms ease;
}

.comment-respond .form-submit .isubmit::after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
}

.comment-respond .form-submit:hover .isubmit {
  width: 27px;
  transform: translateX(calc(50% + 27px));
  opacity: 1;
}

.comment-respond .form-submit:hover .isubmit::before {
  width: 24px;
}

.maps-wrapper {
  position: relative;
}

.maps-wrapper .contact-info {
  padding: 17px 30px 50px;
  width: 100%;
  max-width: 300px;
  position: absolute;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.maps-wrapper .contact-info .info-box {
  margin-bottom: 20px;
}

.maps-wrapper .contact-info .info-box .title {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 2px;
  line-height: 1.2;
  position: relative;
  margin-bottom: 20px;
}

.maps-wrapper .contact-info .info-box .title::after {
  content: '';
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 15px;
  height: 3px;
  background-color: #9E7B58;
}

.maps-wrapper .contact-info .info-box .title::before {
  content: '';
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 15px;
  height: 3px;
  box-shadow: 0px 2px 7px 0px #aa855d;
  opacity: .5;
}

.maps-wrapper .contact-info .info-box .details {
  margin-top: 10px;
  margin-bottom: 12px;
}

.maps-wrapper .contact-info .info-box h6,
.maps-wrapper .contact-info .info-box a {
  text-decoration: none;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  display: block;
  opacity: .7;
  margin: 0;
  letter-spacing: 1.05px;
  line-height: 1.5;
}

.maps-wrapper .contact-info .info-box h6:hover, .maps-wrapper .contact-info .info-box h6:focus, .maps-wrapper .contact-info .info-box h6:visited, .maps-wrapper .contact-info .info-box h6:active,
.maps-wrapper .contact-info .info-box a:hover,
.maps-wrapper .contact-info .info-box a:focus,
.maps-wrapper .contact-info .info-box a:visited,
.maps-wrapper .contact-info .info-box a:active {
  color: #343434;
  text-decoration: none;
}

.maps-wrapper .contact-info .info-box a {
  transition: all 350ms ease;
}

.maps-wrapper .contact-info .info-box a:hover {
  color: #9E7B58;
}

#google-map {
  height: 500px;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.single-portfolio-item {
  background-color: #fff;
  position: relative;
  margin-bottom: 40px;
  padding: 70px;
}

.single-portfolio-item::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 70px);
  height: 100%;
  display: block;
  z-index: -1;
  box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
}

.single-portfolio-item.popup-box {
  margin-bottom: 0;
  padding: 0;
}

.single-portfolio-item.popup-box::before {
  display: none;
}

.single-portfolio-item .title {
  font-size: 14px;
  letter-spacing: 1.8px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 13px;
}

.single-portfolio-item .single-portfolio-section p {
  font-size: 13.5px;
  letter-spacing: 1.1px;
}

.single-portfolio-item .single-portfolio-section p img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 5px;
}

.single-portfolio-item .single-portfolio-section p iframe {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5px;
  height: 675px;
}

@media screen and (max-width: 767px) {
  .single-portfolio-item .single-portfolio-section p iframe {
    height: 300px;
  }
}

.single-portfolio-item .single-portfolio-section .large-sliders {
  margin-top: 25px;
  position: relative;
  margin-bottom: 10px;
}

.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-right,
.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 12px 17px;
  cursor: pointer;
  transition: all 400ms ease;
}

.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-right:hover,
.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-left:hover {
  color: #fff;
  background-color: #343434;
}

.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-right {
  right: 30px;
}

.single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-left {
  left: 30px;
}

.single-portfolio-item .single-portfolio-section .large-sliders img {
  width: 100%;
}

.single-portfolio-item .single-portfolio-section .thumbnails {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 50px;
}

.single-portfolio-item .single-portfolio-section .thumbnails .swiper-slide {
  padding: 5px;
}

.single-portfolio-item .single-portfolio-section .thumbnails .swiper-slide.current .image-wrap::before {
  opacity: 0;
}

.single-portfolio-item .single-portfolio-section .thumbnails .image-wrap {
  width: 100%;
  position: relative;
}

.single-portfolio-item .single-portfolio-section .thumbnails .image-wrap::before {
  content: '';
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  opacity: .7;
  transition: all 400ms ease;
}

.single-portfolio-item .single-portfolio-section .thumbnails .image-wrap:hover::before {
  opacity: 0;
}

.single-portfolio-item .schort_desc {
  margin-bottom: 20px;
}

.single-portfolio-item .schort_desc h3 {
  letter-spacing: 1.8px;
}

.single-portfolio-item .schort_desc p {
  font-size: 13.5px;
  letter-spacing: 1.6px;
}

.single-portfolio-item .clients {
  display: inline-block;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 1.2;
  margin-right: 14px;
  letter-spacing: 1.1px;
}

.single-portfolio-item .clients .clients-title {
  opacity: .7;
}

.single-portfolio-item .category-portfolio,
.single-portfolio-item .category-portfolio a,
.single-portfolio-item .category-portfolio a:active,
.single-portfolio-item .category-portfolio a:visited,
.single-portfolio-item .category-portfolio a:focus {
  display: inline-block;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 1.2;
  letter-spacing: 1.1px;
}

.single-portfolio-item .category-portfolio .category-title,
.single-portfolio-item .category-portfolio a .category-title,
.single-portfolio-item .category-portfolio a:active .category-title,
.single-portfolio-item .category-portfolio a:visited .category-title,
.single-portfolio-item .category-portfolio a:focus .category-title {
  opacity: .7;
}

.single-portfolio-item .category-portfolio a,
.single-portfolio-item .category-portfolio a a,
.single-portfolio-item .category-portfolio a:active a,
.single-portfolio-item .category-portfolio a:visited a,
.single-portfolio-item .category-portfolio a:focus a {
  text-decoration: none;
  transition: all 300ms ease;
  position: relative;
  margin-bottom: 6px;
}

.single-portfolio-item .category-portfolio a::before,
.single-portfolio-item .category-portfolio a a::before,
.single-portfolio-item .category-portfolio a:active a::before,
.single-portfolio-item .category-portfolio a:visited a::before,
.single-portfolio-item .category-portfolio a:focus a::before {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -5px;
  background-color: #343434;
  transition: all 300ms ease;
}

.single-portfolio-item .category-portfolio a:hover,
.single-portfolio-item .category-portfolio a a:hover,
.single-portfolio-item .category-portfolio a:active a:hover,
.single-portfolio-item .category-portfolio a:visited a:hover,
.single-portfolio-item .category-portfolio a:focus a:hover {
  color: #9E7B58;
}

.single-portfolio-item .category-portfolio a:hover::before,
.single-portfolio-item .category-portfolio a a:hover::before,
.single-portfolio-item .category-portfolio a:active a:hover::before,
.single-portfolio-item .category-portfolio a:visited a:hover::before,
.single-portfolio-item .category-portfolio a:focus a:hover::before {
  background-color: #9E7B58;
}

.single-portfolio-item .share-this {
  margin-top: 19px;
}

.single-portfolio-item .share-this .title {
  display: inline-block;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 1.2;
  margin-right: 14px;
  letter-spacing: 1.1px;
  opacity: .7;
  text-transform: none;
  font-weight: normal;
  margin-bottom: auto;
}

.single-portfolio-item .share-this span {
  font-size: 12px;
  display: inline-block;
  color: #343434;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
  transition: all 350ms ease;
}

.single-portfolio-item .share-this span:hover {
  color: #9E7B58;
}

.isubmit {
  cursor: pointer;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.post-content-wrap {
  background-color: #fff;
  position: relative;
  margin-bottom: 50px;
  padding: 70px 70px 57px;
}

.post-content-wrap::before {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  z-index: -1;
  height: 90%;
  left: 50%;
  width: calc(100% - 50px);
  transform: translateX(-50%);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
}

.post-content-wrap .gallery-item {
  float: left;
}

.post-content-wrap:after {
  clear: both;
  content: '';
  display: table;
}

.post-content-wrap .post-banner {
  height: 350px;
  position: relative;
}

.post-content-wrap .post-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
}

.post-content-wrap .post-banner .post-title {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
  width: 100%;
  z-index: 10;
  line-height: 1.2;
  letter-spacing: -.2px;
  text-align: center;
  position: absolute;
  bottom: 31px;
}

.post-content-wrap .post-banner .meta-info-post {
  background-color: #fff;
  padding: 13px 20px;
  z-index: 10;
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.post-content-wrap .post-banner .meta-info-post span,
.post-content-wrap .post-banner .meta-info-post a {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  opacity: .7;
  line-height: 1.2;
  letter-spacing: .5px;
}

.post-content-wrap .post-banner .meta-info-post a {
  margin-right: 10px;
  transition: all 300ms ease;
}

.post-content-wrap .post-banner .meta-info-post a:hover, .post-content-wrap .post-banner .meta-info-post a:focus, .post-content-wrap .post-banner .meta-info-post a:active, .post-content-wrap .post-banner .meta-info-post a:visited {
  color: #9E7B58;
}

.post-content-wrap .post-banner .meta-info-post i {
  color: #9E7B58;
  margin-right: 7px;
}

.post-content-wrap .post-content {
  margin-top: 63px;
  margin-bottom: 58px;
}

.post-content-wrap .post-content h1,
.post-content-wrap .post-content h2,
.post-content-wrap .post-content h3,
.post-content-wrap .post-content h4,
.post-content-wrap .post-content h5,
.post-content-wrap .post-content h6 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.post-content-wrap .post-content p {
  letter-spacing: 1.4px;
  line-height: 22px;
  margin-bottom: 20px;
}

.post-content-wrap .post-content ul li,
.post-content-wrap .post-content ol li {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
}

.post-content-wrap .post-content .gallery {
  margin-right: -7px;
  margin-left: -7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.post-content-wrap .post-category {
  display: inline-block;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  width: calc(100% - 150px);
  line-height: 1.2;
  margin-right: 14px;
  letter-spacing: 1.1px;
}

.post-content-wrap .post-category.tag {
  margin-top: 20px;
}

.post-content-wrap .post-category .title_category {
  opacity: .7;
}

.post-content-wrap .post-category a {
  color: #343434;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  transition: all 300ms ease;
}

.post-content-wrap .post-category a::before {
  content: '';
  position: absolute;
  bottom: -5px;
  height: 1px;
  width: 100%;
  background-color: #343434;
  transition: all 300ms ease;
}

.post-content-wrap .post-category a:hover,
.post-content-wrap .post-category a:focus,
.post-content-wrap .post-category a:visited,
.post-content-wrap .post-category a:active {
  color: #9E7B58;
}

.post-content-wrap .post-category a:hover::before,
.post-content-wrap .post-category a:focus::before,
.post-content-wrap .post-category a:visited::before,
.post-content-wrap .post-category a:active::before {
  background-color: #9E7B58;
}

.post-content-wrap .share-social {
  display: inline-block;
  float: right;
}

.post-content-wrap .share-social span {
  font-size: 12px;
  display: inline-block;
  color: #343434;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
  transition: all 350ms ease;
}

.post-content-wrap .share-social span:hover {
  color: #9E7B58;
}

.text-center {
  text-align: center;
}

.search-post-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.search-post-content h5 {
  line-height: 1.4;
}

blockquote {
  border: none;
  position: relative;
  padding: 1px 30px;
  margin: 45px auto;
}

blockquote p {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #343434;
  opacity: 1;
  margin: 5px 0;
  line-height: 28px;
}

blockquote::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 6px;
  height: 100%;
  background-color: #9E7B58;
}

blockquote::after {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  display: block;
  width: 5px;
  height: 100%;
  box-shadow: 0px 7px 25px 0px #aa855d;
  opacity: .5;
}

table a {
  color: #343434;
  transition: all 300ms ease;
  opacity: 1;
}

table a:hover {
  color: #9E7B58;
  opacity: 1;
}

.post-nav span:not(:first-child),
.post-nav a {
  display: inline-block;
  padding: 5px 7px 5px 9px;
  color: white;
  background-color: #9E7B58;
  border: 1px solid #9E7B58;
  text-decoration: none;
  transition: all 300ms ease;
  margin-right: 3px;
  margin-left: 3px;
}

.post-nav span:not(:first-child):hover,
.post-nav a:hover {
  background-color: transparent;
  color: #343434;
}

.post-nav {
  letter-spacing: 3px;
}

.post-password-form input[type="submit"] {
  background-color: #000;
}

.post-nav span:first-child {
  margin-right: 5px;
  letter-spacing: normal;
}

.post-content {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #343434;
}

.post-content a {
  text-decoration: underline;
}

.post-content ul li,
.post-content ol li {
  line-height: 1.5;
}

.post-content ul,
.post-content ol {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #a7a2a2;
}

.post-content ul ul,
.post-content ul ol,
.post-content ol ol,
.post-content ol ul {
  margin-top: 0;
  margin-bottom: 0;
  color: #a7a2a2;
}

.post-content b,
.post-content strong {
  color: #000;
}

.pingback {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  margin: 0px auto 30px;
}

.pingback a {
  text-decoration: underline;
  color: #343434;
  line-height: 1.5;
  transition: all 300ms ease;
}

.pingback a:hover {
  color: #9E7B58;
}

.main-post-pagination {
  margin: 50px 0 40px;
}

.main-post-pagination .next-btn a,
.main-post-pagination .prev-btn a {
  font-size: 13px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 300ms ease;
}

.main-post-pagination .next-btn a:hover,
.main-post-pagination .prev-btn a:hover {
  color: #9E7B58;
}

.main-post-pagination .next-btn {
  float: right;
}

.main-post-pagination .prev-btn {
  float: left;
}

.post-content a {
  color: #343434;
  transition: all 300ms ease;
  opacity: 1;
}

.post-content a:hover {
  color: #9E7B58;
  opacity: 1;
}

.blog-comment {
  list-style: none;
  display: block;
  padding-left: 15px;
  margin-bottom: 17px;
  min-height: 110px;
}

.comments-post {
  background-color: #fff;
  position: relative;
  margin-bottom: 50px;
  padding: 45px 0px 30px;
}

.comments-post::before {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  z-index: -1;
  height: 90%;
  left: 50%;
  width: calc(100% - 50px);
  transform: translateX(-50%);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
}

.comments-post input::-webkit-input-placeholder,
.comments-post textarea::-webkit-input-placeholder {
  color: #343434;
}

.comments-post input:-moz-placeholder,
.comments-post textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #343434;
}

.comments-post input::-moz-placeholder,
.comments-post textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #343434;
}

.comments-post input:-ms-input-placeholder,
.comments-post textarea:-ms-input-placeholder {
  color: #343434;
}

.comments-post input:not([type="submit"]),
.comments-post textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  color: #343434;
  font-size: 14px;
  width: 100%;
  padding: 0;
  height: 37px;
  letter-spacing: .7px;
  margin-bottom: 20px;
  font-family: "Libre Baskerville", sans-serif;
  line-height: 1.2;
  border-radius: 0;
  transition: all 350ms ease;
}

.comments-post input:not([type="submit"]):focus,
.comments-post textarea:focus {
  border-bottom: 1px solid #9E7B58;
}

.comments-post input:not([type="submit"])::-webkit-input-placeholder,
.comments-post textarea::-webkit-input-placeholder {
  opacity: .5;
}

.comments-post input:not([type="submit"])::-moz-placeholder,
.comments-post textarea::-moz-placeholder {
  opacity: .5;
}

.comments-post input:not([type="submit"]):-ms-input-placeholder,
.comments-post textarea:-ms-input-placeholder {
  opacity: .5;
}

.comments-post input:not([type="submit"]):-moz-placeholder,
.comments-post textarea:-moz-placeholder {
  opacity: .5;
}

.comments-post textarea {
  height: 40px;
}

.comments-post button,
.comments-post input[type="submit"] {
  margin-top: 5px;
  font-size: 11px;
  font-family: "Raleway", sans-serif;
  color: #fff;
  position: relative;
  padding: 16px 41px;
  letter-spacing: 1px;
  z-index: 10;
  font-weight: bold;
  background-color: #9E7B58;
  border: none;
  outline: none;
}

.comments-post button:hover, .comments-post button:focus,
.comments-post input[type="submit"]:hover,
.comments-post input[type="submit"]:focus {
  outline: none;
}

.comments-post .form-submit {
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  opacity: 1;
  margin-left: 15px;
  margin-top: 5px;
}

.comments-post .form-submit img {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translate(100%, -50%);
}

.comments-post .form-submit::before {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  height: 50%;
  display: block;
  z-index: 0;
  box-shadow: 0px 7px 30px 0px rgba(158, 123, 88, 0.5);
  opacity: .5;
}

.comments-post #cancel-comment-reply-link {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #343434;
  opacity: 1;
  margin: 5px 0;
  line-height: 28px;
}

.comments-post .comments_title {
  font-size: 24px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 1px;
  line-height: 1.5;
  text-transform: none;
  margin-bottom: 45px;
  padding-left: 15px;
}

.comments-post .photo-comment {
  display: inline-block;
  float: left;
  margin: 10px 28px 0 0;
}

.comments-post .photo-comment img {
  border-radius: 50%;
}

.comments-post .com-name {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 2.2px;
  margin-right: 4px;
  margin-bottom: 3px;
}

.comments-post .date {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
  letter-spacing: .4px;
}

.comments-post .com-text {
  overflow: hidden;
}

.comments-post .com-text h1,
.comments-post .com-text h2,
.comments-post .com-text h3,
.comments-post .com-text h4,
.comments-post .com-text h5,
.comments-post .com-text h6 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.comments-post .com-text p {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #343434;
  line-height: 1.5;
  letter-spacing: 1.2px;
  margin-bottom: 10px;
}

.comments-post .com-text p:empty {
  display: none;
}

.comments-post .com-text a {
  text-decoration: underline;
  color: #343434;
  transition: all 300ms ease;
}

.comments-post .com-text a:hover {
  color: #9E7B58;
}

.comments-post .com-text ul li,
.comments-post .com-text ol li {
  line-height: 1.5;
}

.comments-post .com-text ul,
.comments-post .com-text ol {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #a7a2a2;
}

.comments-post .com-text ul ul,
.comments-post .com-text ul ol,
.comments-post .com-text ol ol,
.comments-post .com-text ol ul {
  margin-top: 0;
  margin-bottom: 0;
  opacity: 1;
}

.comments-post .com-text b,
.comments-post .com-text strong {
  color: #000;
}

.comments-post .com-reply {
  display: inline-block;
}

.comments-post .com-reply a {
  font-size: 11px;
  font-family: "Raleway", sans-serif;
  color: #9E7B58;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 350ms ease;
}

.comments-post .com-reply a:hover {
  color: #343434;
  opacity: .5;
}

.comments-post .children {
  padding-left: 100px;
  margin: 0;
}

.comments-post .comment-respond {
  margin-top: 33px;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.news-item.classic {
  background-color: #fff;
  max-width: 100%;
}

.news-item.classic .post_item {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.news-item.classic .post_item.sticky .post-item-link {
  display: inline-block;
}

.news-item.classic .post_item.sticky .post-item-link::before {
  content: "\f24a";
  font-family: "FontAwesome";
  color: #9E7B58;
  margin-right: 10px;
}

.news-item.classic .post-media-wrap {
  float: left;
  width: 50%;
  height: 100%;
  position: absolute;
  min-height: 250px;
}

.news-item.classic .post-media-wrap .img-wrap {
  height: inherit;
}

.news-item.classic .post-media-wrap .wrapper_el_video {
  position: relative;
}

.news-item.classic .post-media-wrap .wrapper_el_video iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.news-item.classic .post-media-wrap .blog-video {
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

.news-item.classic .post-media-wrap .blog-video p {
  height: 100%;
  margin: 0;
  line-height: normal;
}

.news-item.classic .post-media-wrap .blog-video iframe {
  margin: 0;
  max-width: 102%;
  margin-top: -3px;
  margin-left: -1%;
}

.news-item.classic .post-media-wrap .img-wrap-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-item.classic .post-media-wrap.standart {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.news-item.classic .post-media-wrap.gallery {
  float: left;
  height: 100%;
}

.news-item.classic .post-media-wrap.gallery .pagination {
  display: none;
}

.news-item.classic .post-media-wrap.gallery .swiper-arrow-left,
.news-item.classic .post-media-wrap.gallery .swiper-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 12px 17px;
  cursor: pointer;
  transition: all 400ms ease;
}

.news-item.classic .post-media-wrap.gallery .swiper-arrow-left:hover,
.news-item.classic .post-media-wrap.gallery .swiper-arrow-right:hover {
  color: #fff;
  background-color: #343434;
}

.news-item.classic .post-media-wrap.gallery .swiper-arrow-left {
  left: 40px;
}

.news-item.classic .post-media-wrap.gallery .swiper-arrow-right {
  right: 40px;
}

.news-item.classic .bloc-content-wrapper {
  padding: 40px;
  float: right;
  width: 50%;
}

.news-item.classic .bloc-content-wrapper.gallery {
  float: right;
}

.news-item.classic .bloc-content-wrapper .meta-data {
  background-color: #fff;
  padding: 13px 0px;
}

.news-item.classic .bloc-content-wrapper .meta-data .admin-post {
  margin-right: 11px;
  display: inline-block;
}

.news-item.classic .bloc-content-wrapper .meta-data .date-post {
  display: inline-block;
}

.news-item.classic .bloc-content-wrapper .meta-data span,
.news-item.classic .bloc-content-wrapper .meta-data a {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.2;
  letter-spacing: .5px;
}

.news-item.classic .bloc-content-wrapper .meta-data a {
  margin-right: 10px;
  transition: all 300ms ease;
}

.news-item.classic .bloc-content-wrapper .meta-data a:hover, .news-item.classic .bloc-content-wrapper .meta-data a:focus, .news-item.classic .bloc-content-wrapper .meta-data a:active, .news-item.classic .bloc-content-wrapper .meta-data a:visited {
  color: #9E7B58;
}

.news-item.classic .bloc-content-wrapper .meta-data i {
  color: #9E7B58;
  margin-right: 7px;
}

.news-item.classic .bloc-content-wrapper .post-item-link {
  font-size: 24px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 1.2px;
  line-height: 1.2;
  display: block;
  margin: 4px 0 19px 0;
  transition: all 400ms ease;
}

.news-item.classic .bloc-content-wrapper .post-item-link:hover {
  color: #9E7B58;
}

.news-item.classic .bloc-content-wrapper .short_desc {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
}

.news-item.classic .bloc-content-wrapper .button {
  margin-top: 24px;
  padding: 15px 50px 15px 39px;
  margin-bottom: 15px;
}

.news-item.classic.style2 .post-media-wrap {
  float: right;
  right: 0;
}

.news-item.classic.style2 .bloc-content-wrapper {
  float: left;
}

.news-item {
  position: relative;
}

.news-item::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 90%;
  height: 30px;
  transform: translateX(-50%);
  display: block;
  z-index: -1;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
}

.button-play {
  background-color: #fff;
  color: #9E7B58;
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 12px 30px;
  transition: all 300ms ease;
}

.button-play:hover {
  background-color: #9E7B58;
  color: #fff;
}

.news-item {
  margin-bottom: 30px;
}

.news-item.masonry {
  width: calc((100% - 60px)/3);
  background-color: #fff;
}

.news-item.masonry.true {
  width: calc((100% - 30px)/2);
}

.news-item.masonry .post-media-wrap {
  position: relative;
}

.news-item.masonry .post-media-wrap .blog-video {
  max-width: 100%;
  overflow: hidden;
  height: 250px;
}

.news-item.masonry .post-media-wrap .blog-video p {
  margin: 0;
  line-height: normal;
}

.news-item.masonry .post-media-wrap .blog-video iframe {
  margin: -3px 0 0 -1%;
  max-width: 102%;
  height: 250px;
}

.news-item.masonry .post-media-wrap .img-wrap-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-item.masonry .post-media-wrap.standart {
  min-height: 70px;
  background-color: #9E7B58;
  display: block;
}

.news-item.masonry .post-media-wrap.standart img {
  width: 100%;
  display: block;
}

.news-item.masonry .post-media-wrap.gallery {
  position: relative;
}

.news-item.masonry .post-media-wrap.gallery .img-wrap img {
  height: 300px;
}

.news-item.masonry .post-media-wrap.gallery .pagination {
  display: none;
}

.news-item.masonry .post-media-wrap.gallery .swiper-arrow-left,
.news-item.masonry .post-media-wrap.gallery .swiper-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 12px 17px;
  cursor: pointer;
  transition: all 400ms ease;
}

.news-item.masonry .post-media-wrap.gallery .swiper-arrow-left:hover,
.news-item.masonry .post-media-wrap.gallery .swiper-arrow-right:hover {
  color: #fff;
  background-color: #343434;
}

.news-item.masonry .post-media-wrap.gallery .swiper-arrow-left {
  left: 40px;
}

.news-item.masonry .post-media-wrap.gallery .swiper-arrow-right {
  right: 40px;
}

.news-item.masonry .bloc-content-wrapper {
  position: relative;
  padding: 0px 23px 20px;
}

.news-item.masonry .bloc-content-wrapper .button {
  display: none;
}

.news-item.masonry .bloc-content-wrapper .meta-data {
  background-color: #fff;
  padding: 13px 19px;
  position: relative;
  display: inline-block;
  top: -17px;
  left: 25px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.news-item.masonry .bloc-content-wrapper .meta-data .admin-post {
  margin-right: 0px;
  display: inline-block;
}

.news-item.masonry .bloc-content-wrapper .meta-data .date-post {
  display: inline-block;
}

.news-item.masonry .bloc-content-wrapper .meta-data span,
.news-item.masonry .bloc-content-wrapper .meta-data a {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.2;
  letter-spacing: .5px;
}

.news-item.masonry .bloc-content-wrapper .meta-data a {
  margin-right: 10px;
  transition: all 300ms ease;
}

.news-item.masonry .bloc-content-wrapper .meta-data a:hover, .news-item.masonry .bloc-content-wrapper .meta-data a:focus, .news-item.masonry .bloc-content-wrapper .meta-data a:active, .news-item.masonry .bloc-content-wrapper .meta-data a:visited {
  color: #9E7B58;
}

.news-item.masonry .bloc-content-wrapper .meta-data i {
  color: #9E7B58;
  margin-right: 7px;
}

.news-item.masonry .bloc-content-wrapper .post-item-link {
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  color: #343434;
  letter-spacing: 1.2px;
  line-height: 1.2;
  display: block;
  margin: 4px 0 14px 0;
  transition: all 400ms ease;
}

.news-item.masonry .bloc-content-wrapper .post-item-link:hover {
  color: #9E7B58;
}

.news-item.masonry .bloc-content-wrapper .short_desc {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  line-height: 1.5;
}

.news-item.masonry .bloc-content-wrapper .button {
  margin-top: 24px;
  padding: 15px 50px 15px 39px;
  margin-bottom: 15px;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
.sidebar-container {
  background: white;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
}

.sidebar-container::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 90%;
  height: 30px;
  transform: translateX(-50%);
  display: block;
  z-index: -1;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
}

.sidebar-container .widget_search {
  display: block;
}

.sidebar-container .widget_search form {
  height: 38px;
}

.sidebar-container .widget_search input {
  width: calc(100% - 40px);
  float: left;
  border-radius: 0;
}

.sidebar-container button {
  width: 40px;
  float: right;
  padding: 11px 14px 11px;
  background-color: #9E7B58;
  border-radius: 0;
  border: 1px solid #9E7B58;
  transition: all 300ms ease;
}

.sidebar-container button:hover {
  background-color: transparent;
  color: #9E7B58;
}

.sidebar-container .widget-section {
  margin-bottom: 30px;
}

.sidebar-container .widget-section .widget-title {
  margin-bottom: 15px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.sidebar-container .widget-section ul li {
  padding: 5px 0;
  line-height: 1.5;
  letter-spacing: .3px;
}

.sidebar-container .widget-section ul li,
.sidebar-container .widget-section ul li a,
.sidebar-container .widget-section label,
.sidebar-container .widget-section select,
.sidebar-container .widget-section option {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
  list-style: none;
}

.sidebar-container .widget-section ul li a {
  transition: all 300ms ease;
}

.sidebar-container .widget-section ul li a:hover {
  color: #9E7B58;
}

.sidebar-container .widget_recent_comments ul li a {
  text-decoration: underline;
}

.sidebar-container table th,
.sidebar-container table td {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #a7a2a2;
}

.sidebar-container table th {
  color: #343434;
  font-weight: bold;
}

.sidebar-container select {
  width: 100%;
  padding: 5px;
}

.sidebar-container .widget_rss a.rsswidget {
  display: block;
  color: #343434;
  font-weight: bold;
}

.sidebar-container .widget_rss .widget-title a.rsswidget {
  display: inline-block;
}

.sidebar-container .widget_rss span.rss-date {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sidebar-container .widget_rss .rssSummary + cite {
  display: block;
  margin: 5px 0 20px;
}

.sidebar-container .tagcloud a {
  color: #343434;
  transition: all 300ms ease;
  font-weight: 100;
}

.sidebar-container .tagcloud a:hover {
  color: #9E7B58;
}

.logged-in-as {
  padding: 0 15px;
}

.logged-in-as a {
  color: #343434;
  transition: all 300ms ease;
}

.logged-in-as a:hover {
  color: #9E7B58;
}

@media screen and (max-width: 1600px) {
  .news-item.masonry.true {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .simple-banner-wrap .content {
    max-width: 500px;
  }
  .banner-slider-wrap .slider-content {
    max-width: 510px;
  }
  .simple-banner-wrap .body-height {
    height: calc(100vh - 455px);
  }
  .creative-slider-wrap .creative-content {
    height: 230px;
    padding-top: 45px;
  }
  .creative-slider-wrap .creative-content .button {
    margin-top: 25px;
  }
  .creative-slider-wrap .wrap-arrows {
    top: calc(100vh - 380px);
  }
  .creative-slider-wrap .pagination {
    bottom: 70px;
    margin: 0;
  }
  .creative-slider-wrap .pagination span {
    font-size: 140px;
  }
  .creative-slider-wrap .img-slider-wrap.body-height {
    height: calc(100vh - 390px);
  }
  .comments-post .children {
    padding-left: 50px;
  }
  .news-item.masonry {
    width: calc((100% - 30px) / 2);
  }
}

@media screen and (max-width: 1390px) {
  body {
    margin: 30px 30px 10px;
  }
  .left-navigation {
    margin-bottom: 30px;
    min-height: calc(100vmin - 120px);
  }
  .section-wrapper {
    margin-left: 310px;
  }
  .body-height {
    height: calc(100vh - 70px);
  }
  .banner-slider-wrap .body-height {
    height: calc(100vh - 130px);
  }
  .creative-slider-wrap .img-slider-wrap.body-height {
    height: calc(100vh - 360px);
  }
  .creative-slider-wrap .wrap-arrows {
    top: calc(100vh - 360px);
  }
  .headlines-wrap.style1 .headlines {
    min-height: 200px;
  }
  .headlines-wrap {
    padding: 50px 0px 20px;
  }
  .headlines-wrap.style2 {
    padding-top: 35px;
  }
  .headlines-wrap.style1 .headlines {
    margin-bottom: 0;
  }
  .call-to-action {
    margin-top: 22px;
  }
  .headlines-wrap.style2 .headlines {
    margin-bottom: 14px;
  }
  .call-to-action.subscribe {
    margin: 35px auto 40px;
  }
  .counters-wrapper .counter .icon {
    margin-top: 10px;
  }
  .counters-wrapper .counter .title {
    margin-top: 15px;
  }
  .counters-wrapper .counter {
    padding: 30px 25px;
  }
  .counters-wrapper {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 992px) {
  .left-navigation {
    position: fixed;
  }
}

@media screen and (max-width: 991px) {
  .services-wrap .text {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .headlines-wrap.style2 .headlines .content .headlines-subtitle {
    font-size: 13px;
  }
  .step-services-wrap .text {
    font-size: 13px;
  }
  .navigation {
    width: 100%;
  }
  .brand-res a {
    display: inline-block;
    padding: 10px 0;
  }
  .brand-res a img {
    max-height: 70px;
  }
  .section-wrapper {
    margin-left: 0px;
  }
  body {
    padding-top: 75px;
  }
  .creative-slider-wrap .img-slider-wrap.body-height {
    height: calc(100vh - 445px);
  }
  .creative-slider-wrap .wrap-arrows {
    top: calc(100vh - 435px);
  }
  .creative-slider-wrap .creative-content .title {
    font-size: 30px;
  }
  .body-height {
    height: calc(100vh - 135px);
  }
  .banner-slider-wrap .body-height {
    height: calc(100vh - 215px);
  }
  .responsive-nav {
    position: fixed;
    height: 75px;
    top: 30px;
    display: table;
    table-layout: fixed;
    background-color: #fff;
    padding: 0px 30px;
    z-index: 1000;
    transition: top 300ms ease;
  }
  .responsive-nav .fixed-sidebar-footer {
    position: relative;
    padding-top: 0;
  }
  .responsive-nav .menu {
    padding-bottom: 30px;
  }
  .responsive-nav.scroll {
    top: 0;
  }
  .responsive-nav .brand-res {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    background-color: #fff;
  }
  .responsive-nav .menu-button {
    display: table-cell;
    vertical-align: middle;
  }
  .res-menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: none;
    background-color: #fff;
    z-index: -1;
  }
  .menu-button {
    position: absolute;
    display: none;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    padding: 0;
    width: 20px;
    height: 35px;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
    background-color: transparent;
    z-index: 101;
  }
  .menu-button span {
    display: block;
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    height: 2px;
    background: #343434;
  }
  .menu-button span::before, .menu-button span::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background: #343434;
    transition: all 300ms ease;
  }
  .menu-button span::before {
    top: -5px;
  }
  .menu-button span::after {
    bottom: -5px;
  }
  .menu-button.active span {
    background-color: transparent;
  }
  .menu-button.active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .menu-button.active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .headlines-wrap.style1 .headlines .content .headlines-title {
    font-size: 30px;
  }
  .headlines-wrap.style2 .headlines .content .headlines-title {
    font-size: 30px;
  }
  .call-to-action .form-subscribe {
    margin-top: 32px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .step-services-wrap {
    margin-bottom: 15px;
  }
  .call-to-action .form-subscribe form .mc4wp-response p {
    font-size: 12px;
  }
  .portfolio-top-nav {
    position: static;
    margin-top: -40px;
    margin-bottom: 45px;
  }
  .close-popup {
    right: 15px;
    top: 29px;
  }
  .popup-wrapper.active .popup-box {
    padding: 70px 30px 30px;
  }
  .post-content-wrap {
    padding: 30px 30px 37px;
  }
  .comments-post {
    padding: 20px 0px 15px;
    margin-bottom: 0;
  }
  .post-content-wrap .post-banner {
    padding-top: 90px;
    padding-bottom: 35px;
    height: auto;
  }
  .post-content-wrap .post-banner .post-title {
    position: relative;
    bottom: auto;
  }
  .container-masonry {
    padding-top: 30px;
  }
  .logo {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  .textlogo {
    font-size: 23px;
  }
}

@media screen and (max-width: 768px) {
  .headlines-wrap.style2 {
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .single-post-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .team-wrap .img-wrap {
    height: 270px;
  }
  .team-wrap .img-wrapper {
    height: 270px;
  }
  .simple-banner-wrap .content {
    max-width: 400px;
  }
  .simple-banner-wrap .content .title {
    font-size: 28px;
  }
  .banner-slider-wrap .slider-content {
    max-width: 400px;
  }
  .banner-slider-wrap .slider-content .title {
    font-size: 28px;
  }
  .banner-slider-wrap .body-height {
    height: calc(100vh - 220px);
    min-height: 250px;
  }
  .creative-slider-wrap .creative-content {
    height: auto;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .creative-slider-wrap .img-slider-wrap.body-height {
    height: 260px;
  }
  .creative-slider-wrap .wrap-arrows {
    top: 260px;
  }
  .creative-slider-wrap .pagination {
    bottom: auto;
    top: 300px;
  }
  .headlines-wrap.style1 .headlines {
    min-height: 100px;
  }
  .headlines-wrap.style1 .headlines .content .headlines-title {
    font-size: 25px;
  }
  .headlines-wrap.style1 .headlines .content {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    bottom: 0;
    top: 0;
  }
  .headlines-wrap {
    padding: 20px 0px 20px;
  }
  .headlines-wrap.style2 .headlines .content .headlines-title {
    font-size: 24px;
    margin-bottom: 9px;
  }
  .headlines-wrap.style1 .headlines {
    margin-bottom: 0;
  }
  .services-wrap {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .headlines-wrap.style2 .headlines {
    margin-bottom: 10px;
  }
  .step-services-wrap .char {
    font-size: 85px;
  }
  .step-services-wrap .content-wrap {
    margin-top: 56px;
    min-height: 105px;
  }
  .call-to-action .content-wrap .title {
    font-size: 28px;
  }
  .call-to-action .content-wrap .subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .call-to-action {
    margin-top: 0;
  }
  .call-to-action.subscribe {
    margin-top: 20px;
  }
  .call-to-action.subscribe .content-wrap .title {
    font-size: 21px;
  }
  .mfp-arrow.mfp-arrow-left {
    left: 15px;
  }
  .mfp-arrow.mfp-arrow-right {
    right: 15px;
  }
  .step-services-wrap .img-wrap {
    height: 150px;
  }
  .counters-wrapper {
    margin-bottom: 20px;
  }
  .wpcf7[role="form"] .paddings-form {
    padding-right: 30px;
    padding-left: 30px;
  }
  .single-portfolio-item {
    padding: 30px;
  }
  .single-portfolio-item .schort_desc h3 {
    letter-spacing: 1px;
    font-size: 19px;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .single-portfolio-item .single-portfolio-section .thumbnails {
    margin-bottom: 20px;
  }
  .single-portfolio-item .schort_desc p {
    font-size: 13px;
  }
  .single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-left {
    left: 10px;
  }
  .single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-right {
    right: 10px;
  }
  blockquote {
    padding: 1px 0 1px 20px;
    margin: 25px auto;
  }
  blockquote p {
    font-size: 16px;
    line-height: 1.5;
  }
  .post-content-wrap .post-content {
    margin-bottom: 38px;
  }
  .post-content-wrap .post-banner .meta-info-post {
    width: 90%;
    text-align: center;
    position: relative;
    bottom: auto;
    margin-bottom: -70px;
  }
  .post-content-wrap .post-banner .meta-info-post .admin-post {
    margin-bottom: 5px;
  }
  .post-content-wrap .post-banner .post-title {
    font-size: 24px;
  }
  .news-item.classic .post-media-wrap {
    float: none;
    display: block;
    width: 100%;
  }
  .news-item.classic .bloc-content-wrapper {
    display: block;
    width: 100%;
    padding: 20px;
    float: none;
  }
  .news-item.classic .post-media-wrap.gallery {
    min-height: 0;
  }
  .news-item.classic .post-media-wrap .blog-video {
    min-height: 250px;
  }
  .container-masonry {
    padding-top: 20px;
  }
  .news-item.classic .post-media-wrap {
    position: static;
  }
  .news-item.classic .bloc-content-wrapper .post-item-link {
    font-size: 20px;
  }
  .news-item.masonry .bloc-content-wrapper .meta-data {
    display: block;
    left: 0;
  }
}

@media screen and (max-width: 600px) {
  .maps-wrapper .contact-info .info-box .title::before,
  .maps-wrapper .contact-info .info-box .title::after {
    left: 50%;
    transform: translateX(-50%);
  }
  body {
    margin: 15px;
  }
  .responsive-nav {
    top: 15px;
    position: fixed;
  }
  .banner-slider-wrap .swiper-outer-left {
    left: 10px;
    width: 50px;
    padding-right: 13px;
  }
  .banner-slider-wrap .swiper-outer-right {
    right: 10px;
    width: 50px;
    padding-left: 13px;
  }
  .a-btn-left:hover i {
    width: 25px;
  }
  .a-btn-left:hover i::after {
    width: 20px;
  }
  .a-btn:hover i {
    width: 25px;
  }
  .a-btn:hover i::before {
    width: 20px;
  }
  .banner-slider-wrap .slider-content {
    padding: 25px 10px 45px;
  }
  .border {
    border: 10px solid white;
  }
  .border::before {
    bottom: -10px;
  }
  .simple-banner-wrap .content {
    max-width: 250px;
    padding: 15px 15px 20px;
  }
  .banner-slider-wrap .body-height {
    height: calc(100vh - 210px);
  }
  .creative-slider-wrap .creative-content .title {
    font-size: 23px;
  }
  .creative-slider-wrap .wrap-arrows {
    padding: 12px 10px;
  }
  .body-height {
    height: calc(100vh - 120px);
  }
  .simple-banner-wrap {
    min-height: 290px;
  }
  .headlines-wrap.style1 .headlines .content .headlines-title {
    font-size: 22px;
  }
  .headlines-wrap.style2 .headlines .content .headlines-title {
    font-size: 22px;
    margin-bottom: 9px;
  }
  .button {
    padding: 15px 30px 15px 15px;
  }
  .button::after {
    right: 12px;
  }
  .button:hover::after {
    right: 8px;
  }
  .gallery-wrap.style-2 .item-gallery.small,
  .gallery-wrap .item-gallery.big,
  .gallery-wrap .item-gallery.long,
  .gallery-wrap .item-gallery.longer,
  .gallery-wrap.style-3 .item-gallery.longer,
  .gallery-wrap.style-3 .item-gallery.small,
  .gallery-wrap.style-3 .item-gallery.long,
  .gallery-wrap .item-gallery.small,
  .gallery-wrap .item-gallery {
    height: 250px;
    width: 100%;
  }
  .wpcf7[role="form"] .title {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .maps-wrapper .contact-info {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    box-shadow: none;
    text-align: center;
  }
  .comments-post .photo-comment {
    display: block;
    float: none;
    margin: 10px auto;
  }
  .comments-post .com-name {
    display: block;
  }
  .comments-post .children {
    padding-left: 20px;
  }
  .comments-post .photo-comment img {
    width: 80px;
    height: 80px;
    margin-bottom: 7px;
  }
  .comments-post .comments_title {
    margin-bottom: 10px;
    font-size: 21px;
  }
  .post-content-wrap .post-category {
    width: 100%;
    margin-bottom: 20px;
  }
  .post-content-wrap .share-social {
    float: none;
  }
  .news-item.masonry {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .banner-slider-wrap .slider-content {
    max-width: 250px;
  }
  .banner-slider-wrap .slider-content .title {
    font-size: 26px;
  }
  .creative-slider-wrap .pagination {
    top: 350px;
  }
  .creative-slider-wrap .pagination span {
    font-size: 100px;
  }
}

.anime {
  opacity: 0;
}

.load-more.button {
  border-radius: 0px;
  margin-bottom: 50px;
  margin-top: 20px;
  padding: 15px 40px;
}

.load-more.button span {
  transition: all 200ms ease;
}

.load-more.button::after {
  content: "\f107";
  right: auto;
  left: 50%;
  top: 0;
  transform: translate(-50%, -150%);
  transition: all 200ms ease;
}

.load-more.button:hover span {
  color: transparent;
}

.load-more.button:hover::after {
  right: auto;
  left: 50%;
  top: 50%;
  transition-delay: 100ms;
  transform: translate(-50%, -50%);
}

.call-to-action .form-subscribe form .mc4wp-response p {
  font-size: 11px;
}

.loading-popup .loading {
  position: absolute;
  height: 100vh;
  background-color: #fff;
}

.move-wrapper {
  background-color: #fff;
  position: relative;
}

.block-wrap-padding {
  padding: 1px 20px;
}

.bg-color1 {
  background-color: #ffffff;
}

.thumbnails .swiper-slide {
  width: 224px;
  height: 224px;
}

.padding-lg-lr0 {
  padding-left: 0;
  padding-right: 0;
}
