/*-------------------------------------------------------------------------------------------------------*/
/*This is main CSS file that contains custom style rules used in this template*/
/*-------------------------------------------------------------------------------------------------------*/
/* Template Name: Melbourne.*/
/* Version: 1.0 Initial Release*/
/* Build Date: 10-02-2017*/
/* Author: */
/* Website: */
/* Copyright: (C) 2016 */
/*------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/

/*----------------------STYLES INIT----------------------
init
fonts
body
swiper
LEFT NAVIGATION 
SCROLL
IMAGE BANNER
SIMPLE BANNER SLIDER
CREATIVE BANNER SLIDER
HEADLINES
SERVICES
STEP SERVICES
TEAM
SKILLS
GALLERY
PORTFOLIO
CLIENTS
COUNTERS
CONTACTS - FORM
PORTFOLIO DETAIL
SINGLE POST
BLOG LIST
SIDEBAR

-------------------------------------------------------*/

@import '_unit-test.scss';
$step: 5;
$count: 40;
@for $i from 0 through $count {
    .margin-lg-#{$step*$i}t {
        margin-top: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .margin-lg-#{$step*$i}b {
        margin-bottom: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .padding-lg-#{$step*$i}t {
        padding-top: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .padding-lg-#{$step*$i}b {
        padding-bottom: 0px+$step*$i;
    }
}

@media screen and (max-width: 1199px) {
    @for $i from 0 through $count {
        .margin-md-#{$step*$i}t {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-md-#{$step*$i}b {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-md-#{$step*$i}t {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-md-#{$step*$i}b {
            padding-bottom: 0px+$step*$i;
        }
    }
}

@media screen and (max-width: 991px) {
    @for $i from 0 through $count {
        .margin-sm-#{$step*$i}t {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-sm-#{$step*$i}b {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-sm-#{$step*$i}t {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-sm-#{$step*$i}b {
            padding-bottom: 0px+$step*$i;
        }
    }
}

@media screen and (max-width: 767px) {
    @for $i from 0 through $count {
        .margin-xs-#{$step*$i}t {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-xs-#{$step*$i}b {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-xs-#{$step*$i}t {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-xs-#{$step*$i}b {
            padding-bottom: 0px+$step*$i;
        }
    }
}


/*--------------------------------------------------------*/


/* Fonts: */


/*--------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Raleway:100,200,300,400,500,600,700,800,900');
* {
    box-sizing: border-box;
    outline: none;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 23px;
}

h4 {
    font-size: 19px;
    text-transform: uppercase;
}

h5 {
    font-size: 19px;
}

h6 {
    font-size: 14px;
    font-style: normal;
    letter-spacing: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-family: "Libre Baskerville", sans-serif;
    color: #343434;
}


/*--------------------------------------------------------*/


/* Body */


/*--------------------------------------------------------*/

.wrapper {
    padding-left: 1em;
    padding-right: 1em;
    margin: auto;
    display: block;
    width: 195px;
}

.cssload-loader {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    margin: 3em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #343434;
}

.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
    animation: 1.15s infinite ease-in-out;
    -o-animation: 1.15s infinite ease-in-out;
    -ms-animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
}

.cssload-loader:before,
.cssload-loader:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.cssload-loader {
    animation-name: cssload-loader;
    -o-animation-name: cssload-loader;
    -ms-animation-name: cssload-loader;
    -webkit-animation-name: cssload-loader;
    -moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
    from {
        transform: scale(0);
        opacity: 1;
    }
    to {
        transform: scale(1);
        opacity: 0;
    }
}

@-o-keyframes cssload-loader {
    from {
        -o-transform: scale(0);
        opacity: 1;
    }
    to {
        -o-transform: scale(1);
        opacity: 0;
    }
}

@-ms-keyframes cssload-loader {
    from {
        -ms-transform: scale(0);
        opacity: 1;
    }
    to {
        -ms-transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loader {
    from {
        -webkit-transform: scale(0);
        opacity: 1;
    }
    to {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@-moz-keyframes cssload-loader {
    from {
        -moz-transform: scale(0);
        opacity: 1;
    }
    to {
        -moz-transform: scale(1);
        opacity: 0;
    }
}

p {
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    color: #a7a2a2;
    line-height: 1.5;
    letter-spacing: 1.5px;
    margin-top: 10px;
    margin-bottom: 15px;
}

body {
    font-size: 14px;
    line-height: normal;
    background-color: #F0F0F0;
    color: #343434;
    margin: 50px 50px 10px;
    overflow-x: hidden;
}

html {
    font-size: 14px;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

.overflow {
    overflow: hidden;
}

.body-height {
    min-height: 200px;
}

.move-wrapper > div.clearfix {
    height: 0;
}


/*--------------------------------------------------------*/


/* SWIPER */


/*--------------------------------------------------------*/

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    direction: ltr;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    height: inherit;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    -webkit-transition-property: -webkit-transform, left, top;
    -webkit-transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    -webkit-transition-timing-function: ease;
    -moz-transition-property: -moz-transform, left, top;
    -moz-transition-duration: 0s;
    -moz-transform: translate3d(0px, 0, 0);
    -moz-transition-timing-function: ease;
    -o-transition-property: -o-transform, left, top;
    -o-transition-duration: 0s;
    -o-transform: translate3d(0px, 0, 0);
    -o-transition-timing-function: ease;
    -o-transform: translate(0px, 0px);
    -ms-transition-property: -ms-transform, left, top;
    -ms-transition-duration: 0s;
    -ms-transform: translate3d(0px, 0, 0);
    -ms-transition-timing-function: ease;
    transition-property: transform, left, top;
    transition-duration: 0s;
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    cursor: ew-resize;
    margin: 0 auto;
    height: inherit;
    float: left;
}

.swiper-slide {
    float: left;
    height: inherit;
    position: relative;
}

.logo {
    position: relative;
    padding: 55px 15px 53px;
    left: 0;
    display: block;
    max-width: 280px;
    margin-right: auto;
    margin-left: auto;
    img {
        max-height: 70px;
    }
}

.loading {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #e4e6eb;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.body-height {
    height: calc(100vh - 100px);
}


/*--------------------------------------------------------*/


/*  LEFT NAVIGATION  */


/*--------------------------------------------------------*/

.left-navigation {
    display: block;
    width: 280px;
    float: left;
    z-index: 500;
    min-height: calc(100vmin - 160px);
    text-align: center;
    background-color: white;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 60px);
        height: 200px;
        z-index: -1;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
    }
}

.menu-wrapper {
    max-height: 100%;
    height: inherit;
    padding: 0px 15px 15px;
    background-color: #FFFFFF;
}

.navigation ul {
    padding-left: 0;
}

.navigation {
    font-family: 'Raleway', serif;
    position: relative;
    width: 100%;
    text-align: center;
    overflow: auto;
    height: 100%;
}

.menu > .li-nav {
    position: relative;
    display: block;
    &:hover > a {
        color: #fff;
        background-color: #AA855D;
        &::before {
            box-shadow: 0px 7px 30px 0px rgb(170, 133, 93);
            opacity: .5;
        }
    }
    &.current-menu-ancestor > a,
    &.current_page_item > a,
    &.current-menu-parent > a {
        color: #AA855D;
    }
    & > .sub-menu > .current-menu-parent > a {
        color: #343434;
        &::before {
            width: 100%;
        }
    }
    & > .sub-menu > .current_page_item > a {
        opacity: 1;
        color: #AA855D;
        &::before {
            background-color: #AA855D;
        }
        &::after {
            background-color: #AA855D;
        }
        &:hover::after {
            background-color: transparent;
        }
    }
    & > .sub-menu .sub-menu a {
        &::after {
            display: none;
        }
    }
    & > .sub-menu .sub-menu > .current_page_item > a {
        color: #AA855D;
        opacity: 1;
    }
    &.current-menu-parent > a:hover {
        color: #fff;
    }
}

.menu-item >a {
    text-transform: uppercase;
}

.nav-link {
    font-size: 13px;
    font-family: "Raleway", sans-serif;
    color: #343434;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 15px 56px;
    display: inline-block;
    line-height: 1.2;
    position: relative;
    z-index: 10;
    text-decoration: none;
    margin-bottom: 14px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    &:focus,
    &:visited,
    &:hover {
        color: #343434;
    }
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        z-index: -1;
        width: 90%;
        bottom: 0;
        height: 30%;
        transform: translateX(-50%);
        -webkit-transition: box-shadow 400ms ease;
        transition: box-shadow 400ms ease;
    }
}

.fixed-sidebar-footer {
    position: absolute;
    padding-top: 15px;
    background-color: #fff;
    width: calc(100% - 30px);
    left: 15px;
    padding-bottom: 15px;
    bottom: 0px;
    z-index: 9999;
}

.menu-item .sub-menu {
    display: none;
    position: relative;
    top: -4px;
    margin: 0;
    padding: 0px;
    li {
        font-size: 11px;
        padding: 0px;
        line-height: 1.8em;
        margin: 0;
        &:hover > a {
            color: #343434;
            opacity: 1;
        }
        .sub-menu {
            margin-top: 8px;
        }
        .sub-menu li > a {
            opacity: .7;
            &::before {
                width: 0%;
            }
            &:hover {
                opacity: 1;
            }
            &:hover::before {
                width: 0%;
            }
        }
    }
    .nav-link {
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        position: relative;
        font-weight: 400;
        letter-spacing: 1.7px;
        padding: 0;
        -webkit-transition: all 400ms ease;
        transition: all 400ms ease;
        text-decoration: none;
        background: none;
        text-transform: none;
        margin-bottom: 8px;
        &::before {
            content: '';
            height: 1px;
            width: 0;
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            transform: translateY(-50%);
            bottom: auto;
            background: transparent;
            transition: width 0s ease ;
        }
        &::after {
            content: '';
            display: block;
            height: 1px;
            width: 0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            background: #343434;
            transition: width .3s ease-out;
        }
        &:hover {
            &::before {
                width: 100%;
                background: #343434;
                transition: width 0.3s ease ;
            }
            &::after {
                width: 100%;
                background: transparent;
                transition: all 0s ease-out;
            }
        }
    }
}

.nav-open-submenu {
    position: relative;
    margin-bottom: -3px;
    display: none;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.current-portfolio-ancestor > a {
    color: #fff;
    background-color: #AA855D;
    &::before {
        box-shadow: 0px 7px 30px 0px rgb(170, 133, 93);
        opacity: .5;
    }
}

.social-ico {
    margin-bottom: 50px;
}

.social {
    font-size: 12px;
    display: inline-block;
    height: 24px;
    width: 24px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    color: #343434;
    position: relative;
    margin: 0 3.6px;
    border: 1px solid transparent;
    i {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transition: color 400ms ease;
        transition: color 400ms ease;
    }
}

.social:hover {
    border-color: #AA855D;
    i {
        color: #AA855D;
    }
}

.copyright {
    padding: 20px;
    background-color: #F0F0F0;
}

.copyright-text {
    font-family: 'Raleway', serif;
    font-size: 12px;
    font-weight: 400;
    color: #343434;
    opacity: .7;
    line-height: 1.5;
    letter-spacing: .8px;
}

.copyright-text a {
    color: #fff;
}

.left-navigation:hover .jspTrack {
    background-color: #F0F0F0;
}

.jspTrack {
    background-color: transparent;
}

.jspVerticalBar {
    width: 5px;
    background-color: transparent;
}


/*--------------------------------------------------------*/

//SCROLL

/*--------------------------------------------------------*/

.left-navigation:hover .jspDrag {
    background-color: #343434;
}

.jspDrag {
    background-color: transparent;
    opacity: .4;
    transition: all 300ms ease;
}

.s-back-switch {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.no-padding {
    padding: 0;
}


/*--------------------------------------------------------*/


/*  IMAGE BANNER  */


/*--------------------------------------------------------*/

.simple-banner-wrap {
    position: relative;
    .content.active {
        opacity: 1;
        .title,
        .a-btn {
            opacity: 1;
        }
        .subtitle {
            opacity: 1;
        }
    }
    .content {
        position: absolute;
        bottom: 70px;
        background-color: #fff;
        padding: 25px 40px 30px;
        text-align: center;
        max-width: 630px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 400ms ease-out 400ms;
        .title {
            font-size: 34px;
            font-family: "Libre Baskerville", sans-serif;
            color: #a7a2a2;
            font-weight: bold;
            line-height: 1.2;
            opacity: 0;
            display: block;
            margin-bottom: 5px;
            transition: opacity 400ms ease-out 600ms;
        }
        .subtitle {
            margin-top: 0;
            font-size: 13px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
            line-height: 1.5;
            opacity: 0;
            transition: opacity 400ms ease-out 800ms;
        }
    }
    .a-btn {
        position: absolute;
        bottom: -13px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 400ms ease-out 1000ms;
    }
}

.simple-banner-wrap {
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
    }
}

.a-btn {
    background-color: #9E7B58;
    color: white;
    text-align: left;
    padding-left: 20px;
    display: inline-block;
    padding-right: 20px;
    width: 65px;
    position: relative;
    height: 27px;
    cursor: pointer;
    &:hover i {
        width: 27px;
        transform: translateX(4px);
    }
    &:hover i::before {
        width: 24px;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 13px 24px -10px rgb(158, 123, 88);
        opacity: .5;
    }
    i {
        display: inline-block;
        width: 25px;
        height: 27px;
        position: relative;
        transition: all 350ms ease;
        &::after {
            content: "";
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid white;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 1px;
        }
        &::before {
            content: '';
            position: absolute;
            height: 1px;
            width: 20px;
            left: 0;
            background-color: #fff;
            top: 50%;
            transform: translateY(-50%);
            transition: width 350ms ease;
        }
    }
}

.a-btn-left {
    background-color: #9E7B58;
    color: white;
    text-align: right;
    display: inline-block;
    padding-right: 20px;
    width: 65px;
    position: relative;
    height: 27px;
    cursor: pointer;
    &:hover i {
        width: 27px;
        transform: translateX(-4px);
    }
    &:hover i::after {
        width: 24px;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 13px 24px -10px rgb(158, 123, 88);
        opacity: .5;
    }
    i {
        display: inline-block;
        width: 25px;
        height: 27px;
        position: relative;
        transition: all 350ms ease;
        &::before {
            content: "";
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid white;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 1px;
        }
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 20px;
            right: 0;
            background-color: #fff;
            top: 50%;
            transform: translateY(-50%);
            transition: width 350ms ease;
        }
    }
}

.border {
    border: 30px solid white;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
    }
}

.border-1 {
    border-top: 30px solid white;
    border-bottom: 30px solid white;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;
    }
}

.border-2 {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        height: calc(100% - 40px);
        display: block;
        z-index: 10;
        width: 30px;
        background-color: #fff;
    }
}

.border-3 {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: calc(100% - 40px);
        display: block;
        z-index: 10;
        width: 30px;
        background-color: #fff;
    }
}

.box-shadow-1 {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
    }
}

.bypostauthor {
    font-size: inherit;
}

.box-shadow {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
    }
}

.box-shadow-row {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 70px);
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
    }
}

.textlogo {
    font-size: 28px;
    font-family: "Libre Baskerville", sans-serif;
    color: #343434;
    font-weight: bold;
    line-height: 1.3;
}


/*--------------------------------------------------------*/


/* SIMPLE BANNER SLIDER */


/*--------------------------------------------------------*/

.banner-slider-wrap {
    .swiper-slide.swiper-slide-active {
        .title {
            opacity: 1;
        }
        .subtitle {
            opacity: 1;
        }
        .slider-content {
            opacity: 1;
        }
    }
    .body-height {
        height: calc(100vh - 160px);
    }
    position: relative;
    & > .swiper-container {
        // padding-bottom: 40px;
    }
    .pagination {
        display: none;
    }
    .swiper-outer-left,
    .swiper-outer-right {
        position: absolute;
        top: calc(50% - 40px);
        transform: translateY(-50%);
        z-index: 50;
    }
    .swiper-outer-left {
        left: 0px;
    }
    .swiper-outer-right {
        right: 0px;
    }
    .wrap {
        position: relative;
    }
    .slider-content {
        position: absolute;
        bottom: -45px;
        background-color: #fff;
        padding: 25px 40px 45px;
        text-align: center;
        max-width: 630px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 200ms ease-out;
        .title {
            font-size: 34px;
            font-family: "Libre Baskerville", sans-serif;
            color: #343434;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 5px;
            opacity: 0;
            transition: opacity 400ms ease-out 600ms;
        }
        .subtitle {
            font-size: 13px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
            line-height: 1.5;
            letter-spacing: .8px;
            opacity: 0;
            transition: opacity 400ms ease-out 800ms;
        }
    }
    .button {
        margin-top: 15px;
    }
}

.single-post-wrap {
    padding-left: 55px;
    padding-right: 55px;
}


/*--------------------------------------------------------*/


/*  CREATIVE BANNER SLIDER */


/*--------------------------------------------------------*/

.creative-slider-wrap {
    position: relative;
    .swiper-slide {
        background-color: #fff;
        &.swiper-slide-active {
            .title,
            .button {
                opacity: 1;
            }
            .subtitle {
                opacity: .8;
            }
        }
    }
    .pagination {
        display: block;
        position: absolute;
        bottom: 82px;
        left: 50%;
        transform: translateX(-50%);
        span {
            display: none;
            font-size: 160px;
            font-family: "Libre Baskerville", sans-serif;
            color: #343434;
            opacity: .1;
            font-weight: bold;
        }
        .swiper-active-switch {
            display: block;
        }
    }
    .wrap {
        background-color: #fff;
    }
    .img-slider-wrap {
        width: 100%;
        &.body-height {
            height: calc(100vh - 450px);
            min-height: 100px;
        }
    }
    .slider-content {
        padding: 0 30px;
    }
    .wrap-arrows {
        position: absolute;
        padding: 12px 25px;
        top: calc(100vh - 440px);
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0;
            height: 100%;
            display: block;
            z-index: -1;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
        }
        & > div {
            display: inline-block;
            font-size: 13px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            opacity: .7;
            font-weight: 600;
            cursor: pointer;
            letter-spacing: 2px;
            transition: opacity 300ms ease;
            &:hover {
                opacity: 1;
            }
        }
        & > div:first-child {
            margin-right: 1px;
            position: relative;
        }
        & > div:nth-child(2):hover {
            opacity: .7;
        }
        & > div:last-child {
            margin-left: 1px;
        }
    }
    .creative-content {
        height: 290px;
        text-align: center;
        padding-top: 55px;
        padding-right: 30px;
        padding-left: 30px;
        .title {
            font-size: 34px;
            font-family: "Libre Baskerville", sans-serif;
            color: #343434;
            font-weight: bold;
            line-height: 1.3;
            opacity: 0;
            transition: opacity 400ms ease-out 400ms;
        }
        .subtitle {
            font-size: 13px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            line-height: 23px;
            letter-spacing: .85px;
            max-width: 610px;
            margin: auto;
            opacity: 0;
            transition: opacity 400ms ease-out 600ms;
        }
        .button {
            margin-top: 43px;
            opacity: 0;
            transition: opacity 400ms ease-out 800ms;
        }
    }
}

.button {
    font-size: 11px;
    font-family: "Raleway", sans-serif;
    color: #fff;
    padding: 15px 50px 15px 37px;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #9E7B58;
    position: relative;
    text-transform: uppercase;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        display: block;
        z-index: 0;
        box-shadow: 0px 7px 30px 0px rgb(158, 123, 88);
        opacity: .5;
    }
    &::after {
        content: "\f105";
        font-family: "FontAwesome";
        color: inherit;
        position: absolute;
        right: 35px;
        top: 48%;
        transform: translateY(-50%);
        transition: right 350ms ease;
    }
    &:focus,
    &:active {
        color: #fff;
    }
    &:hover {
        color: white;
        &::after {
            right: 30px;
        }
    }
}


/*--------------------------------------------------------*/


/* HEADLINES */


/*--------------------------------------------------------*/

.headlines-wrap {
    background-color: #fff;
    padding: 70px 0px 20px;
    &.style1 .headlines {
        margin-bottom: 28px;
        min-height: 300px;
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .2);
        }
        .image-paralax {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .content {
            position: absolute;
            bottom: 34px;
            width: 100%;
            text-align: center;
            z-index: 99;
            .headlines-title {
                padding: 0 15px;
                font-size: 34px;
                font-family: "Libre Baskerville", sans-serif;
                color: #fff;
                line-height: 1.2;
            }
        }
    }
    &.style2 {
        padding-top: 43px;
    }
    &.style2 .headlines {
        margin-bottom: 44px;
        .content {
            text-align: center;
            .headlines-title {
                font-size: 34px;
                font-family: "Libre Baskerville", sans-serif;
                color: #343434;
                font-weight: bold;
                line-height: 1.3;
                margin-bottom: 17px;
            }
            .headlines-subtitle {
                font-size: 12.7px;
                font-family: "Raleway", sans-serif;
                color: #343434;
                font-weight: 600;
                letter-spacing: .27px;
                opacity: .7;
                line-height: 1.5;
                text-align: center;
            }
        }
    }
}


/*--------------------------------------------------------*/


/* SERVICES */


/*--------------------------------------------------------*/

.services-wrap {
    text-align: center;
    margin: 25px auto 20px;
    .icon i {
        font-size: 36px;
        color: #AA855D;
    }
    .title {
        font-size: 14px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        margin-top: 23px;
        letter-spacing: 1.7px;
        font-weight: 600;
    }
    .text {
        font-size: 14px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        opacity: .7;
        line-height: 1.5;
        letter-spacing: 1.1px;
        margin-top: 14px;
    }
    .a-btn {
        margin-top: 20px;
    }
}


/*--------------------------------------------------------*/


/*  STEP SERVICES */


/*--------------------------------------------------------*/

.step-services-wrap {
    position: relative;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    .char {
        position: absolute;
        top: 230px;
        width: 100%;
        text-align: center;
        font-size: 100px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        opacity: .1;
        font-weight: bold;
    }
    .title {
        font-size: 20px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        line-height: 1.2;
        margin-bottom: 5px;
    }
    .text {
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        opacity: .7;
        letter-spacing: 1.2px;
        line-height: 1.5;
    }
    .content-wrap {
        margin-top: 65px;
        text-align: center;
        &.char-style {
            min-height: 120px;
        }
    }
    .img-wrap {
        height: 200px;
        width: 100%;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 21px;
            bottom: -20px;
            width: 72%;
            background-color: #AA855D;
            left: 50%;
            transform: translateX(-50%);
            transition: all 350ms ease;
        }
        &::after {
            content: '';
            position: absolute;
            height: 30px;
            bottom: -10px;
            width: 60%;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0px 10px 50px 0px #AA855D;
            opacity: .5;
            transition: all 350ms ease;
        }
        &:hover {
            &::before {
                height: 26px;
                bottom: -25px;
            }
            &::after {
                height: 35px;
                bottom: -15px;
            }
        }
    }
}


/*  7. call to action */

.call-to-action {
    margin-top: 72px;
    position: relative;
    padding-top: 41px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    background-position: top center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .1);
    }
    .content-wrap {
        color: white;
        text-align: center;
        position: relative;
        .title {
            font-size: 34px;
            font-family: "Libre Baskerville", sans-serif;
            color: #fff;
            line-height: 1.5;
            padding-right: 15px;
            padding-left: 15px;
        }
        .subtitle {
            font-size: 14px;
            padding-right: 15px;
            padding-left: 15px;
            font-family: "Libre Baskerville", sans-serif;
            color: #fff;
            line-height: 1.5;
            font-weight: bold;
            margin-top: 60px;
            letter-spacing: 2px;
            margin-bottom: 50px;
        }
    }
    &.order .content-wrap .subtitle {
        margin-top: 38px;
        margin-bottom: 46px;
    }
    &.order .button {
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
    }
    .form-subscribe {
        max-width: 430px;
        margin: auto;
        margin-top: 67px;
        margin-bottom: -39px;
        form {
            position: relative;
            .mc4wp-response {
                position: absolute;
                top: -45px;
                width: 100%;
                text-align: center;
                p {
                    color: white;
                }
            }
        }
        p {
            opacity: 1;
        }
        input:not([type="submit"]) {
            background-color: transparent;
            border: none;
            text-align: center;
            color: white;
            border-bottom: 1px solid white;
            font-size: 14px;
            line-height: normal;
            font-family: "Libre Baskerville", sans-serif;
            &::-webkit-input-placeholder {
                opacity: .7;
            }
            &::-moz-placeholder {
                opacity: .7;
            }
            &:-moz-placeholder {
                opacity: .7;
            }
            &:-ms-input-placeholder {
                opacity: .7;
            }
        }
        .submit-wrap {
            display: inline-block;
            background: #9E7B58;
            position: relative;
            margin-top: 42px;
            &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                width: 100%;
                left: 0;
                height: 100%;
                display: block;
                z-index: -1;
                box-shadow: 0px 13px 24px -10px rgb(158, 123, 88);
                opacity: .5;
            }
            &:hover i {
                width: 27px;
                transform: translateX(calc(-50% + 4px));
            }
            &:hover i::before {
                width: 24px;
            }
            i {
                display: inline-block;
                width: 25px;
                height: 27px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                transition: all 350ms ease;
                &::after {
                    content: "";
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    border-left: 4px solid white;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: 1px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 20px;
                    left: 0;
                    background-color: #fff;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: width 350ms ease;
                }
            }
        }
        input[type="submit"] {
            background-color: transparent;
            color: white;
            text-align: left;
            padding-left: 20px;
            display: inline-block;
            border: none;
            outline: none;
            padding-right: 20px;
            width: 65px;
            position: relative;
            height: 27px;
            z-index: 10;
            cursor: pointer;
        }
    }
    &.subscribe {
        margin-top: 68px;
        padding-top: 22px;
        padding-bottom: 10px;
        margin-bottom: 64px;
    }
}

* {
    outline: none;
}

div.wpcf7-validation-errors {
    border: none;
    text-align: center;
    color: #9E7B58;
    font-family: "Raleway", sans-serif;
    letter-spacing: .5px;
    font-size: 15px;
}

span.wpcf7-not-valid-tip {
    margin-top: -13px;
    font-family: "Raleway", sans-serif;
    font-size: 13px;
}


/*--------------------------------------------------------*/


/* TEAM */


/*--------------------------------------------------------*/

.team-wrap {
    position: relative;
    max-width: 450px;
    margin: auto;
    .img-wrapper {
        width: 100%;
        height: 350px;
        position: relative;
        overflow: hidden;
    }
    .img-wrap {
        width: 100%;
        height: 350px;
        overflow: hidden;
        transition: all 400ms ease;
    }
    .content-wrapper {
        display: block;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: calc(100% - 90px);
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: .5;
            box-shadow: 0px 10px 50px 0px #AA855D;
        }
    }
    .content-wrap {
        width: calc(100% - 42px);
        margin: auto auto 30px;
        background-color: #AA855D;
        text-align: center;
        position: relative;
        min-height: 40px;
        padding: 3px 15px;
        .content {
            position: absolute;
            overflow: hidden;
            padding: 0 15px;
            top: 0px;
            margin-top: 5px;
            left: 0;
            width: 100%;
            height: calc(100% - 10px);
        }
        .title {
            position: relative;
            font-size: 14px;
            font-family: "Libre Baskerville", sans-serif;
            color: #fff;
            line-height: 1.3;
            letter-spacing: 2px;
            padding-top: 6px;
            padding-bottom: 5px;
            margin-bottom: 0;
            margin-top: 0;
            display: block;
            transition: all 400ms ease;
        }
        .subtitle {
            position: absolute;
            top: 110%;
            width: 100%;
            left: 0;
            text-align: center;
            font-size: 12px;
            font-family: "Raleway", sans-serif;
            color: #fff;
            line-height: 1.3;
            letter-spacing: 1.2px;
            transition: all 400ms ease;
        }
    }
    .team-social-wrap {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        left: 0;
        width: 100%;
        text-align: center;
        transition: all 400ms ease;
    }
    .team-social {
        opacity: 0;
        position: relative;
        padding: 9px;
        text-align: center;
        background-color: #fff;
        display: inline-block;
        transition: opacity 400ms ease-out;
        a {
            font-size: 12px;
            padding-right: 8.5px;
            padding-left: 8.5px;
            color: #343434;
            line-height: 1.5;
            transition: all 300ms ease;
            &:hover {
                color: #AA855D;
            }
        }
    }
    &.hover .team-social {
        opacity: 1;
    }
    &.hover {
        .title {
            margin-top: -40%;
        }
        .subtitle {
            top: 50%;
            transform: translateY(-50%);
        }
        .team-social-wrap {
            bottom: 30px;
            transform: translate(0);
        }
        .img-wrap {
            transform: scale(1.1);
        }
    }
}


/*--------------------------------------------------------*/


/* SKILLS */


/*--------------------------------------------------------*/

.skill-wrapper {
    .main-title {
        font-size: 14px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        letter-spacing: 2px;
    }
    .skills {
        margin-top: 14px;
        margin-bottom: 25px;
    }
    .skill {
        .label-skill {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            opacity: .7;
            letter-spacing: 1.4px;
            display: inline-block;
        }
        .value {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            opacity: .7;
            letter-spacing: 1.4px;
            display: inline-block;
            float: right;
        }
        .line {
            background-color: #EEEEEE;
            position: relative;
            width: 100%;
            height: 20px;
            margin-bottom: 25px;
            margin-top: 12px;
        }
        .active-line {
            background-color: #AA855D;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0;
            transition: all 1500ms ease;
            &::before {
                content: '';
                position: absolute;
                left: 10px;
                bottom: 0;
                width: calc(100% - 20px);
                height: 10px;
                display: block;
                z-index: 1;
                box-shadow: 0px 5px 24px 0px #AA855D;
                opacity: .5;
                transition: all 1500ms ease;
            }
        }
    }
}

.section-wrapper {
    height: 100%;
    margin-left: 330px;
}


/*--------------------------------------------------------*/

//GALLERY

/*--------------------------------------------------------*/

.gallery-wrap {
    margin: 30px auto 20px;
    .item-gallery {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        &.big {
            width: 50%;
        }
        &.small {
            width: 25%;
        }
        &.long {
            width: 25%;
        }
        &.longer {
            width: 50%;
        }
    }
    &.style-3 .item-gallery {
        &.big {
            width: 66.66%;
        }
        &.small {
            width: 33.33%;
        }
        &.long {
            width: 33.33%;
        }
        &.longer {
            width: 66.66%;
        }
    }
    &.style-2 .item-gallery {
        &.big {
            width: 100%;
        }
        &.small {
            width: 50%;
        }
        &.long {
            width: 50%;
        }
        &.longer {
            width: 100%;
        }
    }
    .item-gallery .img-wrap {
        display: block;
        height: inherit;
        width: calc(100% - 10px);
        margin-right: 5px;
        margin-left: 5px;
        position: relative;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        transition: filter 500ms ease;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            opacity: .9;
            transition: background-color 700ms ease;
        }
    }
    span {
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        opacity: 0;
        text-align: center;
        padding-right: 15px;
        padding-left: 15px;
        font-size: 24px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        line-height: 1.2;
        transition: opacity 800ms ease;
    }
    .item-gallery:hover .img-wrap {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        &::before {
            background-color: #fff;
        }
    }
    .item-gallery:hover {
        span {
            opacity: 1;
        }
    }
}

.mfp-container {
    cursor: pointer;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-counter {
    display: none;
}

.mfp-arrow {
    background-color: #9E7B58;
    width: 65px;
    height: 27px;
    margin-top: 0;
    transform: translateY(-50%);
    transition: all 300ms ease;
    &.mfp-arrow-left {
        left: 20px;
    }
    &.mfp-arrow-right {
        right: 20px;
    }
}

.mfp-arrow:focus {
    opacity: .65;
}

.mfp-arrow:active {
    margin-top: 0;
    opacity: .65;
}

.mfp-arrow:hover {
    opacity: 1;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border: none;
    height: 1px;
    margin-top: 13px;
    margin-left: 21px;
    width: 20px;
    background-color: #fff;
    transition: margin 300ms ease;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border: none;
    top: 0;
    margin-top: 10px;
    margin-left: 41px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid white;
    transition: margin 300ms ease;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    border: none;
    height: 1px;
    margin-top: 13px;
    margin-left: 24px;
    width: 20px;
    background-color: #fff;
    transition: margin 300ms ease;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border: none;
    top: 0;
    margin-top: 10px;
    margin-left: 20px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid white;
    transition: margin 300ms ease;
}

.mfp-arrow-left:hover {
    &::before,
    .mfp-b {
        margin-left: 20px;
    }
    &::after,
    .mfp-a {
        margin-left: 16px;
    }
}

.mfp-arrow-right:hover {
    &::before,
    .mfp-b {
        margin-left: 25px;
    }
    &::after,
    .mfp-a {
        margin-left: 45px;
    }
}

.mfp-zoom-in {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }
    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }
    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}

@keyframes hinge {
    0% {
        transform: rotate(0);
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    20%,
    60% {
        transform: rotate(80deg);
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    40% {
        transform: rotate(60deg);
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    80% {
        transform: rotate(60deg) translateY(0);
        opacity: 1;
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    100% {
        transform: translateY(700px);
        opacity: 0;
    }
}

.hinge {
    animation-duration: 1s;
    animation-name: hinge;
}

.mfp-with-fade {
    .mfp-content,
    &.mfp-bg {
        opacity: 0;
        transition: opacity .5s ease-out;
    }
    &.mfp-ready {
        .mfp-content {
            opacity: 1;
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }
    &.mfp-removing {
        &.mfp-bg {
            opacity: 0;
        }
    }
}


/*--------------------------------------------------------*/

//PORTFOLIO
///*--------------------------------------------------------*/
.portfolio .item-gallery {
    .title {
        position: static;
        transform: none;
    }
    .category {
        position: static;
        transform: none;
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #9E7B58;
        line-height: 1.2;
        margin-top: 30px;
        letter-spacing: 1.2px;
    }
}

.portfolio-popup-hidden .filter {
    margin-top: 18px;
    margin-bottom: -10px;
    ul li {
        display: inline-block;
        list-style: none;
        padding: 12px 19px;
        font-size: 13px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        line-height: 1.2;
        letter-spacing: 1.5px;
        cursor: pointer;
        transition: all 350ms ease;
        &:hover {
            color: #9E7B58;
        }
    }
    ul {
        margin: auto;
        text-align: center;
    }
}

.close-popup {
    position: absolute;
    right: -15px;
    top: 19px;
    width: 27px;
    height: 27px;
    background: #9E7B58;
    cursor: pointer;
    i {
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0;
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 13px 24px -10px #9e7b58;
        opacity: .5;
    }
}

.close-popup:before,
.close-popup:after {
    display: block;
    content: '';
    height: 14px;
    width: 1px;
    background: #fff;
    top: 10px;
    left: 13px;
    position: relative;
}

.close-popup:before {
    transform: rotate(45deg);
    top: 7px;
    transition: all 300ms ease;
}

.close-popup:after {
    transform: rotate(-45deg);
    top: -7px;
    transition: all 300ms ease;
}

.close-popup:hover {
    &::before {
        transform: rotate(135deg);
    }
    &:after {
        transform: rotate(45deg);
    }
}

.popup-wrapper {
    position: relative;
    width: 100%;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -ms-transition: all .5s linear;
    -o-transition: all .5s linear;
    transition: all .5s linear;
}

.popup-wrapper.active {
    opacity: 1;
    visibility: visible;
    .popup-box {
        padding: 70px;
    }
}

.portfolio-top-nav {
    position: absolute;
    top: 15px;
    left: -15px;
    .a-btn-prev {
        background-color: #9E7B58;
        color: white;
        text-align: left;
        padding-left: 20px;
        display: inline-block;
        padding-right: 20px;
        width: 65px;
        position: relative;
        height: 27px;
        cursor: pointer;
        margin-right: 10px;
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0;
            height: 100%;
            display: block;
            z-index: 0;
            box-shadow: 0px 13px 24px -10px #9e7b58;
            opacity: .5;
        }
        i {
            display: inline-block;
            width: 25px;
            height: 27px;
            position: relative;
            transition: all 350ms ease;
            transform: translateX(0);
            &::after {
                content: '';
                height: 1px;
                width: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                border: none;
                background-color: #fff;
                margin-left: 0;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right: 4px solid white;
                left: 0;
                margin-left: 1px;
            }
        }
        &:hover i {
            width: 27px;
            transform: translateX(-4px);
            &::before {
                width: auto;
            }
            &::after {
                width: 24px;
            }
        }
        &.disable {
            i {
                opacity: .2;
            }
            &:hover i {
                width: 25px;
                transform: translateX(0);
                &::after {
                    width: 20px;
                }
            }
        }
    }
    .a-btn-next {
        background-color: #9E7B58;
        color: white;
        text-align: left;
        padding-left: 20px;
        display: inline-block;
        padding-right: 20px;
        width: 65px;
        position: relative;
        height: 27px;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0;
            height: 100%;
            display: block;
            z-index: 0;
            box-shadow: 0px 13px 24px -10px #9e7b58;
            opacity: .5;
        }
        i {
            display: inline-block;
            width: 25px;
            height: 27px;
            position: relative;
            transition: all 350ms ease;
            transform: translateX(0);
            &::before {
                content: '';
                position: absolute;
                height: 1px;
                width: 20px;
                left: 0;
                background-color: #fff;
                top: 50%;
                transform: translateY(-50%);
                transition: width 350ms ease;
            }
            &::after {
                content: "";
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid white;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-left: 1px;
            }
        }
        &:hover i {
            width: 27px;
            transform: translateX(4px);
            &::before {
                width: 24px;
            }
        }
        &.disable {
            i {
                opacity: .2;
            }
            &:hover i {
                width: 25px;
                transform: translateX(0);
                &::before {
                    width: 20px;
                }
            }
        }
    }
}


/*--------------------------------------------------------*/

//CLIENTS

/*--------------------------------------------------------*/

.clients-wrapper {
    padding: 20px 0;
    .clients-wrap {
        margin: auto;
        display: -webkit-flex;
        -webkit-justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .client-logo {
            list-style: none;
            display: inline-block;
            padding: 20px 15px;
            opacity: .4;
            flex: none;
            transition: all 300ms ease;
            .img {
                max-height: 50px;
                width: auto;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
}


/*--------------------------------------------------------*/

//COUNTERS

/*--------------------------------------------------------*/

.counters-wrapper {
    position: relative;
    padding: 25px 15px 27px;
    margin: 20px auto 50px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
    }
    .counters-wrap {
        position: relative;
        z-index: 99;
        margin: auto;
        display: -webkit-flex;
        -webkit-justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .counter {
        list-style: none;
        display: inline-block;
        padding: 40px 25px;
        flex: none;
        span {
            display: block;
            text-align: center;
        }
        .number {
            font-size: 36px;
            font-family: "Raleway", sans-serif;
            color: #fff;
        }
        .icon {
            font-size: 26px;
            color: #fff;
            margin-top: 20px;
        }
        .title {
            margin-top: 25px;
            font-size: 14px;
            letter-spacing: 2px;
            font-family: "Libre Baskerville", sans-serif;
            color: #fff;
            line-height: 1.3;
        }
    }
}


/*--------------------------------------------------------*/

//CONTACTS - FORM

/*--------------------------------------------------------*/

.wpcf7[role="form"] {
    .paddings-form {
        padding-right: 55px;
        padding-left: 55px;
    }
    .title {
        font-size: 24px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        letter-spacing: 1.2px;
        line-height: 1.75;
        margin-bottom: 43px;
    }
    input:not([type="submit"]),
    textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #d1d1d1;
        color: #343434;
        font-size: 14px;
        padding: 10px 0;
        letter-spacing: .7px;
        margin-bottom: 25px;
        font-family: "Libre Baskerville", sans-serif;
        line-height: 1.2;
        transition: all 350ms ease;
        &:focus {
            border-bottom: 1px solid #9E7B58;
        }
        &::-webkit-input-placeholder {
            opacity: .5;
        }
        &::-moz-placeholder {
            opacity: .5;
        }
        &:-ms-input-placeholder {
            opacity: .5;
        }
        &:-moz-placeholder {
            opacity: .5;
        }
    }
    textarea {
        height: 40px;
    }
    input[type="submit"] {
        margin-top: 5px;
        font-size: 11px;
        font-family: "Raleway", sans-serif;
        color: #fff;
        position: relative;
        padding: 16px 43px;
        letter-spacing: 1px;
        z-index: 10;
        font-weight: bold;
        background-color: #9E7B58;
    }
    .submit-wrap {
        position: relative;
        display: inline-block;
        margin-bottom: 40px;
        img {
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translate(100%, -50%);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
            height: 50%;
            display: block;
            z-index: 0;
            box-shadow: 0px 7px 30px 0px #9E7B58;
            opacity: .5;
        }
        input {
            transition: all 180ms ease;
            border-radius: 0px;
            transition-delay: 150ms;
        }
        &:hover input {
            color: transparent;
            transition-delay: 0ms;
        }
    }
    .submit-wrap {
        position: relative;
        i {
            display: inline-block;
            width: 25px;
            height: 27px;
            position: absolute;
            top: 13px;
            left: 20%;
            opacity: 0;
            transform: translateX(-50%);
            z-index: 100;
            transition: all 150ms ease;
        }
        i::before {
            content: '';
            position: absolute;
            height: 1px;
            width: 20px;
            left: 0;
            background-color: #fff;
            top: 50%;
            transform: translateY(-50%);
            transition: width 150ms ease;
        }
        i::after {
            content: "";
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid white;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 1px;
        }
        &:hover i {
            left: 50%;
            opacity: 1;
            transition-delay: 180ms;
        }
    }
}

.single-post-wrap .form-submit {
    input[type="submit"] {
        transition: all 180ms ease;
        border-radius: 0px;
        transition-delay: 150ms;
    }
    &:hover input[type="submit"] {
        color: transparent;
        transition-delay: 0ms;
    }
}

.comment-respond {
    .form-submit {
        position: relative;
        .isubmit {
            display: inline-block;
            width: 25px;
            height: 27px;
            position: absolute;
            top: 13px;
            left: 20%;
            opacity: 0;
            transform: translateX(-50%);
            z-index: 100;
            transition: all 150ms ease;
            &::before {
                content: '';
                position: absolute;
                height: 1px;
                width: 20px;
                left: 0;
                background-color: #fff;
                top: 50%;
                transform: translateY(-50%);
                transition: width 350ms ease;
            }
            &::after {
                content: "";
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid white;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-left: 1px;
            }
        }
        &:hover .isubmit {
            width: 27px;
            transform: translateX(calc(50% + 27px));
            opacity: 1;
            &::before {
                width: 24px;
            }
        }
    }
}

.maps-wrapper {
    position: relative;
    .contact-info {
        padding: 17px 30px 50px;
        width: 100%;
        max-width: 300px;
        position: absolute;
        left: 70px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
        .info-box {
            margin-bottom: 20px;
            .title {
                font-size: 14px;
                font-family: "Libre Baskerville", sans-serif;
                color: #343434;
                letter-spacing: 2px;
                line-height: 1.2;
                position: relative;
                margin-bottom: 20px;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                    width: 15px;
                    height: 3px;
                    background-color: #9E7B58;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                    width: 15px;
                    height: 3px;
                    box-shadow: 0px 2px 7px 0px rgb(170, 133, 93);
                    opacity: .5;
                }
            }
            .details {
                margin-top: 10px;
                margin-bottom: 12px;
            }
            h6,
            a {
                text-decoration: none;
                font-size: 12px;
                font-family: "Raleway", sans-serif;
                color: #343434;
                display: block;
                opacity: .7;
                margin: 0;
                letter-spacing: 1.05px;
                line-height: 1.5;
                &:hover,
                &:focus,
                &:visited,
                &:active {
                    color: #343434;
                    text-decoration: none;
                }
            }
            a {
                transition: all 350ms ease;
            }
            a:hover {
                color: #9E7B58;
            }
        }
    }
}

#google-map {
    height: 500px;
}


/*--------------------------------------------------------*/

//PORTFOLIO DETAIL

/*--------------------------------------------------------*/

.single-portfolio-item {
    background-color: #fff;
    position: relative;
    margin-bottom: 40px;
    padding: 70px;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 70px);
        height: 100%;
        display: block;
        z-index: -1;
        box-shadow: 0px 29px 40px -20px rgba(0, 0, 0, 0.2);
    }
    &.popup-box {
        margin-bottom: 0;
        padding: 0;
    }
    &.popup-box::before {
        display: none;
    }
    .title {
        font-size: 14px;
        letter-spacing: 1.8px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 13px;
    }
    .single-portfolio-section {
        p {
            font-size: 13.5px;
            letter-spacing: 1.1px;
            img {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
                margin-top: 5px;
            }
            iframe {
                width: 100%;
                margin-bottom: 20px;
                margin-top: 5px;
                height: 675px;
                @media screen and (max-width: 767px) {
                    height: 300px;
                }
            }
        }
        .large-sliders {
            margin-top: 25px;
            position: relative;
            margin-bottom: 10px;
            .swiper-arrow-right,
            .swiper-arrow-left {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;
                border-radius: 50%;
                padding: 12px 17px;
                cursor: pointer;
                transition: all 400ms ease;
                &:hover {
                    color: #fff;
                    background-color: #343434;
                }
            }
            .swiper-arrow-right {
                right: 30px;
            }
            .swiper-arrow-left {
                left: 30px;
            }
            img {
                width: 100%;
            }
        }
        .thumbnails {
            margin-right: -5px;
            margin-left: -5px;
            margin-bottom: 50px;
            .swiper-slide {
                padding: 5px;
                &.current .image-wrap::before {
                    opacity: 0;
                }
            }
            .image-wrap {
                width: 100%;
                position: relative;
                &::before {
                    content: '';
                    width: calc(100% - 20px);
                    height: calc(100% - 20px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    opacity: .7;
                    transition: all 400ms ease;
                }
                &:hover::before {
                    opacity: 0;
                }
            }
        }
    }
    .schort_desc {
        margin-bottom: 20px;
        h3 {
            letter-spacing: 1.8px;
        }
        p {
            font-size: 13.5px;
            letter-spacing: 1.6px;
        }
    }
    .clients {
        display: inline-block;
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        line-height: 1.2;
        margin-right: 14px;
        letter-spacing: 1.1px;
        .clients-title {
            opacity: .7;
        }
    }
    .category-portfolio,
    .category-portfolio a,
    .category-portfolio a:active,
    .category-portfolio a:visited,
    .category-portfolio a:focus {
        display: inline-block;
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        line-height: 1.2;
        letter-spacing: 1.1px;
        .category-title {
            opacity: .7;
        }
        a {
            text-decoration: none;
            transition: all 300ms ease;
            position: relative;
            margin-bottom: 6px;
            &::before {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                bottom: -5px;
                background-color: #343434;
                transition: all 300ms ease;
            }
            &:hover {
                color: #9E7B58;
                &::before {
                    background-color: #9E7B58;
                }
            }
        }
    }
    .share-this {
        margin-top: 19px;
        .title {
            display: inline-block;
            font-size: 12px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            line-height: 1.2;
            margin-right: 14px;
            letter-spacing: 1.1px;
            opacity: .7;
            text-transform: none;
            font-weight: normal;
            margin-bottom: auto;
        }
        span {
            font-size: 12px;
            display: inline-block;
            color: #343434;
            position: relative;
            margin-right: 12px;
            cursor: pointer;
            transition: all 350ms ease;
            &:hover {
                color: #9E7B58;
            }
        }
    }
}

.isubmit {
    cursor: pointer;
}


/*--------------------------------------------------------*/

//SINGLE POST

/*--------------------------------------------------------*/

.post-content-wrap {
    background-color: #fff;
    position: relative;
    margin-bottom: 50px;
    padding: 70px 70px 57px;
    &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        z-index: -1;
        height: 90%;
        left: 50%;
        width: calc(100% - 50px);
        transform: translateX(-50%);
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
    }
    .gallery-item {
        float: left;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    .post-banner {
        height: 350px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: rgba(0, 0, 0, .2);
        }
        .post-title {
            font-size: 34px;
            font-family: "Libre Baskerville", sans-serif;
            color: #fff;
            padding-right: 15px;
            padding-left: 15px;
            display: block;
            width: 100%;
            z-index: 10;
            line-height: 1.2;
            letter-spacing: -.2px;
            text-align: center;
            position: absolute;
            bottom: 31px;
        }
        .meta-info-post {
            background-color: #fff;
            padding: 13px 20px;
            z-index: 10;
            position: absolute;
            bottom: -22px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
            span,
            a {
                font-size: 12px;
                font-family: "Raleway", sans-serif;
                color: #343434;
                opacity: .7;
                line-height: 1.2;
                letter-spacing: .5px;
            }
            a {
                margin-right: 10px;
                transition: all 300ms ease;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: #9E7B58;
                }
            }
            i {
                color: #9E7B58;
                margin-right: 7px;
            }
        }
    }
    .post-content {
        margin-top: 63px;
        margin-bottom: 58px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 10px;
            margin-bottom: 15px;
        }
        p {
            letter-spacing: 1.4px;
            line-height: 22px;
            margin-bottom: 20px;
        }
        ul li,
        ol li {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
        }
        .gallery {
            margin-right: -7px;
            margin-left: -7px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .post-category {
        display: inline-block;
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        width: calc(100% - 150px);
        line-height: 1.2;
        margin-right: 14px;
        letter-spacing: 1.1px;
        &.tag {
            margin-top: 20px;
        }
        .title_category {
            opacity: .7;
        }
        a {
            color: #343434;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;
            transition: all 300ms ease;
            &::before {
                content: '';
                position: absolute;
                bottom: -5px;
                height: 1px;
                width: 100%;
                background-color: #343434;
                transition: all 300ms ease;
            }
        }
        a:hover,
        a:focus,
        a:visited,
        a:active {
            color: #9E7B58;
            &::before {
                background-color: #9E7B58;
            }
        }
    }
    .share-social {
        display: inline-block;
        float: right;
        span {
            font-size: 12px;
            display: inline-block;
            color: #343434;
            position: relative;
            margin-right: 12px;
            cursor: pointer;
            transition: all 350ms ease;
            &:hover {
                color: #9E7B58;
            }
        }
    }
}

.text-center {
    text-align: center;
}

.search-post-content {
    padding-top: 20px;
    padding-bottom: 20px;
    h5 {
        line-height: 1.4;
    }
}

blockquote {
    border: none;
    position: relative;
    padding: 1px 30px;
    margin: 45px auto;
    p {
        font-size: 18px;
        letter-spacing: 1px;
        font-family: "Raleway", sans-serif;
        font-style: normal;
        font-weight: 500;
        color: #343434;
        opacity: 1;
        margin: 5px 0;
        line-height: 28px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 6px;
        height: 100%;
        background-color: #9E7B58;
    }
    &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 0;
        display: block;
        width: 5px;
        height: 100%;
        box-shadow: 0px 7px 25px 0px rgb(170, 133, 93);
        opacity: .5;
    }
}

table a {
    color: #343434;
    transition: all 300ms ease;
    opacity: 1;
    &:hover {
        color: #9E7B58;
        opacity: 1;
    }
}

.post-nav span:not(:first-child),
.post-nav a {
    display: inline-block;
    padding: 5px 7px 5px 9px;
    color: white;
    background-color: #9E7B58;
    border: 1px solid #9E7B58;
    text-decoration: none;
    transition: all 300ms ease;
    margin-right: 3px;
    margin-left: 3px;
    &:hover {
        background-color: transparent;
        color: #343434;
    }
}

.post-nav {
    letter-spacing: 3px;
}

.post-password-form input[type="submit"] {
    background-color: #000;
}

.post-nav span:first-child {
    margin-right: 5px;
    letter-spacing: normal;
}

.post-content {
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    color: #343434;
    a {
        text-decoration: underline;
    }
    ul li,
    ol li {
        line-height: 1.5;
    }
    ul,
    ol {
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 15px;
        color: #a7a2a2;
    }
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        margin-top: 0;
        margin-bottom: 0;
        color: #a7a2a2;
    }
    b,
    strong {
        color: #000;
    }
}

.pingback {
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    color: #343434;
    margin: 0px auto 30px;
    a {
        text-decoration: underline;
        color: #343434;
        line-height: 1.5;
        transition: all 300ms ease;
        &:hover {
            color: #9E7B58;
        }
    }
}

.main-post-pagination {
    .next-btn a,
    .prev-btn a {
        font-size: 13px;
        font-family: "Raleway", sans-serif;
        color: #343434;
        font-weight: 600;
        letter-spacing: 2px;
        transition: all 300ms ease;
        &:hover {
            color: #9E7B58;
        }
    }
    .next-btn {
        float: right;
    }
    .prev-btn {
        float: left;
    }
    margin: 50px 0 40px;
}

.post-content a {
    color: #343434;
    transition: all 300ms ease;
    opacity: 1;
    &:hover {
        color: #9E7B58;
        opacity: 1;
    }
}

.blog-comment {
    list-style: none;
    display: block;
    padding-left: 15px;
    margin-bottom: 17px;
    min-height: 110px;
}

.comments-post {
    background-color: #fff;
    position: relative;
    margin-bottom: 50px;
    padding: 45px 0px 30px;
    &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        z-index: -1;
        height: 90%;
        left: 50%;
        width: calc(100% - 50px);
        transform: translateX(-50%);
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
    }
    input,
    textarea {
        &::-webkit-input-placeholder {
            color: #343434;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: #343434;
            ;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #343434;
            ;
        }
        &:-ms-input-placeholder {
            color: #343434;
            ;
        }
    }
    input:not([type="submit"]),
    textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #d1d1d1;
        color: #343434;
        font-size: 14px;
        width: 100%;
        padding: 0;
        height: 37px;
        letter-spacing: .7px;
        margin-bottom: 20px;
        font-family: "Libre Baskerville", sans-serif;
        line-height: 1.2;
        border-radius: 0;
        transition: all 350ms ease;
        &:focus {
            border-bottom: 1px solid #9E7B58;
        }
        &::-webkit-input-placeholder {
            opacity: .5;
        }
        &::-moz-placeholder {
            opacity: .5;
        }
        &:-ms-input-placeholder {
            opacity: .5;
        }
        &:-moz-placeholder {
            opacity: .5;
        }
    }
    textarea {
        height: 40px;
    }
    button,
    input[type="submit"] {
        margin-top: 5px;
        font-size: 11px;
        font-family: "Raleway", sans-serif;
        color: #fff;
        position: relative;
        padding: 16px 41px;
        letter-spacing: 1px;
        z-index: 10;
        font-weight: bold;
        background-color: #9E7B58;
        border: none;
        outline: none;
        &:hover,
        &:focus {
            outline: none;
        }
    }
    .form-submit {
        position: relative;
        display: inline-block;
        margin-bottom: 40px;
        opacity: 1;
        margin-left: 15px;
        margin-top: 5px;
        img {
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translate(100%, -50%);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
            height: 50%;
            display: block;
            z-index: 0;
            box-shadow: 0px 7px 30px 0px rgba(158, 123, 88, 0.5);
            opacity: .5;
        }
    }
    #cancel-comment-reply-link {
        font-size: 18px;
        letter-spacing: 1px;
        font-family: "Raleway", sans-serif;
        font-style: normal;
        font-weight: 500;
        color: #343434;
        opacity: 1;
        margin: 5px 0;
        line-height: 28px;
    }
    .comments_title {
        font-size: 24px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        letter-spacing: 1px;
        line-height: 1.5;
        text-transform: none;
        margin-bottom: 45px;
        padding-left: 15px;
    }
    .photo-comment {
        display: inline-block;
        float: left;
        margin: 10px 28px 0 0;
    }
    .photo-comment img {
        border-radius: 50%;
    }
    .com-name {
        font-size: 14px;
        font-family: "Libre Baskerville", sans-serif;
        color: #343434;
        letter-spacing: 2.2px;
        margin-right: 4px;
        margin-bottom: 3px;
    }
    .date {
        font-size: 12px;
        font-family: "Raleway", sans-serif;
        color: #a7a2a2;
        line-height: 1.5;
        letter-spacing: .4px;
    }
    .com-text {
        overflow: hidden;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 10px;
            margin-bottom: 15px;
        }
        p {
            font-size: 12px;
            font-family: "Raleway", sans-serif;
            color: #343434;
            line-height: 1.5;
            letter-spacing: 1.2px;
            margin-bottom: 10px;
        }
        p:empty {
            display: none;
        }
        a {
            text-decoration: underline;
            color: #343434;
            transition: all 300ms ease;
            &:hover {
                color: #9E7B58;
            }
        }
        ul li,
        ol li {
            line-height: 1.5;
        }
        ul,
        ol {
            margin-top: 10px;
            margin-bottom: 20px;
            margin-left: 15px;
            color: #a7a2a2;
        }
        ul ul,
        ul ol,
        ol ol,
        ol ul {
            margin-top: 0;
            margin-bottom: 0;
            opacity: 1;
        }
        b,
        strong {
            color: #000;
        }
    }
    .com-reply {
        display: inline-block;
    }
    .com-reply a {
        font-size: 11px;
        font-family: "Raleway", sans-serif;
        color: #9E7B58;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all 350ms ease;
        &:hover {
            color: #343434;
            opacity: .5;
        }
    }
    .children {
        padding-left: 100px;
        margin: 0;
    }
    .comment-respond {
        margin-top: 33px;
    }
}


/*--------------------------------------------------------*/

//BLOG LIST

/*--------------------------------------------------------*/

.news-item.classic {
    background-color: #fff;
    max-width: 100%;
    .post_item {
        display: table;
        table-layout: fixed;
        width: 100%;
        &.sticky .post-item-link {
            display: inline-block;
            &::before {
                content: "\f24a";
                font-family: "FontAwesome";
                color: #9E7B58;
                margin-right: 10px;
            }
        }
    }
    .post-media-wrap {
        float: left;
        width: 50%;
        height: 100%;
        position: absolute;
        min-height: 250px;
        .img-wrap {
            height: inherit;
        }
        .wrapper_el_video {
            position: relative;
        }
        .wrapper_el_video iframe {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .blog-video {
            max-width: 100%;
            height: 100%;
            overflow: hidden;
            p {
                height: 100%;
                margin: 0;
                line-height: normal;
            }
            iframe {
                margin: 0;
                max-width: 102%;
                margin-top: -3px;
                margin-left: -1%;
            }
        }
        .img-wrap-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &.standart {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &.gallery {
            float: left;
            height: 100%;
            .pagination {
                display: none;
            }
            .swiper-arrow-left,
            .swiper-arrow-right {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;
                border-radius: 50%;
                padding: 12px 17px;
                cursor: pointer;
                transition: all 400ms ease;
                &:hover {
                    color: #fff;
                    background-color: #343434;
                }
            }
            .swiper-arrow-left {
                left: 40px;
            }
            .swiper-arrow-right {
                right: 40px;
            }
        }
    }
    .bloc-content-wrapper {
        padding: 40px;
        float: right;
        width: 50%;
        &.gallery {
            float: right;
        }
        .meta-data {
            background-color: #fff;
            padding: 13px 0px;
            .admin-post {
                margin-right: 11px;
                display: inline-block;
            }
            .date-post {
                display: inline-block;
            }
            span,
            a {
                font-size: 12px;
                font-family: "Raleway", sans-serif;
                color: #a7a2a2;
                line-height: 1.2;
                letter-spacing: .5px;
            }
            a {
                margin-right: 10px;
                transition: all 300ms ease;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: #9E7B58;
                }
            }
            i {
                color: #9E7B58;
                margin-right: 7px;
            }
        }
        .post-item-link {
            font-size: 24px;
            font-family: "Libre Baskerville", sans-serif;
            color: #343434;
            letter-spacing: 1.2px;
            line-height: 1.2;
            display: block;
            margin: 4px 0 19px 0;
            transition: all 400ms ease;
            &:hover {
                color: #9E7B58;
            }
        }
        .short_desc {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
            line-height: 1.5;
        }
        .button {
            margin-top: 24px;
            padding: 15px 50px 15px 39px;
            margin-bottom: 15px;
        }
    }
    &.style2 {
        .post-media-wrap {
            float: right;
            right: 0;
        }
        .bloc-content-wrapper {
            float: left;
        }
    }
}

.news-item {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 90%;
        height: 30px;
        transform: translateX(-50%);
        display: block;
        z-index: -1;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
    }
}

.button-play {
    background-color: #fff;
    color: #9E7B58;
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    cursor: pointer;
    padding: 12px 30px;
    transition: all 300ms ease;
    &:hover {
        background-color: #9E7B58;
        color: #fff;
    }
}

.news-item {
    margin-bottom: 30px;
}

.news-item.masonry {
    width: calc((100% - 60px)/3);
    background-color: #fff;
    &.true {
        width: calc((100% - 30px)/2);
    }
    .post-media-wrap {
        position: relative;
        .blog-video {
            max-width: 100%;
            overflow: hidden;
            height: 250px;
            p {
                margin: 0;
                line-height: normal;
            }
            iframe {
                margin: -3px 0 0 -1%;
                max-width: 102%;
                height: 250px;
            }
        }
        .img-wrap-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .post-media-wrap.standart {
        min-height: 70px;
        background-color: #9E7B58;
        display: block;
    }
    .post-media-wrap.standart img {
        width: 100%;
        display: block;
    }
    .post-media-wrap.gallery {
        position: relative;
        .img-wrap img {
            height: 300px;
        }
        .pagination {
            display: none;
        }
        .swiper-arrow-left,
        .swiper-arrow-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            border-radius: 50%;
            padding: 12px 17px;
            cursor: pointer;
            transition: all 400ms ease;
            &:hover {
                color: #fff;
                background-color: #343434;
            }
        }
        .swiper-arrow-left {
            left: 40px;
        }
        .swiper-arrow-right {
            right: 40px;
        }
    }
    .bloc-content-wrapper {
        position: relative;
        padding: 0px 23px 20px;
        .button {
            display: none;
        }
        .meta-data {
            background-color: #fff;
            padding: 13px 19px;
            position: relative;
            display: inline-block;
            top: -17px;
            left: 25px;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
            .admin-post {
                margin-right: 0px;
                display: inline-block;
            }
            .date-post {
                display: inline-block;
            }
            span,
            a {
                font-size: 12px;
                font-family: "Raleway", sans-serif;
                color: #a7a2a2;
                line-height: 1.2;
                letter-spacing: .5px;
            }
            a {
                margin-right: 10px;
                transition: all 300ms ease;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: #9E7B58;
                }
            }
            i {
                color: #9E7B58;
                margin-right: 7px;
            }
        }
        .post-item-link {
            font-size: 20px;
            font-family: "Libre Baskerville", sans-serif;
            color: #343434;
            letter-spacing: 1.2px;
            line-height: 1.2;
            display: block;
            margin: 4px 0 14px 0;
            transition: all 400ms ease;
            &:hover {
                color: #9E7B58;
            }
        }
        .short_desc {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
            line-height: 1.5;
        }
        .button {
            margin-top: 24px;
            padding: 15px 50px 15px 39px;
            margin-bottom: 15px;
        }
    }
}


/*--------------------------------------------------------*/

//SIDEBAR

/*--------------------------------------------------------*/

.sidebar-container {
    background: white;
    padding: 30px;
    margin-bottom: 40px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 90%;
        height: 30px;
        transform: translateX(-50%);
        display: block;
        z-index: -1;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
    }
    .widget_search {
        display: block;
        form {
            height: 38px;
        }
    }
    .widget_search input {
        width: calc(100% - 40px);
        float: left;
        border-radius: 0;
    }
    button {
        width: 40px;
        float: right;
        padding: 11px 14px 11px;
        background-color: #9E7B58;
        border-radius: 0;
        border: 1px solid #9E7B58;
        transition: all 300ms ease;
        &:hover {
            background-color: transparent;
            color: #9E7B58;
        }
    }
    .widget-section {
        margin-bottom: 30px;
        .widget-title {
            margin-bottom: 15px;
            font-family: "Raleway", sans-serif;
            font-size: 16px;
            font-weight: bold;
        }
        ul li {
            padding: 5px 0;
            line-height: 1.5;
            letter-spacing: .3px;
        }
        ul li,
        ul li a,
        label,
        select,
        option {
            font-size: 14px;
            font-family: "Raleway", sans-serif;
            color: #a7a2a2;
            list-style: none;
        }
        ul li a {
            transition: all 300ms ease;
        }
        ul li a:hover {
            color: #9E7B58;
        }
    }
    .widget_recent_comments ul li a {
        text-decoration: underline;
    }
    table th,
    table td {
        font-size: 14px;
        font-family: "Raleway", sans-serif;
        color: #a7a2a2;
    }
    table th {
        color: #343434;
        font-weight: bold;
    }
    select {
        width: 100%;
        padding: 5px;
    }
    .widget_rss {
        a.rsswidget {
            display: block;
            color: #343434;
            font-weight: bold;
        }
        .widget-title a.rsswidget {
            display: inline-block;
        }
        span.rss-date {
            font-size: 12px;
            display: block;
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .rssSummary + cite {
            display: block;
            margin: 5px 0 20px;
        }
    }
    .tagcloud a {
        color: #343434;
        transition: all 300ms ease;
        font-weight: 100;
        &:hover {
            color: #9E7B58;
        }
    }
}

.logged-in-as {
    padding: 0 15px;
    a {
        color: #343434;
        transition: all 300ms ease;
        &:hover {
            color: #9E7B58;
        }
    }
}

@media screen and (max-width: 1600px) {
    .news-item.masonry {
        &.true {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .simple-banner-wrap .content {
        max-width: 500px;
    }
    .banner-slider-wrap .slider-content {
        max-width: 510px;
    }
    .simple-banner-wrap .body-height {
        height: calc(100vh - 455px);
    }
    .creative-slider-wrap .creative-content {
        height: 230px;
        padding-top: 45px;
        .button {
            margin-top: 25px;
        }
    }
    .creative-slider-wrap .wrap-arrows {
        top: calc(100vh - 380px);
    }
    .creative-slider-wrap .pagination {
        bottom: 70px;
        margin: 0;
        span {
            font-size: 140px;
        }
    }
    .creative-slider-wrap .img-slider-wrap.body-height {
        height: calc(100vh - 390px);
    }
    .comments-post .children {
        padding-left: 50px;
    }
    .news-item.masonry {
        width: calc((100% - 30px) / 2);
    }
}

@media screen and (max-width: 1390px) {
    body {
        margin: 30px 30px 10px;
    }
    .left-navigation {
        margin-bottom: 30px;
        min-height: calc(100vmin - 120px);
    }
    .section-wrapper {
        margin-left: 310px;
    }
    .body-height {
        height: calc(100vh - 70px);
    }
    .banner-slider-wrap .body-height {
        height: calc(100vh - 130px);
    }
    .creative-slider-wrap .img-slider-wrap.body-height {
        height: calc(100vh - 360px);
    }
    .creative-slider-wrap .wrap-arrows {
        top: calc(100vh - 360px);
    }
    .headlines-wrap.style1 .headlines {
        min-height: 200px;
    }
    .headlines-wrap {
        padding: 50px 0px 20px;
    }
    .headlines-wrap.style2 {
        padding-top: 35px;
    }
    .headlines-wrap.style1 .headlines {
        margin-bottom: 0;
    }
    .call-to-action {
        margin-top: 22px;
    }
    .headlines-wrap.style2 .headlines {
        margin-bottom: 14px;
    }
    .call-to-action.subscribe {
        margin: 35px auto 40px;
    }
    .counters-wrapper .counter .icon {
        margin-top: 10px;
    }
    .counters-wrapper .counter .title {
        margin-top: 15px;
    }
    .counters-wrapper .counter {
        padding: 30px 25px;
    }
    .counters-wrapper {
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 992px) {
    .left-navigation {
        position: fixed;
    }
}

@media screen and (max-width: 991px) {
    .services-wrap .text {
        font-size: 13px;
    }
    p {
        font-size: 13px;
    }
    .headlines-wrap.style2 .headlines .content .headlines-subtitle {
        font-size: 13px;
    }
    .step-services-wrap .text {
        font-size: 13px;
    }
    .navigation {
        width: 100%;
    }
    .brand-res {
        a {
            display: inline-block;
            padding: 10px 0;
            img {
                max-height: 70px;
            }
        }
    }
    .section-wrapper {
        margin-left: 0px;
    }
    body {
        padding-top: 75px;
    }
    .creative-slider-wrap .img-slider-wrap.body-height {
        height: calc(100vh - 445px);
    }
    .creative-slider-wrap .wrap-arrows {
        top: calc(100vh - 435px);
    }
    .creative-slider-wrap .creative-content .title {
        font-size: 30px;
    }
    .body-height {
        height: calc(100vh - 135px);
    }
    .banner-slider-wrap .body-height {
        height: calc(100vh - 215px);
    }
    .responsive-nav {
        position: fixed;
        height: 75px;
        top: 30px;
        display: table;
        table-layout: fixed;
        background-color: #fff;
        padding: 0px 30px;
        z-index: 1000;
        transition: top 300ms ease;
        .fixed-sidebar-footer {
            position: relative;
            padding-top: 0;
        }
        .menu {
            padding-bottom: 30px;
        }
        &.scroll {
            top: 0;
        }
        .brand-res {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            background-color: #fff;
        }
        .menu-button {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .res-menu {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        display: none;
        background-color: #fff;
        z-index: -1;
    }
    .menu-button {
        position: absolute;
        display: none;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        padding: 0;
        width: 20px;
        height: 35px;
        font-size: 0;
        text-indent: -9999px;
        cursor: pointer;
        background-color: transparent;
        z-index: 101;
        span {
            display: block;
            position: absolute;
            top: 18px;
            left: 0;
            right: 0;
            height: 2px;
            background: #343434;
            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 2px;
                background: #343434;
                transition: all 300ms ease;
            }
            &::before {
                top: -5px;
            }
            &::after {
                bottom: -5px;
            }
        }
        &.active {
            span {
                background-color: transparent;
                &::before {
                    top: 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &::after {
                    bottom: 0;
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .headlines-wrap.style1 .headlines .content .headlines-title {
        font-size: 30px;
    }
    .headlines-wrap.style2 .headlines .content .headlines-title {
        font-size: 30px;
    }
    .call-to-action .form-subscribe {
        margin-top: 32px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .step-services-wrap {
        margin-bottom: 15px;
    }
    .call-to-action .form-subscribe form .mc4wp-response p {
        font-size: 12px;
    }
    .portfolio-top-nav {
        position: static;
        margin-top: -40px;
        margin-bottom: 45px;
    }
    .close-popup {
        right: 15px;
        top: 29px;
    }
    .popup-wrapper.active .popup-box {
        padding: 70px 30px 30px;
    }
    .post-content-wrap {
        padding: 30px 30px 37px;
    }
    .comments-post {
        padding: 20px 0px 15px;
        margin-bottom: 0;
    }
    .post-content-wrap .post-banner {
        padding-top: 90px;
        padding-bottom: 35px;
        height: auto;
    }
    .post-content-wrap .post-banner .post-title {
        position: relative;
        bottom: auto;
    }
    .container-masonry {
        padding-top: 30px;
    }
    .logo {
        max-width: 100%;
        margin-left: 0;
        padding-left: 0;
    }
    .textlogo {
        font-size: 23px;
    }
}

@media screen and (max-width: 768px) {
    .headlines-wrap.style2 {
        padding-top: 15px;
    }
}

@media screen and (max-width: 767px) {
    .single-post-wrap {
        padding-left: 15px;
        padding-right: 15px;
    }
    .team-wrap .img-wrap {
        height: 270px;
    }
    .team-wrap .img-wrapper {
        height: 270px;
    }
    .simple-banner-wrap .content {
        max-width: 400px;
    }
    .simple-banner-wrap .content .title {
        font-size: 28px;
    }
    .banner-slider-wrap .slider-content {
        max-width: 400px;
    }
    .banner-slider-wrap .slider-content .title {
        font-size: 28px;
    }
    .banner-slider-wrap .body-height {
        height: calc(100vh - 220px);
        min-height: 250px;
    }
    .creative-slider-wrap .creative-content {
        height: auto;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .creative-slider-wrap .img-slider-wrap.body-height {
        height: 260px;
    }
    .creative-slider-wrap .wrap-arrows {
        top: 260px;
    }
    .creative-slider-wrap .pagination {
        bottom: auto;
        top: 300px;
    }
    .headlines-wrap.style1 .headlines {
        min-height: 100px;
    }
    .headlines-wrap.style1 .headlines .content .headlines-title {
        font-size: 25px;
    }
    .headlines-wrap.style1 .headlines .content {
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        bottom: 0;
        top: 0;
    }
    .headlines-wrap {
        padding: 20px 0px 20px;
    }
    .headlines-wrap.style2 .headlines .content .headlines-title {
        font-size: 24px;
        margin-bottom: 9px;
    }
    .headlines-wrap.style1 .headlines {
        margin-bottom: 0;
    }
    .services-wrap {
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .headlines-wrap.style2 .headlines {
        margin-bottom: 10px;
    }
    .step-services-wrap .char {
        font-size: 85px;
    }
    .step-services-wrap .content-wrap {
        margin-top: 56px;
        min-height: 105px;
    }
    .call-to-action .content-wrap .title {
        font-size: 28px;
    }
    .call-to-action .content-wrap .subtitle {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 12px;
    }
    .call-to-action {
        margin-top: 0;
    }
    .call-to-action.subscribe {
        margin-top: 20px;
    }
    .call-to-action.subscribe .content-wrap .title {
        font-size: 21px;
    }
    .mfp-arrow.mfp-arrow-left {
        left: 15px;
    }
    .mfp-arrow.mfp-arrow-right {
        right: 15px;
    }
    .step-services-wrap .img-wrap {
        height: 150px;
    }
    .counters-wrapper {
        margin-bottom: 20px;
    }
    .wpcf7[role="form"] .paddings-form {
        padding-right: 30px;
        padding-left: 30px;
    }
    .single-portfolio-item {
        padding: 30px;
    }
    .single-portfolio-item .schort_desc h3 {
        letter-spacing: 1px;
        font-size: 19px;
        line-height: 1.2;
        margin-bottom: 0;
    }
    .single-portfolio-item .single-portfolio-section .thumbnails {
        margin-bottom: 20px;
    }
    .single-portfolio-item .schort_desc p {
        font-size: 13px;
    }
    .single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-left {
        left: 10px;
    }
    .single-portfolio-item .single-portfolio-section .large-sliders .swiper-arrow-right {
        right: 10px;
    }
    blockquote {
        padding: 1px 0 1px 20px;
        margin: 25px auto;
        p {
            font-size: 16px;
            line-height: 1.5;
        }
    }
    .post-content-wrap .post-content {
        margin-bottom: 38px;
    }
    .post-content-wrap .post-banner .meta-info-post {
        width: 90%;
        text-align: center;
        position: relative;
        bottom: auto;
        margin-bottom: -70px;
    }
    .post-content-wrap .post-banner .meta-info-post .admin-post {
        margin-bottom: 5px;
    }
    .post-content-wrap .post-banner .post-title {
        font-size: 24px;
    }
    .news-item.classic .post-media-wrap {
        float: none;
        display: block;
        width: 100%;
    }
    .news-item.classic .bloc-content-wrapper {
        display: block;
        width: 100%;
        padding: 20px;
        float: none;
    }
    .news-item.classic .post-media-wrap.gallery {
        min-height: 0;
    }
    .news-item.classic .post-media-wrap .blog-video {
        min-height: 250px;
    }
    .container-masonry {
        padding-top: 20px;
    }
    .news-item.classic .post-media-wrap {
        position: static;
    }
    .news-item.classic .bloc-content-wrapper .post-item-link {
        font-size: 20px;
    }
    .news-item.masonry .bloc-content-wrapper .meta-data {
        display: block;
        left: 0;
    }
}

@media screen and (max-width: 600px) {
    .maps-wrapper .contact-info .info-box .title::before,
    .maps-wrapper .contact-info .info-box .title::after {
        left: 50%;
        transform: translateX(-50%);
    }
    body {
        margin: 15px;
    }
    .responsive-nav {
        top: 15px;
        position: fixed;
    }
    .banner-slider-wrap .swiper-outer-left {
        left: 10px;
        width: 50px;
        padding-right: 13px;
    }
    .banner-slider-wrap .swiper-outer-right {
        right: 10px;
        width: 50px;
        padding-left: 13px;
    }
    .a-btn-left:hover i {
        width: 25px;
    }
    .a-btn-left:hover i::after {
        width: 20px;
    }
    .a-btn:hover i {
        width: 25px;
    }
    .a-btn:hover i::before {
        width: 20px;
    }
    .banner-slider-wrap .slider-content {
        padding: 25px 10px 45px;
    }
    .border {
        border: 10px solid white;
    }
    .border::before {
        bottom: -10px;
    }
    .simple-banner-wrap .content {
        max-width: 250px;
        padding: 15px 15px 20px;
    }
    .banner-slider-wrap .body-height {
        height: calc(100vh - 210px);
    }
    .creative-slider-wrap .creative-content .title {
        font-size: 23px;
    }
    .creative-slider-wrap .wrap-arrows {
        padding: 12px 10px;
    }
    .body-height {
        height: calc(100vh - 120px);
    }
    .simple-banner-wrap {
        min-height: 290px;
    }
    .headlines-wrap.style1 .headlines .content .headlines-title {
        font-size: 22px;
    }
    .headlines-wrap.style2 .headlines .content .headlines-title {
        font-size: 22px;
        margin-bottom: 9px;
    }
    .button {
        padding: 15px 30px 15px 15px;
        &::after {
            right: 12px;
        }
        &:hover::after {
            right: 8px;
        }
    }
    .gallery-wrap.style-2 .item-gallery.small,
    .gallery-wrap .item-gallery.big,
    .gallery-wrap .item-gallery.long,
    .gallery-wrap .item-gallery.longer,
    .gallery-wrap.style-3 .item-gallery.longer,
    .gallery-wrap.style-3 .item-gallery.small,
    .gallery-wrap.style-3 .item-gallery.long,
    .gallery-wrap .item-gallery.small,
    .gallery-wrap .item-gallery{
        height: 250px;
        width: 100%;
    }
    .wpcf7[role="form"] .title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .maps-wrapper .contact-info {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        max-width: 100%;
        box-shadow: none;
        text-align: center;
    }
    .comments-post .photo-comment {
        display: block;
        float: none;
        margin: 10px auto;
    }
    .comments-post .com-name {
        display: block;
    }
    .comments-post .children {
        padding-left: 20px;
    }
    .comments-post .photo-comment img {
        width: 80px;
        height: 80px;
        margin-bottom: 7px;
    }
    .comments-post .comments_title {
        margin-bottom: 10px;
        font-size: 21px;
    }
    .post-content-wrap .post-category {
        width: 100%;
        margin-bottom: 20px;
    }
    .post-content-wrap .share-social {
        float: none;
    }
    .news-item.masonry {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .banner-slider-wrap .slider-content {
        max-width: 250px;
    }
    .banner-slider-wrap .slider-content .title {
        font-size: 26px;
    }
    .creative-slider-wrap .pagination {
        top: 350px;
    }
    .creative-slider-wrap .pagination span {
        font-size: 100px;
    }
}

.anime {
    opacity: 0;
}

.load-more.button {
    border-radius: 0px;
    margin-bottom: 50px;
    margin-top: 20px;
    padding: 15px 40px;
    span {
        transition: all 200ms ease;
    }
    &::after {
        content: "\f107";
        right: auto;
        left: 50%;
        top: 0;
        transform: translate(-50%, -150%);
        transition: all 200ms ease;
    }
    &:hover span {
        color: transparent ;
    }
    &:hover::after {
        right: auto;
        left: 50%;
        top: 50%;
        transition-delay: 100ms;
        transform: translate(-50%, -50%);
    }
}

.call-to-action .form-subscribe form .mc4wp-response p {
    font-size: 11px;
}

.loading-popup .loading {
    position: absolute;
    height: 100vh;
    background-color: #fff;
}

.move-wrapper {
    background-color: #fff;
    position: relative;
}

.block-wrap-padding {
    padding: 1px 20px;
}

.bg-color1 {
    background-color: #ffffff
}

.thumbnails {
    .swiper-slide {
        width: 224px;
        height: 224px;
    }
}

.padding-lg-lr0 {
    padding-left: 0;
    padding-right: 0;
}
